import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import { fetchData } from "../helpers/externapi";

const Login = () => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [empId, setEmpId] = useState('');
    const [password, setPassword] = useState('');
    const [empIdError, setEmpIdError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [isLoginError, setIsLoginError] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const cookieValue = Cookies.get('path');

    const message = location.state || '';

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const onChangeInput = (e) => {
        if (e.target.name === 'empId') {
            setEmpId(e.target.value);

            if (e.target.value.length > 0) {
                setEmpIdError(false);
            } else {
                setEmpIdError(true);
            }
        } else {
            setPassword(e.target.value);

            if (e.target.value.length > 0) {
                setPasswordError(false);
            } else {
                setPasswordError(true);
            }
        }
    };

    const onSubmitLogin = async (e) => {
        e.preventDefault();

        if (empId.length <= 0) {
            setEmpIdError(true);
        } else {
            setEmpIdError(false);
        }

        if (password.length <= 0) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }

        if (empId.length > 0 && empId.length > 0) {
            setLoading(true);

            const loginResponse = await fetchData('Users/adminloginm', {
                userName: empId,
                password: password
            });

            if (loginResponse && loginResponse.status) {
                setIsLoginError(false);
                setLoginError('');

                const currentTime = new Date().getTime();
                const expirationTime = currentTime + 120 * 60 * 1000;

                sessionStorage.setItem('UserId', loginResponse.data[0].UserId);
                sessionStorage.setItem('UserName', loginResponse.data[0].UserName);
                sessionStorage.setItem('SessionTime', expirationTime);

                if (cookieValue && cookieValue.length > 0) {
                    navigate(`${cookieValue}`);
                } else {
                    navigate(`/dashboard`);
                }
                
            } else if (loginResponse && !loginResponse.status) {
                setIsLoginError(true);
                setLoginError(loginResponse.message);
                sessionStorage.clear();
            }

            setLoading(false);
        }
    }

    return (
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner">
                    {/* Register */}
                    <div className="card">
                        <div className="card-body">
                            {/* Logo */}
                            <div className="app-brand justify-content-center">
                                <a className="app-brand-link gap-2">
                                    <span className="app-brand-logo demo">
                                        <img src={`${process.env.PUBLIC_URL}/assets/applogo.png`} height="50" />
                                    </span>
                                    <span className="app-brand-text demo text-body fw-bolder">OHOINDIA</span>
                                </a>
                            </div>
                            {message && message.length > 0 && (
                                <p className="text-danger text-center mb-4 fw-bolder">{message}</p>
                            )}
                            <div className="app-brand justify-content-center" style={{ marginBottom: 0, marginTop: -20 }} >
                                <h4 className="mb-2 center">LOGIN</h4>
                            </div>
                            <div className="app-brand justify-content-center" style={{ marginBottom: '5px' }} >
                                <p className="mb-4">Please sign-in to your account</p>
                            </div>

                            {isLoginError && (
                                <span style={{ color: 'red' }} >{loginError}</span>
                            )}                            
                            <form className="mb-3 fv-plugins-bootstrap5 fv-plugins-framework"
                                noValidate="novalidate"
                                onSubmit={(e) => onSubmitLogin(e)}
                            >
                                <div className="mb-3 fv-plugins-icon-container d-flex flex-column justify-content-start align-items-start">
                                    <label htmlFor="empId" className="form-label">Employee id or User name
                                        <span className="required" style={{ color: 'red' }} > *</span>
                                    </label>

                                    <input type="text" className="form-control" id="empId" name="empId"
                                        placeholder="Enter your Employee Id or username"
                                        onChange={(e) => onChangeInput(e)}
                                        value={empId}
                                        autoFocus=""
                                        maxLength="50"
                                    />
                                    {empIdError && (
                                        <div id="empIdError" className="fv-plugins-message-container invalid-feedback">
                                            <span>Employee Id or User Name is required *</span>
                                        </div>
                                    )}                  
                                </div>

                                <div className="mb-3 form-password-toggle fv-plugins-icon-container">
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label" htmlFor="password">Password
                                            <span className="required" style={{ color: 'red' }} > *</span>
                                        </label>
                                    </div>
                                    <div className="input-group input-group-merge has-validation">
                                        <input type={isPasswordVisible ? 'text' : 'password'} id="password"
                                            className="form-control" name="password"
                                            placeholder="**********" aria-describedby="password"
                                            maxLength="20" minLength="4"
                                            onChange={(e) => onChangeInput(e)}
                                            value={password}
                                        />
                                        <span className="input-group-text cursor-pointer" onClick={togglePasswordVisibility}>
                                            <i id="togglePasswordVisibility" className={`bx ${isPasswordVisible ? 'bx-show' : 'bx-hide'}`}></i>
                                        </span>
                                    </div>
                                    {passwordError && (
                                        <div id="passwordError" className="fv-plugins-message-container invalid-feedback">
                                            <span>Password is required *</span>
                                        </div>
                                    )}                                    
                                </div>

                                <div className="mb-3">
                                    <div className="form-check d-flex flex-row justify-content-start">
                                        <input className="form-check-input" type="checkbox" id="remember-me" />
                                        <label className="form-check-label ms-2" htmlFor="remember-me" >
                                            Remember Me
                                        </label>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <button className="btn btn-primary d-grid w-100" 
                                        style={{ backgroundColor: '#0E94C3', minHeight: '40px' }} 
                                        type="submit" disabled={loading}
                                    >
                                        {loading ? (
                                            <div class="spinner-border text-white" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        ) : 'Sign in'}                                        
                                    </button>
                                </div>
                                <input type="hidden" />
                            </form>

                            <p className="text-center">
                                <span>New on our platform?</span>
                                <a href="https://ohoindialife.com/" target="_blank">
                                    <span>Please contact to contact@ohoindialife.com</span>
                                </a>
                            </p>
                        </div>
                    </div>
                        {/* /Register */}
                </div>
            </div>
        </div>
    )
};

export default Login;