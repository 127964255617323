import React, { useState, useEffect } from 'react';
import 'regenerator-runtime/runtime';
import Chart from 'react-apexcharts';
import { fetchAllData, fetchData } from '../../helpers/externapi';
import CircularProgress from '@mui/material/CircularProgress';
import Flatpickr from 'react-flatpickr';
import MapBoxHospital from '../../Components/MapBoxHospital';
import Layout from '../../Layout/Layout';
import { Link } from 'react-router-dom';

export default function Dashboard() {
    const [monthWiseCount, setMonthWiseCount] = useState([]);
    const [progressData, setProgressData] = useState({});
    const [filteredProgressData, setFilteredProgressData] = useState({});
    const [currentDate, setCurrentDate] = useState(new Date());
    const [startDate, setStartDate] = useState('');
    const [filterOption, setFilterOption] = useState('Today');
    const [loading, setLoading] = useState(true);
    const [hospitals, setHospitals] = useState({});
    const [distributerData, setDistributerData] = useState();
    const [rmData, setRmData] = useState();
    const [showAll, setShowAll] = useState(false);
    const [showsAll, setShowsAll] = useState(false);
    const visibleDistributers = distributerData && distributerData.length > 0
        ? (showAll ? distributerData : distributerData.slice(0, 3))
        : [];
    const visibleRMs = rmData && rmData.length > 0
        ? (showsAll ? rmData : rmData.slice(0, 3))
        : [];

    console.log("distributerData: ", distributerData);

    useEffect(() => {
        const getMemberCountPerMonthData = async () => {
            try {
                const memberCountPerMonthData = await fetchAllData('Member/ProgressOfLast6MonthlyCounts', {});
                //console.log("memberCountPerMonthData", memberCountPerMonthData);
                setMonthWiseCount(memberCountPerMonthData);
            } catch (error) {
                console.error('Error fetching member count per month data:', error);
            }
        };

        const getProgressData = async () => {
            try {
                setLoading(true);
                const progressDataResponse = await fetchAllData('Member/ProgressTillNow', {});
                setProgressData(progressDataResponse[0]);
                handleFilterChange('Today');
            } catch (error) {
                console.error('Error fetching progress data:', error);
            }
            setLoading(false);
        };

        const getHospitalsData = async () => {
            try {
                setLoading(true);
                const hospitalsDataResponse = await fetchData('Hospital/all', { 'skip': 0, 'take': 0 });
                setHospitals(hospitalsDataResponse);
            } catch (error) {
                console.error("Error fetching hospitals data:", error);
            }
            setLoading(false);
        };

        getHospitalsData();
        getMemberCountPerMonthData();
        getProgressData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 86400000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const getDistributerAndRmCount = async () => {
            const getDistributerCount = await fetchAllData('Member/GetDistributerCount');
            setDistributerData(getDistributerCount);

            const getRmCount = await fetchAllData('Users/getRMCount');
            setRmData(getRmCount);
        };

        getDistributerAndRmCount();
    }, []);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const formatAmount = (amount) => {
        if (amount === null || undefined) {
            return `₹ 0`;
        } else {
            return `₹ ${amount}`;
        }
    };

    const progressLabels = {
        DistributorCount: 'Advisors',
        MemberCount: 'Customers',
        HospitalCount: 'Network Hospitals',
        //TotalPremiumAmount: 'Premium cards amount',
        TotalPrivilegeAmount: 'Privilege cards amount',
        //TotalPriorityAmount: 'Priority cards amount',
        OHOCardsPremiumCount: 'Sales: Premium cards',
        OHOCardsPrivilegeCount: 'Sales: Privilege cards  ',
    };

    const icons = {
        DistributorCount: <i className="fa-solid fa-users"></i>,
        MemberCount: <i className="fa-solid fa-user"></i>,
        HospitalCount: <i className="fa-regular fa-hospital"></i>,
        TotalPremiumAmount: <i className="fa-solid fa-money-bill-wave"></i>,
        TotalPrivilegeAmount: <i className="fa-solid fa-money-bill-wave"></i>,
        TotalPriorityAmount: <i className="fa-solid fa-money-bill-wave"></i>,
        OHOCardsPremiumCount: <i className="fa-solid fa-id-card"></i>,
        OHOCardsPrivilegeCount: <i className="fa-solid fa-id-card"></i>,
        OHOCardsCount: <i className="fa-solid fa-money-bill-wave"></i>
    };

    const handleFilterChange = async (option) => {
        setFilterOption(option);
        setLoading(true);
        let newStartDate = new Date();
        let newEndDate = new Date();

        if (option === 'Today') {

        } else if (option === 'Last 7 days') {
            newStartDate.setDate(newStartDate.getDate() - 7);
        } else if (option === 'Last 30 days') {
            newStartDate.setDate(newStartDate.getDate() - 30);
        } else if (option === 'Custom') {
            setLoading(false);
            return;
        }

        try {
            const response = await fetchData('Member/GetProgressByFilter', {
                value: [formatDate(newStartDate), formatDate(newEndDate)],
            });
            console.log("response", response);
            setFilteredProgressData(response[0]);
        } catch (error) {
            console.error('Error fetching filtered progress data:', error);
        }
        setLoading(false);
    };

    const handleCustomDateChange = async () => {
        if (startDate && startDate.length === 2) {
            setLoading(true);
            try {
                const response = await fetchData('Member/GetProgressByFilter', {
                    value: [formatDate(startDate[0]), formatDate(startDate[1])],
                });
                //console.log("response", response);
                setFilteredProgressData(response[0]);
            } catch (error) {
                console.error('Error fetching filtered progress data:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const cardStyle = {
        position: 'relative',
        overflow: 'hidden',
        transition: 'box-shadow 0.3s ease',
        marginBottom: '1rem',
    };

    const bottomHighlightStyle = {
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        height: '3px',
        backgroundColor: '#4287f5',
        transition: 'width 0.3s ease',
        width: '0',
    };

    const chartOptions = {
        chart: {
            toolbar: {
                show: false,
            },
            foreColor: '#566a7f',
            zoom: {
                enabled: false,
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
        },
        grid: {
            borderColor: '#f1f3fa',
        },
        xaxis: {
            labels: {
                style: {
                    colors: '#808080',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#808080',
                },
            },
        },
        stroke: {
            curve: 'smooth',
            width: 3,
        },
        dataLabels: {
            enabled: false,
        },
        colors: ['#1E90FF', '#32CD32', '#FFA07A', '#20B2AA'],
    };

    const styles = `
        .cardStyle {
            border: 1px solid #ddd;
            border-radius: 8px;
            transition: box-shadow 0.3s ease-in-out;
            background-color: #F7F7F7; /* Lighter background for cards */
        }
        .bottomHighlightStyle2 {
            height: 4px;
            background-color: #007bff;
            transition: width 0.3s ease-in-out;
        }

        .shimmer {
            animation-duration: 2.5s; /* Increased duration for slower movement */
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: shimmer;
            animation-timing-function: linear;
            background: linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%);
            background-size: 1000px 104px;
            position: relative;
            overflow: hidden;
        }

        @keyframes shimmer {
            0% {
                background-position: -1000px 0;
            }
            100% {
                background-position: 1000px 0;
            }
        }

        .skeletonCardStyle {
            border: 1px solid #ddd;
            border-radius: 8px;
            transition: box-shadow 0.3s ease-in-out;
            background-color: #f0f0f0; /* Lighter background for cards */
            overflow: hidden;
            position: relative;
        }
        .textStyle {
            width: 100%;
            height: 100%;
            background: #c8c8c8; /* Darker background for text */
            animation: shimmer 2.5s infinite linear;
            border-radius: 4px; /* Optional: to match the style */
        }
        .distrubuterHead {
            width: 50%;
            height: 15px;
            background: #c8c8c8;
            animation: shimmer 2.5s infinite linear;
            border-radius: 4px;
        }
        .distributerSmall {
            width: 30%;
            height: 10px;
            background: #c8c8c8;
            animation: shimmer 2.5s infinite linear;
            border-radius: 4px;
        }
        .distributerCount {
            width: 20%;
            height: 15px;
            background: #c8c8c8;
            animation: shimmer 2.5s infinite linear;
            border-radius: 4px;
        }
        `;

    const skeletonLoading = () => (
        <div className="row mt-2">
            <style>
                {styles}
            </style>
            {[...Array(6)].map((_, index) => (
                <div className="col-md-2 mb-4" key={index}>
                    <div className="card custom-card card-border-shadow-primary h-100 skeletonCardStyle shimmer">
                        <div className="card-body custom-card-body">
                            <div className="d-flex align-items-center mb-2 pb-1">
                                <div className="avatar me-2">
                                    <span className="avatar-initial rounded bg-label-primary" style={{ width: '40px', height: '40px' }}></span>
                                </div>
                                <h4 className="ms-1 mb-0 textStyle" style={{ width: '60%', height: '24px' }}></h4>
                            </div>
                            <p className="d-block mb-1 textStyle" style={{ width: '80%', height: '16px' }}></p>

                        </div>
                    </div>
                </div>
            ))}
            <div className="row">

                <div className="col-md-6">
                    <div className="card shadow mb-4 skeletonCardStyle shimmer">
                        <div className="card-body">
                            <h5 className="card-title textStyle" style={{ width: '70%', height: '24px' }}></h5>
                            <div className="textStyle" style={{ width: '100%', height: '350px' }}></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card shadow mb-4 skeletonCardStyle shimmer">
                        <div className="card-body">
                            <h5 className="card-title textStyle" style={{ width: '70%', height: '24px' }}></h5>
                            <div className="textStyle" style={{ width: '100%', height: '350px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
            {Object.keys(progressLabels).map((key) => (
                key !== 'DistributorCount' &&
                key !== 'MemberCount' &&
                key !== 'OHOCardsPremiumCount' &&
                key !== 'OHOCardsPrivilegeCount' && (
                    <div className="col-md-6" key={key}>
                        <div className="card shadow mb-4 skeletonCardStyle shimmer">
                            <div className="card-body">
                                <h5 className="card-title textStyle" style={{ width: '70%', height: '24px' }}></h5>
                                <div className="textStyle" style={{ width: '100%', height: '350px' }}></div>
                            </div>
                        </div>
                    </div>
                )
            ))}
            <div className="col-md-12">


                <h5 className="textStyle" style={{ width: '20%', height: '24px' }}></h5>


                <div className="row mt-2">
                    <div className="col-12 mb-1">
                        <h4 className="card-title textStyle" style={{ width: '30%', height: '32px' }}></h4>
                    </div>
                </div>

                <div className="row mt-2">
                    {Object.keys(progressLabels).map((key) => (
                        <div className="col-md-3 mb-4" key={key}>
                            <div className="card custom-card card-border-shadow-primary h-100 skeletonCardStyle shimmer">
                                <div className="card-body custom-card-body">
                                    <div className="d-flex align-items-center mb-2 pb-1">
                                        <div className="avatar me-2">
                                            <span className="avatar-initial rounded bg-label-primary textStyle" style={{ width: '40px', height: '40px' }}></span>
                                        </div>
                                        <h4 className="ms-1 mb-0 textStyle" style={{ width: '60%', height: '24px' }}></h4>
                                    </div>
                                    <p className="d-block mb-1 textStyle" style={{ width: '80%', height: '16px' }}></p>


                                </div>
                            </div>
                        </div>
                    ))}
                </div>


            </div>
        </div>
    );

    const skeletonDistributer = () => {
        return (
            <div className="card-body">
                <style>
                    {styles}
                </style>
                <ul className="p-0 m-0">
                    {[...Array(10)].map((_, index) => (
                        <li className="d-flex justify-content-between mb-2 pb-1 shimmer" key={index}>
                            <div>
                                <h6 className="mb-0 distrubuterHead"></h6>
                                <small className="text-muted d-block mb-1 distributerSmall"></small>
                            </div>

                            <div className="user-progress d-flex align-items-center gap-1">
                                <span className="fw-medium distributerCount"></span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const handleViewAll = () => {
        setShowAll(true);
    };

    const handleViewsAll = () => {
        setShowsAll(true);
    };

    const renderDistributerAndRmCounts = () => {
        return (
            <>
                <div className="row">
                    <div className="col-lg-12 col-xl-6">
                        <div className="card card-action mb-4" style={{zIndex:1} }>
                            <div className="card-header align-items-center">
                                <h5 className="card-action-title mb-0">Top Advisors by <span className="text-primary">Sales</span></h5>
                            </div>
                            <div className="card-body">
                                <ul className="list-unstyled mb-0">
                                    {distributerData ? (
                                        visibleDistributers.map(distributer => (
                                            <li className="mb-3" key={`${distributer.id}-${distributer.Name}`}>
                                                <div className="d-flex align-items-start">
                                                    <div className="d-flex align-items-start">
                                                        <div className="avatar me-3">
                                                            <img src="/assets/img/dummy-avatar.jpg" alt="Avatar" className="rounded-circle" />
                                                        </div>
                                                        <div className="me-2">
                                                            <Link to={`/advisor/details/${distributer.MemberId}`}>
                                                                <h6 className="mb-0" style={{ color: '#0077cc' }}>{distributer.Name}</h6>
                                                            </Link>

                                                            <small className="text-muted">Assigned cards: {distributer.AssignedCards}</small>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <small className="btn btn-label-primary btn-icon btn-sm">{distributer.SoldCards}</small>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    ) : (
                                        skeletonDistributer()
                                    )}
                                    {!showAll && distributerData && distributerData.length > 3 && (
                                        <li className="text-center">
                                            <a className="text-primary" style={{ cursor: 'pointer' }} onClick={handleViewAll}>
                                                View all Advisors sales
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 col-xl-6">
                        <div className="card card-action mb-4" style={{ zIndex: 1 }}>
                            <div className="card-header align-items-center">
                                <h5 className="card-action-title mb-0">Top RM's by <span className="text-primary">Members Count</span></h5>
                            </div>
                            <div className="card-body">
                                <ul className="list-unstyled mb-0">
                                    {rmData ? (
                                        visibleRMs.map(rm => (
                                            <li key={rm.Name} className="mb-3">
                                                <div className="d-flex align-items-start">
                                                    <div className="d-flex align-items-start">
                                                        <div className="avatar me-3">
                                                            <img src="/assets/img/dummy-avatar.jpg" alt="Avatar" className="rounded-circle" />
                                                        </div>
                                                        <div className="me-2">
                                                            <h6 className="mb-0">{rm.Name}</h6>
                                                            {/* <small class="text-muted">Assigned cards: {distributer.AssignedCards}</small>*/}
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <small className="btn btn-label-primary btn-icon btn-sm">{rm.MemberCount}</small>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    ) : (
                                        skeletonDistributer()
                                    )}
                                    {!showsAll && rmData && rmData.length > 3 && (
                                        <li className="text-center">
                                            <a className="text-primary" style={{ cursor: 'pointer' }} onClick={handleViewsAll}>
                                                View all RM Advisors List
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>



            </>

        );
    };

    const colorMap = {
        DistributorCount: 'linear-gradient(135deg, #FFB6C1, #FFC0CB)', // Light Pink gradient
        MemberCount: 'linear-gradient(135deg, #ADD8E6, #B0E0E6)',      // Light Blue gradient
        HospitalCount: 'linear-gradient(135deg, #98FB98, #A4D3EE)',    // Pale Green gradient
        TotalPremiumAmount: 'linear-gradient(135deg, #FFD700, #FFA500)', // Gold gradient
        TotalPrivilegeAmount: 'linear-gradient(135deg, #FFA07A, #FA8072)', // Light Salmon gradient
        TotalPriorityAmount: 'linear-gradient(135deg, #87CEFA, #4682B4)',  // Light Sky Blue gradient
        OHOCardsPremiumCount: 'linear-gradient(135deg, #FF6347, #FF4500)', // Tomato gradient
        OHOCardsPrivilegeCount: 'linear-gradient(135deg, #9370DB, #8A2BE2)', // Medium Purple gradient
    };


    const dashboardPatterns = {
        DistributorCount: `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><defs><linearGradient id="lg1" x1="0%" y1="0%" x2="100%" y2="100%"><stop offset="0%" style="stop-color:rgba(255, 192, 203, 0.3);stop-opacity:1" /><stop offset="100%" style="stop-color:rgba(255, 105, 180, 0.6);stop-opacity:1" /></linearGradient></defs><rect width="100%" height="100%" fill="url(#lg1)" /><circle cx="75%" cy="75%" r="20%" fill="rgba(0, 0, 0, 0.1)" stroke="rgba(255, 255, 255, 0.2)" stroke-width="2"/></svg>`,

        MemberCount: `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><defs><linearGradient id="lg2" x1="0%" y1="0%" x2="100%" y2="100%"><stop offset="0%" style="stop-color:rgba(173, 216, 230, 0.5);stop-opacity:1" /><stop offset="100%" style="stop-color:rgba(0, 191, 255, 0.8);stop-opacity:1" /></linearGradient></defs><rect width="100%" height="100%" fill="url(#lg2)" /><path d="M10,80 Q40,20 70,80 T100,80" stroke="rgba(0, 0, 0, 0.2)" stroke-width="3" fill="transparent"/></svg>`,

        HospitalCount: `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><defs><linearGradient id="lg3" x1="0%" y1="0%" x2="100%" y2="100%"><stop offset="0%" style="stop-color:rgba(144, 238, 144, 0.4);stop-opacity:1" /><stop offset="100%" style="stop-color:rgba(34, 139, 34, 0.6);stop-opacity:1" /></linearGradient></defs><rect width="100%" height="100%" fill="url(#lg3)" /> <polygon points="60,10 70,40 100,40 80,70 60,50 40,70 20,40 50,40" stroke="rgba(255, 255, 255, 0.4)" fill="transparent" stroke-width="3" /></svg>`,

        TotalPremiumAmount: `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><defs><linearGradient id="lg4" x1="0%" y1="0%" x2="100%" y2="100%"><stop offset="0%" style="stop-color:rgba(255, 215, 0, 0.5);stop-opacity:1" /><stop offset="100%" style="stop-color:rgba(255, 140, 0, 0.7);stop-opacity:1" /></linearGradient></defs><rect width="100%" height="100%" fill="url(#lg4)" /><path d="M5,85 Q40,50 70,65 T100,40" stroke="rgba(255, 255, 255, 0.3)" stroke-width="3" fill="transparent"/></svg>`,

        TotalPrivilegeAmount: `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><defs><linearGradient id="lg5" x1="0%" y1="0%" x2="100%" y2="100%"><stop offset="0%" style="stop-color:rgba(255, 160, 122, 0.6);stop-opacity:1" /><stop offset="100%" style="stop-color:rgba(255, 99, 71, 0.8);stop-opacity:1" /></linearGradient></defs><rect width="100%" height="100%" fill="url(#lg5)" /><path d="M10,80 C25,40 75,40 90,80" stroke="rgba(255, 255, 255, 0.3)" stroke-width="3" fill="transparent"/></svg>`,

        TotalPriorityAmount: `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><defs><linearGradient id="lg6" x1="0%" y1="0%" x2="100%" y2="100%"><stop offset="0%" style="stop-color:rgba(135, 206, 250, 0.5);stop-opacity:1" /><stop offset="100%" style="stop-color:rgba(70, 130, 180, 0.7);stop-opacity:1" /></linearGradient></defs><rect width="100%" height="100%" fill="url(#lg6)" /><circle cx="75%" cy="75%" r="20%" fill="rgba(255, 255, 255, 0.3)" stroke="rgba(255, 255, 255, 0.5)" stroke-width="2"/></svg>`,

        OHOCardsPremiumCount: `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><defs><linearGradient id="lg7" x1="0%" y1="0%" x2="100%" y2="100%"><stop offset="0%" style="stop-color:rgba(255, 99, 71, 0.4);stop-opacity:1" /><stop offset="100%" style="stop-color:rgba(255, 69, 0, 0.7);stop-opacity:1" /></linearGradient></defs><rect width="100%" height="100%" fill="url(#lg7)" /><path d="M5,80 Q30,50 70,50 T100,60" stroke="rgba(255, 255, 255, 0.3)" stroke-width="3" fill="transparent"/></svg>`,

        OHOCardsPrivilegeCount: `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><defs><linearGradient id="lg8" x1="0%" y1="0%" x2="100%" y2="100%"><stop offset="0%" style="stop-color:rgba(255, 99, 71, 0.4);stop-opacity:1" /><stop offset="100%" style="stop-color:rgba(255, 69, 0, 0.7);stop-opacity:1" /></linearGradient></defs><rect width="100%" height="100%" fill="url(#lg8)" /><path d="M10,70 L40,30 L70,50 L90,20" stroke="rgba(255, 255, 255, 0.3)" stroke-width="3" fill="transparent"/></svg>`,
    };



    return (
        <Layout> 
            <div className="mt-4">

                {loading && skeletonLoading()}
                {!loading && (
                    <>

                        {renderDistributerAndRmCounts()}

                        <div className="row mt-2">
                            <div className="col-12 mb-2">
                                <h4 className="card-title">
                                    OUR PROGRESS TILL TODAY <span>{formatDate(currentDate)}</span>
                                </h4>
                            </div>
                            {Object.entries(progressLabels).map(([key, label]) => (
                                <div className="col-md-4 mb-4" key={key}>
                                    <div
                                        className="card custom-card card-border-shadow-primary h-100"
                                        style={{
                                            ...cardStyle,
                                            background: `linear-gradient(135deg, ${colorMap[key]})`,
                                            backgroundImage: `url('data:image/svg+xml;base64,${btoa(dashboardPatterns[key])}')`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'right center',
                                            color: '#000000', // Ensures text contrast
                                            border: 'none',
                                            zIndex:1
                                        }}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.boxShadow = '0 8px 16px 0 rgba(0,0,0,0.2)';
                                            e.currentTarget.querySelector('.card-bottom-highlight').style.width = '100%';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.boxShadow = 'none';
                                            e.currentTarget.querySelector('.card-bottom-highlight').style.width = '0';
                                        }}
                                    >
                                        <div className="card-body custom-card-body">
                                            <div className="d-flex align-items-center mb-2 pb-1">
                                                <div className="avatar me-2">
                                                    <span className="avatar-initial rounded bg-label-primary">{icons[key]}</span>
                                                </div>
                                                <h4 className="mb-0" style={{ color: 'black', fontWeight: 'bold' }}>
                                                    {key === 'TotalPrivilegeAmount' ? formatAmount(progressData[key]) :
                                                        progressData[key]
                                                    }
                                                </h4>
                                            </div>
                                            <p className="d-block mb-1">{label}</p>
                                            <div className="card-bottom-highlight" style={bottomHighlightStyle}></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>


                        <div className="row">


                            <h4>Progress Of Last 6 months</h4>

                            {/* Distributors */}
                            <div className="col-md-6">
                                <div className="card shadow mb-4" style={{zIndex:1} }>
                                    <div className="card-body">
                                        <h5 className="card-title"> {progressLabels.DistributorCount}</h5>
                                        <Chart
                                            options={{
                                                ...chartOptions,
                                                xaxis: {
                                                    ...chartOptions.xaxis,
                                                    categories: monthWiseCount.map((item) => item.month),
                                                },
                                            }}
                                            series={[
                                                {
                                                    name: progressLabels.DistributorCount,
                                                    data: monthWiseCount.map((item) => item.DistributorCount || 0),
                                                },
                                            ]}
                                            type="line"
                                            height={350}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Customers */}
                            <div className="col-md-6">
                                <div className="card shadow mb-4" style={{ zIndex: 1 }}>
                                    <div className="card-body">
                                        <h5 className="card-title">{progressLabels.MemberCount}</h5>
                                        <Chart
                                            options={{
                                                ...chartOptions,
                                                xaxis: {
                                                    ...chartOptions.xaxis,
                                                    categories: monthWiseCount.map((item) => item.month),
                                                },
                                            }}
                                            series={[
                                                {
                                                    name: progressLabels.MemberCount,
                                                    data: monthWiseCount.map((item) => item.MemberCount || 0),
                                                },
                                            ]}
                                            type="line"
                                            height={350}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="card shadow mb-4" style={{ zIndex: 1 }}>
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            Card Sales
                                        </h5>
                                        <Chart
                                            options={{
                                                ...chartOptions,
                                                xaxis: {
                                                    ...chartOptions.xaxis,
                                                    categories: monthWiseCount.map((item) => item.month),
                                                },
                                                colors: ['#007BFF', '#FF5733'], // Custom colors for each line
                                            }}
                                            series={[
                                                {
                                                    name: progressLabels.OHOCardsPremiumCount,
                                                    data: monthWiseCount.map((item) => item.OHOCardsPremiumCount || 0),
                                                },
                                                {
                                                    name: progressLabels.OHOCardsPrivilegeCount,
                                                    data: monthWiseCount.map((item) => item.OHOCardsPrivilegeCount || 0),
                                                },
                                            ]}
                                            type="line"
                                            height={350}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="card shadow mb-4" style={{ zIndex: 1 }}>
                                    <div className="card-body">
                                        <h5 className="card-title">Card Sales Amount</h5>
                                        <Chart
                                            options={{
                                                ...chartOptions,
                                                xaxis: {
                                                    ...chartOptions.xaxis,
                                                    categories: monthWiseCount.map((item) => item.month),
                                                },
                                                colors: ['#007BFF', '#FF5733'], // Custom colors for each line
                                            }}
                                            series={[
                                                {
                                                    name: 'Premium Cards Amount',
                                                    data: monthWiseCount.map((item) =>
                                                        (item.TotalPremiumAmount.replace(/,/g, '')) || 0
                                                    ),
                                                },
                                                {
                                                    name: 'Privilege Cards Amount',
                                                    data: monthWiseCount.map((item) =>
                                                        (item.TotalPrivilegeAmount.replace(/,/g, '')) || 0
                                                    ),
                                                },
                                            ]}
                                            type="line"
                                            height={350}
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div className="row mt-4">
                            {Object.entries(progressLabels).map(([key, label]) => (
                                key !== 'DistributorCount' &&
                                key !== 'MemberCount' &&
                                key !== 'OHOCardsPremiumCount' &&
                                key !== 'OHOCardsPrivilegeCount' &&
                                key !== 'TotalPremiumAmount' &&
                                key !== 'TotalPrivilegeAmount' && (
                                    <div className="col-md-6" key={key}>
                                        <div className="card shadow mb-4" style={{ zIndex: 1 }}>
                                            <div className="card-body">
                                                <h5 className="card-title">{label}</h5>
                                                <Chart
                                                    options={{
                                                        ...chartOptions,
                                                        xaxis: {
                                                            ...chartOptions.xaxis,
                                                            categories: monthWiseCount.map((item) => item.month),
                                                        },
                                                    }}
                                                    series={[
                                                        {
                                                            name: label,
                                                            data: monthWiseCount.map((item) => item[key] || 0),
                                                        },
                                                    ]}
                                                    type="line"
                                                    height={350}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>

                        <div className="mb-3">
                            <h4>Network Hospitals List</h4>
                            <div style={{ display: 'flex', width: '140%' }}>
                                {hospitals && hospitals.length > 0 ?
                                    <MapBoxHospital hospitalsData={hospitals} />
                                    : null
                                }
                            </div>
                        </div>


                        {loading && (
                            <div className="d-flex justify-content-center align-items-center mt-5">
                                <CircularProgress />
                            </div>
                        )}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card" style={{ zIndex: 1 }}>
                                    <div className="card-body">
                                        <h5>Filter</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="col-3 me-3">
                                                <select
                                                    className="form-select"
                                                    value={filterOption}
                                                    onChange={(e) => handleFilterChange(e.target.value)}
                                                >
                                                    <option value="Today">Today</option>
                                                    <option value="Last 7 days">Last Week</option>
                                                    <option value="Last 30 days">Last Month</option>

                                                    <option value="Custom">Custom Date Range</option>
                                                </select>
                                            </div>
                                            {filterOption === 'Custom' && (
                                                <>
                                                    <div className="col-md-3 col-4 mb-4 my-1">
                                                        <label htmlFor="flatpickr-range" className="form-label">Select multiple dates</label>
                                                        <Flatpickr
                                                            id="flatpickr-range"
                                                            className="form-control"
                                                            placeholder="YYYY-MM-DD to YYYY-MM-DD"
                                                            value={startDate}
                                                            options={{
                                                                mode: 'range',
                                                                dateFormat: 'Y-m-d',
                                                            }}
                                                            onChange={(selectedDates) => {
                                                                if (selectedDates.length === 2) {
                                                                    setStartDate(selectedDates);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mx-2 mt-2">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={handleCustomDateChange}
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12 mb-1">
                                                <h4 className="card-title" style={{ color: '#4287f5' }}>
                                                    Customized Data
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            {loading ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <CircularProgress />
                                                </div>
                                            ) : (
                                                <div className="table-responsive">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Label</th>
                                                                <th scope="col">Value</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.entries(progressLabels).map(([key, label]) => (
                                                                <tr key={key}>
                                                                    <td>{label}</td>
                                                                    <td>
                                                                        {key === 'TotalPremiumAmount' ?
                                                                            formatAmount(filteredProgressData[key]) :
                                                                            key === 'TotalPrivilegeAmount' ?
                                                                                formatAmount(filteredProgressData[key]) :
                                                                                key === 'TotalPriorityAmount' ?
                                                                                    formatAmount(filteredProgressData[key]) :
                                                                                    filteredProgressData[key]
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                )}
            </div>
        </Layout>
    );
}