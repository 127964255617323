import React, { useEffect, useState } from "react";
import PageWrapper from "../../Components/PageWrapper";
import { useLocation, useParams, Link } from "react-router-dom";
import HospitalPersonalDetails from "./HospitalPersonalDetails";
import { fetchAllData } from "../../helpers/externapi";
import Layout from "../../Layout/Layout";
export default function HospitalDetails(props) {
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [details, setDetails] = useState(null)
    const location = useLocation();
    const [navPath, setNavPath] = useState('');
    const id = useParams();

    useEffect(() => {
        if (location.pathname.toLowerCase().indexOf('new') > 0) {
            setNavPath('new')
        } else if (location.pathname.toLowerCase().indexOf('details') > 0) {
            setNavPath('details')
        } else {
            setNavPath('list')
        }
    }, [location]);    
        
    useEffect(() => {
        setLoading(false);

        const getHospitalDetails = async (hospitalId) => {
            const hospitalData = await fetchAllData(`Hospital/GetById/${hospitalId}`);
            setDetails(hospitalData && hospitalData[0]);
        }

        if (id.Id > 0) {
            getHospitalDetails(id.Id);
        }
    }, []);

    return (
        <Layout>
            <PageWrapper loading={loading} error={error}>
                {details ? (
                    <div>
                        <div className="text-center bg-white p-2 m-1">
                            <div className="row align-items-center">
                                <div className="col-md-9">
                                    <ul className="nav nav-md nav-pills">
                                        {(navPath !== "new") && (
                                            <li className="nav-item">
                                                <Link className={"nav-link " + (navPath === "list" ? " active" : "")} to={`/hospitals/new`} onClick={() => setNavPath('new')}><i className="tf-icons navbar-icon bx bx-plus-circle"></i>&nbsp;Add New</Link>
                                            </li>
                                        )}
                                        {(navPath !== "list") && (
                                            <li className="nav-item">
                                                <Link className={"nav-link " + (navPath === "new" ? " active" : "")} to={`/hospitals/list`} onClick={() => setNavPath('list')}><i className="bx bx-user me-1"></i>&nbsp;List</Link>
                                            </li>
                                        )}

                                        {(navPath !== "list" && navPath !== "new") && (
                                            <li className="nav-item">
                                                <Link className={"nav-link " + (navPath === "details" ? " active" : "")} /*to={`/hospitals/details`}*/ onClick={() => setNavPath('details')}><i className="tf-icons navbar-icon bx bx-edit"></i>&nbsp;Details</Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>

                                <div className="col-md-3">
                                    <h5 className="fw-bold py-1 mb-1 text-md-end">
                                        Hospitals Management
                                    </h5>
                                </div>
                            </div>
                        </div>

                        <div id="detail">
                            <HospitalPersonalDetails data={details} /> 
                        </div>
                    </div>                    
                ) : (
                    <div>
                        <div className="text-center bg-white p-2 m-1">
                            <div className="row align-items-center">
                                <div className="col-md-9">
                                    <ul className="nav nav-md nav-pills">
                                        {(navPath !== "new") && (
                                            <li className="nav-item">
                                                <Link className={"nav-link " + (navPath === "list" ? " active" : "")} to={`/hospitals/new`} onClick={() => setNavPath('new')}><i className="tf-icons navbar-icon bx bx-plus-circle"></i>&nbsp;Add New</Link>
                                            </li>
                                        )}
                                        {(navPath !== "list") && (
                                            <li className="nav-item">
                                                <Link className={"nav-link " + (navPath === "new" ? " active" : "")} to={`/hospitals/list`} onClick={() => setNavPath('list')}><i className="bx bx-user me-1"></i>&nbsp;List</Link>
                                            </li>
                                        )}

                                        {(navPath !== "list" && navPath !== "new") && (
                                            <li className="nav-item">
                                                <Link className={"nav-link " + (navPath === "details" ? " active" : "")} /*to={`/hospitals/details`}*/ onClick={() => setNavPath('details')}><i className="tf-icons navbar-icon bx bx-edit"></i>&nbsp;Details</Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>

                                <div className="col-md-3">
                                    <h5 className="fw-bold py-1 mb-1 text-md-end">
                                        Hospitals Management
                                    </h5>
                                </div>
                            </div>
                        </div>

                        <div className='text-center mt-5'>
                            <h4 className='text-danger'>Hospital Not Available</h4>
                        </div>
                    </div>                   
                )}   
            </PageWrapper>
        </Layout>
    )
}