import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BoxWrapper from "../../Components/BoxWrapper";
import { fetchData } from "../../helpers/externapi";

export default function HospitalPersonalDetails(props) {
    const location = useLocation();
    const [profile, setProfile] = React.useState(props.data);
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = React.useState(false);

   console.log("profile: ", profile);

    const handleBackToList = () => {
        navigate('/hospitals/list');
    };  

    //console.log('profile', profile);
    const handleEditForm = () => {
        navigate('/hospitals/new', { state: { profile } });
    };

    const handleUpdateLocation = async () => {
        try {
            const response = await fetchData('Geocoding/forward', { hospitalId: profile.HospitalId })
            console.log("Response: ", response);
            window.location.reload();
        } catch (error) {
            console.log("Error fetching location data: ", error);
        }
    };

    return (
        <>
            <div className="btn-toolbar mb-1 d-flex justify-content-between bg-white">
                <div className="d-flex align-items-center mx-2">
                    <button onClick={handleEditForm} className="btn btn-md btn-primary">Edit</button>
                </div>
            </div>
            <BoxWrapper>
                {!isEditing && (
                    <>
                        <div className="card card-action mb-2">
                            <div className="card-header" >
                                <h5 className="card-title" style={{ color: '#008000' }}>Basic Information :</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <p><i className="fas fa-hospital"></i><strong> Hospital Name:</strong> <a href={`tel:${profile.HospitalName}`}>{profile.HospitalName || "Not given"}</a></p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><i className="fas fa-phone"></i><strong> Phone Number:</strong> <a href={`tel:${profile.HospitalMobileNumber}`}>{profile.HospitalMobileNumber || "Not given"}</a></p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><i className="fas fa-phone"></i> <strong>Landline Number: </strong><a href={`tel:${profile.Landline}`}>{profile.Landline || "Not given"}</a></p>
                                    </div>
                                    <div className="col-md-6">
                                        <p><i className="fas fa-envelope"></i> <strong>Email:</strong> <a href={`mailto:${profile.Email}`}>{profile.Email || "Not given"}</a></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <p><i className="fas fa-map-marker"></i> <strong>City : </strong> {profile.City || "Not given"}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><i className="fas fa-thumbtack"></i> <strong>Pincode : </strong> {profile.Pincode || "Not given"}</p>
                                    </div>

                                    <div className="col-md-4">
                                        <p><strong>Mandal:</strong> {profile.Mandal || "Not given"}</p>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-md-4">
                                        <p><i className="fas fa-globe"></i> <strong>Website : </strong>
                                            <a href={profile.Website} target="_blank" rel="noopener noreferrer">
                                                {profile.Website ? "Hospital Website" : "Not given"}
                                            </a>
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><strong>Specialization:</strong> {profile.Specialization || "Not given"}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <p>
                                        <i className="fas fa-map-marker-alt"></i>
                                        <strong>Address : </strong> {`${profile.AddressLine1 || "Not given"} ${profile.AddressLine2 || ""}`}
                                    </p>
                                </div>

                            </div>
                        </div>

                        <div className="card card-action mb-2">
                            <div className="card-header">
                                <h5 className="card-title" style={{ color: '#008000' }}>LOCATION COORDINATES: </h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <p><strong>Latitude:</strong> {profile.Latitude || "Not given"}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><strong>Longitude:</strong> {profile.Longitude || "Not given"}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><strong>Map View:</strong> {profile.MapView || "Not given"}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><strong>Route Map Name:</strong> {profile.RouteName || "Not given"}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><strong>Route Map:</strong> {profile.RouteMap || "Not given"}</p>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {!profile.Latitude || !profile.Longitude ? (
                                        <button className="btn btn-primary" onClick={() => handleUpdateLocation()}>Update Location</button>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="card card-action mb-2">
                            <div className="card-header">
                                <h5 className="card-title" style={{ color: '#008000' }}>SPOCs INFORMATION </h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    {/* SPOC 1 Information */}
                                    <div className="col-md-6">
                                        <div className="card shadow-none bg-transparent border border-primary mb-3">
                                            <div className="card-header">
                                                <h6 className="card-title">SPOC 1 </h6>
                                            </div>
                                            <div className="card-body">
                                                <p><strong>Name:</strong> {profile.Spoc1Name || "Not given"}</p>
                                                <p><strong>Designation:</strong> {profile.Spoc1Designation || "Not given"}</p>
                                                <p><strong>Contact Number:</strong> {profile.Spoc1ContactNumber || "Not given"}</p>
                                                <p><strong>Alternate Contact Number:</strong> {profile.Spoc1AlternateContactNumber || "Not given"}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* SPOC 2 Information */}
                                    <div className="col-md-6">
                                        <div className="card shadow-none bg-transparent border border-primary mb-3">
                                            <div className="card-header">
                                                <h6 className="card-title">SPOC 2 </h6>
                                            </div>
                                            <div className="card-body">
                                                <p><strong>Name:</strong> {profile.Spoc2Name || "Not given"}</p>
                                                <p><strong>Designation:</strong> {profile.Spoc2Designation || "Not given"}</p>
                                                <p><strong>Contact Number:</strong> {profile.Spoc2ContactNumber || "Not given"}</p>
                                                <p><strong>Alternate Contact Number:</strong> {profile.Spoc2AlternateContactNumber || "Not given"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-action mb-2">
                            <div className="card-header">
                                <h5 className="card-title" style={{ color: '#008000' }}>ADDITIONAL INFORMATION</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <p>
                                            <strong>Aarogyasri : </strong> {profile.Aarogyasri || "Not given"}
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <p>
                                            <strong>Call to Front Desk : </strong> {profile.CallToFrontDesk || "Not given"}
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <p>
                                            <strong>Resident Counselling Fee : </strong> {profile.PatientCounsellingFee || "Not given"}
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <p>
                                            <strong>Menu Card for Diagnostics : </strong> {profile.MenuCardForDiagnostics || "Not given"}
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <p>
                                            <strong>Discount On Diagnostics: </strong> {profile.DiscountOnDiagnostics || "Not given"}
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <p>
                                            <strong>Is Free OP Consultation : </strong> {profile.IsFreeOPConsultation || "Not given"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <p><strong>Is Agreement Received:</strong> {profile.IsAgreementReceived || "Not given"}</p>
                                </div>
                                <div className="col-md-12">
                                    <p>
                                        <strong>MOU File:</strong>
                                        {profile.MOUFileName ?
                                            profile.MOUFileName
                                            : "Not given"}
                                    </p>
                                </div>

                            </div>
                        </div>
                    </>
                )}
            </BoxWrapper>
        </>
    );
}