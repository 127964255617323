import './layout.css';
import React from 'react';
import TopNav from './TopNav'; // Import the top navigation component
import Menu from './Menu'; // Import the menu component
import Footer from './Footer'; // Import the footer component

const Layout = ({ children }) => {
    return (
        <div id='layout-container' className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu /> {/* Top Navigation component */}
                <div className="layout-page">
                    <div className="content-wrapper">
                        <TopNav /> {/* Menu component */}
                        <div id='scrollable-content' className="container-xxl flex-grow-1">
                            {children} {/* Render the main content here */}
                            <Footer />                                                                    
                        </div>
                         {/* Footer component */}
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>
    );
};

export default Layout;
