import React, { useState, useEffect } from 'react';
import "cleave.js/dist/addons/cleave-phone.in";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchAllData, fetchData, fetchUpdateData, uploadImage } from "../../helpers/externapi";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export default function Registration() {
    const location = useLocation();
    const profileFromLocation = location.state ? location.state.profile : null;
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [states, setStates] = React.useState([]);
    const [districts, setDistricts] = React.useState([]);
    const [HospitalsData, setHospitalsData] = React.useState();
    const [errors, setErrors] = useState({});
    const [isEditForm, setIsEditForm] = useState(true);
    const [memberId, setMemberId] = React.useState('');
    const [isAddForm, setIsAddForm] = React.useState(false);
    const [formError, setFormError] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [selectedStateId, setSelectedStateId] = useState(null);
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [routename, setRouteName] = React.useState();
    const [userId, setUserId] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const initialFormData = {
        HospitalName: "", HospitalMobileNumber: "", HospitalContact: "", Landline: "", Email: "", AddressLine1: "", AddressLine2: "", City: "", DistrictId: "", StateId: "", Mandal: "", Pincode: "", Website: "",
        Longitude: "", Latitude: "", CreatedBy: "", UpdatedBy: "", Specialization: "", Location: "", Area: "", Spoc1Name: "", Spoc1Designation: "", Spoc1ContactNumber: "",
        Spoc1AlternateContactNumber: "", MOUFileName: "", Spoc2Name: "", Spoc2ContactNumber: "", Spoc2AlternateContactNumber: "", Spoc2Designation: "", RouteMapId: "", MapView: "", Aarogyasri: "",
        Agreement: "", PatientCounsellingFee: "", MenuCardForDiagnostics: "", DiscountOnDiagnostics: "", PartnershipCertificate: "", CallToFrontDesk: "",
        IsFreeOPConsultation: "", IsAgreementReceived: "", RouteMaps: "", RouteName: ""
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        const userIdFromStorage = parseInt(sessionStorage.getItem("UserId"), 10) || 0;
        setUserId(userIdFromStorage);
        if (profileFromLocation) {
            setIsEditForm(true);
            setMemberId(profileFromLocation.HospitalId);
            setFormData(profileFromLocation);
            setSelectedStateId(profileFromLocation.StateId);

            // Set selected image from profile data if it exists
            if (profileFromLocation.MOUFileName) {
                setSelectedImage(profileFromLocation.MOUFileName);
            }
        } else {
            setIsAddForm(true);
            setFormData(initialFormData);
        }
    }, [profileFromLocation]);

    useEffect(() => {
        const getStates = async () => {
            setLoading(true);
            const statesData = await fetchData("States/all", { "skip": 0, "take": 0 });
            setStates(statesData);
            setLoading(false);
        }
        getStates();
    }, []);

    useEffect(() => {
        const getDistricts = async () => {
            if (selectedStateId !== null) {
                setLoading(true);
                const districtsData = await fetchAllData(`Districts/GetByStateId/${selectedStateId}`);
                setDistricts(districtsData);
                setLoading(false);
            } else {
                setDistricts([]);
            }
        }
        getDistricts();
    }, [selectedStateId]);

    useEffect(() => {
        const getRouteName = async () => {
            const routename = await fetchData("RouteMap/all", { "skip": 0, "take": 0 });
            setRouteName(routename);

            setLoading(false);
        };
        getRouteName();
    }, []);

    const handleBackToView = () => {
        if (profileFromLocation && profileFromLocation.HospitalId) {
            navigate(`/hospitals/details/${profileFromLocation.HospitalId}`);
        } else {
            navigate("/hospitals/list");
        }
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setErrors({});
        setFormError({});
    };

    const validateForm = async () => {
        let err = {};

        if (formData.HospitalName === '') {
            err.HospitalName = 'Please Enter Hospital Name';            
        } else if (formData.HospitalName.length < 3) {
            err.HospitalName = 'Hospital Name must be grater than 2 letters';  
        }
        if (typeof formData.HospitalMobileNumber === 'string' && formData.HospitalMobileNumber.trim() === '') {
            err.HospitalMobileNumber = 'Please enter a valid 10-digit mobile number';
        } else if (!/^[6-9]\d{9}$/.test(formData.HospitalMobileNumber && formData.HospitalMobileNumber.trim())) {
            err.HospitalMobileNumber = 'Mobile Number must start with 6, 7, 8, or 9 and must be 10 digits';
        }

        // if (formData.Landline && formData.Landline.trim() === '') {
        //     err.Landline = 'Please enter a landline number';
        // } else if (!/^\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(formData.Landline && formData.Landline.trim())) {
        //     err.Landline = 'Landline number format is invalid';
        // } else if (formData.Landline && formData.Landline.trim().replace(/[-.\s]/g, '').length > 15) {
        //     err.Landline = 'Landline number cannot exceed 15 digits';
        // }

        // if (!formData.Website || formData.Website.trim() === '') {
        //     err.Website = 'Please Enter Hospital Website';
        // }
        // if (formData.Email && formData.Email.trim() === '') {
        //     err.Email = 'Please Enter Email';
        // } else {
        //     let emailRegex = /^\w+([\.-]?\w+)*@gmail.com$/;
        //     if (!emailRegex.test(formData.Email)) {
        //         err.Email = 'Email Must be in @gmail.com Format';
        //     }
        // }
        if (formData.AddressLine1.length < 2) {
            err.AddressLine1 = 'Please Enter Your Address';
        }
        if (formData.City && formData.City.trim() === '') {
            err.City = 'Please Enter Your Cityname';
        }
        if (!profileFromLocation) {
            if (!formData.DistrictId || formData.DistrictId.trim() === '') {
                err.DistrictId = 'Please Select Your District';
            }
        } else {
            if (!formData.DistrictId || formData.DistrictId.length === 0) {
                err.DistrictId = 'Please Select Your District';
            }
        }

        if (!profileFromLocation) {
            if (!formData.StateId || formData.StateId.trim() === '') {
                err.StateId = 'Please Select Your State';
            }
        } else {
            if (!formData.StateId || formData.StateId.length === 0) {
                err.StateId = 'Please Select Your State';
            }
        }

        if (typeof formData.Pincode === 'string' && formData.Pincode.trim() === '') {
            err.Pincode = 'Please enter a valid 6-digit pin code';
        } else if (typeof formData.Pincode === 'string' && formData.Pincode.trim().length !== 6) {
            err.Pincode = 'Pincode must be 6 digits';
        }

        if (formData.City === '') {
            err.City = 'Enter city Name';
        } else if (formData.City && formData.City.trim() !== '') {
            const isValidCity = /^[A-Za-z\s]+$/.test(formData.City);
            if (!isValidCity) {
                err.City = 'City name should contain only alphabetic characters';
            }
        }

        // if (formData.Latitude && formData.Latitude.trim() === '') {
        //     err.Latitude = 'Please Enter Latitude';
        // } else {
        //     const latitude = parseFloat(formData.Latitude && formData.Latitude.trim());
        //     if (isNaN(latitude) || latitude < -90 || latitude > 90) {
        //         err.Latitude = 'Latitude must be a number between -90 and 90';
        //     }
        // }


        // Validate Longitude if provided
        // if (formData.Longitude && formData.Longitude.trim() == '') {
        //     err.Longitude = 'Please Enter Longitude';
        // } else {
        //     const longitude = parseFloat(formData.Longitude && formData.Longitude.trim());
        //     if (isNaN(longitude) || longitude < -180 || longitude > 180) {
        //         err.Longitude = 'Longitude must be a number between -180 and 180';
        //     }
        // }
        // if (formData.MapView && formData.MapView.trim() === '') {
        //     err.MapView = 'Please Enter Map View';
        // }
        // if (formData.RouteName && formData.RouteName.trim() === '') {
        //     err.RouteName = 'Please Enter  Route Name';
        // }

        // if (!selectedImage) {
        //     err.MOUFileName = 'Please upload a file only .pdf and .doc';
        // }

        if (formData.Spoc1Name === '') {
            err.Spoc1Name = 'Please Enter Spoc1Name';
        }
        if (formData.Spoc1Designation === '') {
            err.Spoc1Designation = 'Please Enter Spoc1Designation';
        }

        if (formData.Spoc1ContactNumber && formData.Spoc1ContactNumber.trim() === '') {
            err.Spoc1ContactNumber = 'Please Enter SPOC 1 Contact Number';
        } else if (!/^[6-9]\d{9}$/.test(formData.Spoc1ContactNumber && formData.Spoc1ContactNumber.trim())) {
            err.Spoc1ContactNumber = 'SPOC 1 Contact Number must start with 6, 7, 8, or 9 and must be 10 digits';
        }

        //if (formData.Spoc1AlternateContactNumber.trim() !== '') {
        //    if (!/^\d{10}$/.test(formData.Spoc1AlternateContactNumber.trim())) {
        //        err.Spoc1AlternateContactNumber = 'SPOC 1 Alternate Contact Number must be 10 digits';
        //    }
        //}

        if (formData.Spoc2ContactNumber && formData.Spoc2ContactNumber.trim() !== '') {
            if (!/^[6-9]\d{9}$/.test(formData.Spoc2ContactNumber.trim())) {
                err.Spoc2ContactNumber = 'SPOC 2 Contact Number must start with 6, 7, 8, or 9 and must be 10 digits';
            }
        }

        //if (formData.Spoc2AlternateContactNumber.trim() !== '') {
        //    if (!/^\d{10}$/.test(formData.Spoc2AlternateContactNumber.trim())) {
        //        err.- = 'SPOC 2 Alternate Contact Number must be 10 digits';
        //    }
        //}


        if (typeof formData.IsAgreementReceived !== 'boolean') {
            err.IsAgreementReceived = 'Please select if the agreement is received';
        }

        setFormError({ ...err });

        const isValid = Object.keys(err).length === 0;
        return isValid;
    };

    const onChangeHandler = (event) => {
        const { name, value, files } = event.target;
        let updatedValue = value;

        if (name === 'MOUFileName') {
            updatedValue = files[0];
        } else if (name === 'IsAgreementReceived') {
            updatedValue = value === 'Yes';
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: updatedValue
        }));

        if (name === 'StateId') {
            setSelectedStateId(value);
            setFormData((prevFormData) => ({
                ...prevFormData,
                DistrictId: ''
            }));
        }
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        const isValid = await validateForm();

        if (isValid) {
            const mobileNumber = formData.HospitalMobileNumber;
            const formattedNumber = mobileNumber ? mobileNumber.replace(/\s/g, '') : '';

            let HospitalData;
            let HospitalsId;

            if (!formData.HospitalId) {
                HospitalData = await fetchData("Hospital/add", {
                    hospitalname: formData.HospitalName,
                    mobileNumber: formattedNumber,
                    Landline: formData.Landline,
                    email: formData.Email,
                    addressLine1: formData.AddressLine1,
                    addressLine2: formData.AddressLine2,
                    districtId: formData.DistrictId,
                    stateId: formData.StateId,
                    pincode: formData.Pincode,
                    city: formData.City,
                    website: formData.Website,
                    mandal: formData.Mandal,
                    longitude: formData.Longitude,
                    latitude: formData.Latitude,
                    mapView: formData.MapView,
                    routeMapId: formData.RouteMapId ? formData.RouteMapId : null,
                    routenames: formData.RouteName,
                    spoc1Name: formData.Spoc1Name,
                    spoc1Designation: formData.Spoc1Designation,
                    spoc1ContactNumber: formData.Spoc1ContactNumber,
                    spoc1AlternateContactNumber: formData.Spoc1AlternateContactNumber,
                    spoc2Name: formData.Spoc2Name,
                    spoc2ContactNumber: formData.Spoc2ContactNumber,
                    spoc2AlternateContactNumber: formData.Spoc2AlternateContactNumber,
                    spoc2Designation: formData.Spoc2Designation,
                    isAgreementReceived: formData.IsAgreementReceived,
                    aarogyasri: formData.Aarogyasri,
                    patientCounsellingFee: formData.PatientCounsellingFee,
                    specialization: formData.Specialization,
                    callToFrontDesk: formData.CallToFrontDesk,
                    menuCardForDiagnostics: formData.MenuCardForDiagnostics,
                    discountOnDiagnostics: formData.DiscountOnDiagnostics,
                    isFreeOPConsultation: formData.IsFreeOPConsultation,
                    mOUFileName: formData.MOUFileName
                });
                setHospitalsData(HospitalData);
                setSnackbarMessage("Hospital Added Succesfully");

            } else {
                HospitalData = await fetchUpdateData("Hospital/update", {
                    hospitalid: formData.HospitalId,
                    hospitalname: formData.HospitalName,
                    mobileNumber: formattedNumber,
                    Landline: formData.Landline,
                    email: formData.Email,
                    addressLine1: formData.AddressLine1,
                    addressLine2: formData.AddressLine2,
                    districtId: formData.DistrictId,
                    stateId: formData.StateId,
                    pincode: formData.Pincode,
                    city: formData.City,
                    website: formData.Website,
                    mandal: formData.Mandal,
                    longitude: formData.Longitude,
                    latitude: formData.Latitude,
                    mapView: formData.MapView,
                    routeMapId: formData.RouteMapId,
                    routenames: formData.RouteName,
                    spoc1Name: formData.Spoc1Name,
                    spoc1Designation: formData.Spoc1Designation,
                    spoc1ContactNumber: formData.Spoc1ContactNumber,
                    spoc1AlternateContactNumber: formData.Spoc1AlternateContactNumber,
                    spoc2Name: formData.Spoc2Name,
                    spoc2ContactNumber: formData.Spoc2ContactNumber,
                    spoc2AlternateContactNumber: formData.Spoc2AlternateContactNumber,
                    spoc2Designation: formData.Spoc2Designation,
                    isAgreementReceived: formData.IsAgreementReceived,
                    aarogyasri: formData.Aarogyasri,
                    patientCounsellingFee: formData.PatientCounsellingFee,
                    specialization: formData.Specialization,
                    callToFrontDesk: formData.CallToFrontDesk,
                    menuCardForDiagnostics: formData.MenuCardForDiagnostics,
                    discountOnDiagnostics: formData.DiscountOnDiagnostics,
                    isFreeOPConsultation: formData.IsFreeOPConsultation,
                    mOUFileName: formData.MOUFileName
                });
                setHospitalsData(HospitalData);
                setSnackbarMessage("Hospital updated Succesfully");
            }

            const HospitalId = HospitalData.hospitalId;

            if (!HospitalId) {
                throw new Error('HospitalId is undefined');
            }

            await handleFileUpload(HospitalId);

            if (!profileFromLocation) {
                HospitalsId = HospitalData.hospitalId;
            } else {
                HospitalsId = HospitalData.hospitalId;
            }
            if (formData.RouteMapId) {
                HospitalData.routemapid = formData.RouteMapId
            }

            setLoading(false);
            setIsSubmitted(true);

            setTimeout(() => {
                setIsSubmitted(false);
                navigate(`/hospitals/details/${HospitalsId}`);
            }, 3000);
            setSnackbarOpen(true);
        }
    };

    const handleFileSelection = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                setFormError(prevError => ({
                    ...prevError,
                    MOUFileName: 'Invalid file type. Please upload a PDF or DOC file.'
                }));
                setSelectedImage(null);
            } else if (file.size > maxFileSize) {
                setFormError(prevError => ({
                    ...prevError,
                    MOUFileName: 'File size exceeds 5MB. Please upload a smaller file.'
                }));
                setSelectedImage(null);
            } else {
                setFormError(prevError => ({
                    ...prevError,
                    MOUFileName: ''
                }));
                setSelectedImage(file);
            }
        } else {
            setFormError(prevError => ({
                ...prevError,
                MOUFileName: 'Please upload a file.'
            }));
            setSelectedImage(null);
        }
    };

    const handleFileUpload = async (HospitalId) => {
        try {
            const formData = new FormData();

            formData.append('HospitalId', HospitalId);
            formData.append('MOUFileName', selectedImage);

            const response = await uploadImage('Hospital/upload', formData);

            return response;
        } catch (error) {
            console.error('Error uploading files:', error);

        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const getS3Url = (filename) => {
        const nameWithoutExtension = filename.split('.').shift();
        return `https://ohoindiauserimages.s3.ap-south-1.amazonaws.com/${nameWithoutExtension}.pdf`;
    };

    const renderFilePreview = () => {
        if (selectedImage instanceof File) {
            const fileName = selectedImage.name;
            return (
                <div>
                    <a href={URL.createObjectURL(selectedImage)} target="_blank" rel="noopener noreferrer">
                        {fileName}
                    </a>
                </div>
            );
        } else if (typeof selectedImage === 'string') {
            const fileUrl = getS3Url(selectedImage);
            const fileName = selectedImage.split('/').pop();
            return (
                <div>
                    <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                        {fileName}
                    </a>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <div className="App">
                <form onSubmit={onSubmitHandler}>
                    {/*className="mx-2" onSubmit={onSubmitHandler} style={{ backgroundColor: "#fff", padding: "20px", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}*/}
                    <div className="accordion-body">
                        <div className="row g-3">

                            <div className="col-md-12 mt-3">
                                <div className="card mt-3">
                                    <div className="card-header mx-2">
                                        <h5 className="card-title">
                                            <b>HOSPITAL INFORMATION</b>
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6" style={{ textAlign: 'left', display: 'block' }}>
                                                <div className="form-group">
                                                    <label htmlFor="HospitalName" className="form-label">HOSPITAL NAME</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="bx bx-user"></i></span>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="HospitalName"
                                                            placeholder="HOSPITAL NAME"
                                                            name="HospitalName"
                                                            maxLength={200}
                                                            value={formData.HospitalName}
                                                            onChange={onChangeHandler}
                                                            style={{ borderRadius: "5px" }}
                                                        />
                                                    </div>
                                                    <span className="non-valid" style={{ color: 'red' }}>{formError.HospitalName}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="HospitalMobileNumber" className="form-label">HOSPITAL CONTACT</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="bx bxs-phone"></i></span>
                                                        <input
                                                            className="form-control"
                                                            name="HospitalMobileNumber"
                                                            placeholder="xxxx xxx xxxx"
                                                            onChange={onChangeHandler}
                                                            value={formData.HospitalMobileNumber}
                                                            maxLength={10}
                                                            style={{ borderRadius: "5px" }}
                                                        />
                                                    </div>
                                                    <span className="non-valid" style={{ color: 'red' }}>{formError.HospitalMobileNumber}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="HospitalLandLine" className="form-label">HOSPITAL LANDLINE</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="bx bxs-phone"></i></span>
                                                        <input
                                                            className="form-control"
                                                            name="Landline"
                                                            placeholder="xxxxxxxxxx"
                                                            onChange={onChangeHandler}
                                                            value={formData.Landline}
                                                            maxLength={13}
                                                            style={{ borderRadius: "5px" }}
                                                        />
                                                    </div>
                                                    {formError.Landline && <span className="non-valid" style={{ color: 'red' }}>{formError.Landline}</span>}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="Email" className="form-label">EMAIL</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="Email"
                                                            placeholder="Example@gmail.com"
                                                            maxLength={70}
                                                            onChange={onChangeHandler}
                                                            value={formData.Email}
                                                            style={{ borderRadius: "5px" }}
                                                        />
                                                    </div>
                                                    <span className="non-valid" style={{ color: 'red' }}>{formError.Email}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-2">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="Website" className="form-label">HOSPITAL WEBSITE</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="bx bx-plus-medical"></i></span>
                                                        <input
                                                            type="text"
                                                            name="Website"
                                                            className="form-control"
                                                            placeholder="Website"
                                                            value={formData.Website}
                                                            onChange={onChangeHandler}
                                                            style={{ borderRadius: "5px" }}
                                                        />
                                                    </div>
                                                    <span className="non-valid" style={{ color: 'red' }}>{formError.Website}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="Specialization" className="form-label">SPECIALIZATION</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Specialization"
                                                        name="Specialization"
                                                        value={formData.Specialization}
                                                        onChange={onChangeHandler}
                                                        className="form-control"
                                                    />
                                                    {formError.Specialization && <span className="non-valid" style={{ color: 'red' }}>{formError.Specialization}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-3">
                                <div className="card mt-3">
                                    <div className="card-header mx-2">
                                        <h5 className="card-title"><b>ADDRESS</b> <span className="required" style={{ color: "red" }}> *</span></h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4 mb-2">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="AddressLine1" className="form-label">Address-1</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="AddressLine1"
                                                        placeholder="Enter Address"
                                                        name="AddressLine1"
                                                        maxLength={150}
                                                        value={formData.AddressLine1}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                    <span className="non-valid" style={{ color: 'red' }}>{formError.AddressLine1}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="AddressLine2" className="form-label">Address-2</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="AddressLine2"
                                                        placeholder="Enter Address"
                                                        name="AddressLine2"
                                                        maxLength={150}
                                                        value={formData.AddressLine2}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="StateId" className="form-label">State</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <select
                                                        className="form-select"
                                                        name="StateId"
                                                        value={formData.StateId}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    >
                                                        <option value="">Select State</option>
                                                        {states.map(state => (
                                                            <option key={state.StateId} value={state.StateId}>{state.StateName}</option>
                                                        ))}
                                                    </select>
                                                    {formError.StateId && <small className="non-valid" style={{ color: 'red' }}>{formError.StateId}</small>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="DistrictId" className="form-label">District</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <select
                                                        className="form-select"
                                                        name="DistrictId"
                                                        value={formData.DistrictId}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    >
                                                        <option value="">Select District</option>
                                                        {(districts || []).map(district => (
                                                            <option key={district.DistrictId} value={district.DistrictId}>{district.DistrictName}</option>
                                                        ))}
                                                    </select>
                                                    {formError.DistrictId && <small className="non-valid" style={{ color: 'red' }}>{formError.DistrictId}</small>}
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="City" className="form-label">City/Town</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="City"
                                                        placeholder="Enter City Name"
                                                        name="City"
                                                        maxLength={75}
                                                        value={formData.City}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                    <span className="non-valid" style={{ color: 'red' }}>{formError.City}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="Mandal" className="form-label">Mandal</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="Mandal"
                                                        placeholder="Enter Mandal Name"
                                                        name="Mandal"
                                                        maxLength={75}
                                                        value={formData.Mandal}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="Pincode" className="form-label">Pincode</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="Pincode"
                                                        pattern="[0-9]{6}"
                                                        maxLength="6"
                                                        id="Pincode"
                                                        placeholder="eg: 123456"
                                                        onChange={onChangeHandler}
                                                        value={formData.Pincode}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                    <span className="non-valid" style={{ color: 'red' }}>{formError.Pincode}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-3">
                                <div className="card mt-3">
                                    <div className="card-header mx-2">
                                        <h5 className="card-title"><b>LOCATION COORDINATES</b></h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4 mb-2" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="latitude" className="form-label">Latitude</label>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bx-map"></i></span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="latitude"
                                                        placeholder="Latitude"
                                                        name="Latitude"
                                                        maxLength={50}
                                                        value={formData.Latitude}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </div>
                                                <span className='non-valid' style={{ color: 'red' }}>{formError.Latitude}</span>
                                            </div>

                                            <div className="col-md-4 mb-2" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="longitude" className="form-label">Longitude</label>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bx-map-pin"></i></span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="longitude"
                                                        placeholder="Longitude"
                                                        name="Longitude"
                                                        maxLength={50}
                                                        value={formData.Longitude}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </div>
                                                <span className='non-valid' style={{ color: 'red' }}>{formError.Longitude}</span>
                                            </div>

                                            <div className="col-md-4 mb-2" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="mapView" className="form-label">Map View</label>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bx-map-alt"></i></span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="mapView"
                                                        placeholder="MapView"
                                                        name="MapView"
                                                        maxLength={150}
                                                        value={formData.MapView}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </div>
                                                <span className='non-valid' style={{ color: 'red' }}>{formError.MapView}</span>
                                            </div>


                                            {/*<div className="col-md-4 mb-2">                                                   */}
                                            {/*    <label htmlFor="routename" className="form-label">Route Name</label>*/}
                                            {/*    <span className="required" style={{ color: "red" }}> *</span>*/}
                                            {/*    <div className="input-group">*/}
                                            {/*        <span className="input-group-text"><i className="bx bx-map-alt"></i></span>*/}


                                            {/*    <select*/}
                                            {/*        id="select2Success"*/}
                                            {/*        name="RouteMapId"*/}
                                            {/*        className="form-select"*/}
                                            {/*        value={formData.RouteMapId}*/}
                                            {/*        onChange={onChangeHandler}*/}
                                            {/*    >*/}
                                            {/*<option value="">--Select RouteName--</option>*/}
                                            {/*{routename && routename.map((option, index) => (*/}
                                            {/*    <option key={'routename' + index} value={option.RouteMapId}>*/}
                                            {/*        {option.RouteName}*/}
                                            {/*    </option>*/}
                                            {/*        ))}*/}
                                            {/*        </select>*/}
                                            {/*    </div>*/}
                                            {/*    <span className='non-valid' style={{ color: 'red' }}>{formError.RouteName }</span>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-md-4 mb-2">*/}
                                            {/*    <div className="form-group">*/}
                                            {/*        <label htmlFor="routename" className="form-label">Route Name</label>*/}
                                            {/*        <span className="required" style={{ color: "red" }}> *</span>*/}
                                            {/*        <select*/}
                                            {/*            className="form-select"*/}
                                            {/*            name="RouteMapId"*/}
                                            {/*            value={formData.RouteMapId}*/}
                                            {/*            onChange={onChangeHandler}*/}
                                            {/*            style={{ borderRadius: "5px" }}*/}
                                            {/*        >*/}
                                            {/*            <option value="">--Select RouteName--</option>*/}
                                            {/*            {routename && routename.map((option, index) => (*/}
                                            {/*                <option key={'routename' + index} value={option.RouteMapId}>*/}
                                            {/*                    {option.RouteName}*/}
                                            {/*                </option>*/}
                                            {/*            ))}*/}
                                            {/*        </select>*/}
                                            {/*        {formError.RouteName && <span className="non-valid" style={{ color: 'red' }}>{formError.RouteName}</span>}*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}

                                            <div className="col-md-4 mb-2">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="RouteName" className="form-label">Route Name</label>
                                                    <select
                                                        className="form-select"
                                                        name="RouteMapId"
                                                        value={formData.RouteMapId}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    >
                                                        <option value="">--Select RouteName--</option>
                                                        {routename && routename.map((option, index) => (
                                                            <option key={'RouteName' + index} value={option.RouteMapId}>
                                                                {option.RouteName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {formError.RouteMapId && <span className="non-valid" style={{ color: 'red' }}>{formError.RouteMapId}</span>}
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-md-12 mt-3">
                                <div className="card mt-3">
                                    <div className="card-header mx-2">
                                        <h5 className="card-title">
                                            <b>SPOCs INFORMATION</b>

                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="Spoc1Name" className="form-label">SPOC 1 Name</label>
                                                <span className="required" style={{ color: "red" }}> *</span>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bx-user"></i></span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="Spoc1Name"
                                                        placeholder="Enter SPOC 1 Name"
                                                        name="Spoc1Name"
                                                        maxLength={150}
                                                        value={formData.Spoc1Name}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </div>
                                                <span className='non-valid' style={{ color: 'red' }}>{formError.Spoc1Name}</span>
                                            </div>
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="Spoc1Designation" className="form-label">SPOC 1 Designation</label>
                                                <span className="required" style={{ color: "red" }}> *</span>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bx-briefcase"></i></span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="Spoc1Designation"
                                                        placeholder="Enter SPOC 1 Designation"
                                                        name="Spoc1Designation"
                                                        maxLength={150}
                                                        value={formData.Spoc1Designation}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </div>
                                                <span className='non-valid' style={{ color: 'red' }}>{formError.Spoc1Designation}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="Spoc1ContactNumber" className="form-label">SPOC 1 Contact Number</label>
                                                <span className="required" style={{ color: "red" }}> *</span>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bxs-phone"></i></span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="Spoc1ContactNumber"
                                                        placeholder="Enter SPOC 1 Contact Number"
                                                        name="Spoc1ContactNumber"
                                                        maxLength={10}
                                                        value={formData.Spoc1ContactNumber}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </div>
                                                <span className='non-valid' style={{ color: 'red' }}>{formError.Spoc1ContactNumber}</span>
                                            </div>
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="Spoc1AlternateContactNumber" className="form-label">SPOC 1 Alternate Contact Number</label>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bxs-phone"></i></span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="Spoc1AlternateContactNumber"
                                                        placeholder="Enter SPOC 1 Alternate Contact Number"
                                                        name="Spoc1AlternateContactNumber"
                                                        maxLength={10}
                                                        value={formData.Spoc1AlternateContactNumber}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </div>
                                                <span className='non-valid' style={{ color: 'red' }}>{formError.Spoc1AlternateContactNumber}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="Spoc2Name" className="form-label">SPOC 2 Name</label>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bx-user"></i></span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="Spoc2Name"
                                                        placeholder="Enter SPOC 2 Name"
                                                        name="Spoc2Name"
                                                        maxLength={150}
                                                        value={formData.Spoc2Name}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </div>
                                                <span className='non-valid' style={{ color: 'red' }}>{formError.Spoc2Name}</span>
                                            </div>
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="Spoc2Designation" className="form-label">SPOC 2 Designation</label>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bx-briefcase"></i></span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="Spoc2Designation"
                                                        placeholder="Enter SPOC 2 Designation"
                                                        name="Spoc2Designation"
                                                        maxLength={150}
                                                        value={formData.Spoc2Designation}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </div>
                                                <span className='non-valid' style={{ color: 'red' }}>{formError.Spoc2Designation}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="Spoc2ContactNumber" className="form-label">SPOC 2 Contact Number</label>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bxs-phone"></i></span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="Spoc2ContactNumber"
                                                        placeholder="Enter SPOC 2 Contact Number"
                                                        name="Spoc2ContactNumber"
                                                        maxLength={10}
                                                        value={formData.Spoc2ContactNumber}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </div>
                                                <span className='non-valid' style={{ color: 'red' }}>{formError.Spoc2ContactNumber}</span>
                                            </div>
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="Spoc2AlternateContactNumber" className="form-label">SPOC 2 Alternate Contact Number</label>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bxs-phone"></i></span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="Spoc2AlternateContactNumber"
                                                        placeholder="Enter SPOC 2 Alternate Contact Number"
                                                        name="Spoc2AlternateContactNumber"
                                                        maxLength={10}
                                                        value={formData.Spoc2AlternateContactNumber}
                                                        onChange={onChangeHandler}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </div>
                                                <span className='non-valid' style={{ color: 'red' }}>{formError.Spoc2AlternateContactNumber}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-12 mt-3">
                                <div className="card mt-3">
                                    <div className="card-header mx-2" >
                                        <h5 className="card-title"><b>ADDITIONAL INFORMATION</b></h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="Aarogyasri" className="form-label">Aarogyasri</label>
                                                <input
                                                    type="text"
                                                    id="Aarogyasri"
                                                    name="Aarogyasri"
                                                    value={formData.Aarogyasri}
                                                    onChange={onChangeHandler}
                                                    className="form-control"
                                                    placeholder="Enter Aarogyasri information"
                                                    style={{ borderRadius: "5px" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="CallToFrontDesk" className="form-label">Call to Front Desk</label>
                                                <input
                                                    type="text"
                                                    id="CallToFrontDesk"
                                                    name="CallToFrontDesk"
                                                    value={formData.CallToFrontDesk}
                                                    onChange={onChangeHandler}
                                                    className="form-control"
                                                    style={{ borderRadius: "5px" }}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="PatientCounsellingFee" className="form-label">Resident Counselling Fee</label>
                                                <input
                                                    type="text"
                                                    id="PatientCounsellingFee"
                                                    name="PatientCounsellingFee"
                                                    value={formData.PatientCounsellingFee}
                                                    onChange={onChangeHandler}
                                                    className="form-control"
                                                    style={{ borderRadius: "5px" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="MenuCardForDiagnostics" className="form-label">Menu Card for Diagnostics</label>
                                                <input
                                                    type="text"
                                                    id="MenuCardForDiagnostics"
                                                    name="MenuCardForDiagnostics"
                                                    value={formData.MenuCardForDiagnostics}
                                                    onChange={onChangeHandler}
                                                    className="form-control"
                                                    style={{ borderRadius: "5px" }}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="DiscountOnDiagnostics" className="form-label">Discount On Diagnostics</label>
                                                <input
                                                    type="text"
                                                    id="DiscountOnDiagnostics"
                                                    name="DiscountOnDiagnostics"
                                                    value={formData.DiscountOnDiagnostics}
                                                    onChange={onChangeHandler}
                                                    className="form-control"
                                                    style={{ borderRadius: "5px" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label className="form-label">Is Free OP Consultation</label>
                                                <input
                                                    type="text"
                                                    id="IsFreeOPConsultation"
                                                    name="IsFreeOPConsultation"
                                                    value={formData.IsFreeOPConsultation}
                                                    onChange={onChangeHandler}
                                                    className="form-control"
                                                    style={{ borderRadius: "5px" }}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3" style={{ textAlign: 'left', display: 'block' }}>
                                                <label className="form-label">Is Agreement Received</label><span className="required" style={{ color: "red" }}> *</span>
                                                <div className="form-check">
                                                    <input
                                                        type="radio"
                                                        id="IsAgreementReceivedYes"
                                                        name="IsAgreementReceived"
                                                        value="Yes"
                                                        checked={formData.IsAgreementReceived === true}
                                                        onChange={onChangeHandler}
                                                        className="form-check-input"
                                                    />
                                                    <label htmlFor="IsAgreementReceivedYes" className="form-check-label">Yes</label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type="radio"
                                                        id="IsAgreementReceivedNo"
                                                        name="IsAgreementReceived"
                                                        value="No"
                                                        checked={formData.IsAgreementReceived === false}
                                                        onChange={onChangeHandler}
                                                        className="form-check-input"
                                                    />
                                                    <label htmlFor="IsAgreementReceivedNo" className="form-check-label">No</label>
                                                </div>
                                                {formError.IsAgreementReceived && (
                                                    <div className="invalid-feedback d-block">
                                                        {formError.IsAgreementReceived}
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-3">
                                <div className="card mt-3">
                                    <div className="card-header mx-2">
                                        <h5 className="card-title">
                                            <b>Upload MOU File</b>
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6" style={{ textAlign: 'left', display: 'block' }}>
                                                <div className="form-group">
                                                    <label>MOU File Name</label>
                                                    {renderFilePreview()}
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        name="MOUFileName"
                                                        accept=".pdf, .doc, .docx"
                                                        onChange={(e) => handleFileSelection(e)}
                                                        style={{ marginTop: '10px' }}
                                                    />


                                                    {formError.MOUFileName && <div className="text-danger">{formError.MOUFileName}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{ marginTop: "20px", textAlign: "center", marginLeft: "200px" }}>
                                <div className="col-md-8 col-md-offset-2">
                                    <button
                                        className="btn btn-md btn-primary"
                                        type="reset"
                                        onClick={handleBackToView}
                                        style={{
                                            marginRight: "10px",
                                            //backgroundColor: "#d9534f",
                                            //borderColor: "#d9534f",
                                            //color: "#fff",
                                            borderRadius: "5px",
                                            padding: "8px 20px",
                                            fontWeight: "bold",
                                            cursor: "pointer"
                                        }}> Cancel  </button>



                                    <button
                                        className="btn btn-md btn-danger"
                                        type="reset"
                                        style={{
                                            marginRight: "10px",
                                            /*backgroundColor: "#5bc0de",*/
                                            /* borderColor: "#5bc0de",*/
                                            /* color: "#fff",*/
                                            borderRadius: "5px",
                                            padding: "8px 20px",
                                            fontWeight: "bold",
                                            cursor: "pointer"
                                        }}
                                        onClick={resetForm}
                                    >
                                        Reset
                                    </button>
                                    <button
                                        className="btn btn-md btn-primary"
                                        type="submit"
                                        style={{
                                            backgroundColor: "#5cb85c",
                                            borderColor: "#5cb85c",
                                            color: "#fff",
                                            borderRadius: "5px",
                                            padding: "8px 20px",
                                            fontWeight: "bold",
                                            cursor: "pointer"
                                        }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>

                            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                <Alert onClose={handleSnackbarClose} severity="success">
                                    {snackbarMessage}
                                </Alert>
                            </Snackbar>

                        </div>
                    </div>
                </form>
            </div>



        </>
    );
}