import React, { useState, useEffect } from 'react';
import { fetchAllData, uploadImage } from "../helpers/externapi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import ChangePassword from './ChangePassword';
import Layout from "../Layout/Layout";

const UserProfile = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);


    const UserId = sessionStorage.getItem("UserId");


    const fetchUserDetails = async () => {
        try {
            const response = await fetchAllData(`Users/GetById/${UserId}`);
            setUser(response[0]);
            setLoading(false);
        } catch (error) {
            console.error('Error while fetching data:', error);
            setError('Failed to fetch user details');
            setLoading(false);
        }
    };





    useEffect(() => {
        fetchUserDetails();
    }, []);

    const handleFileSelection = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            await handleFileUpload(file);
        }
    };

    const handleFileUpload = async (file) => {


        const UserId = sessionStorage.getItem("UserId");

        try {
            const formData = new FormData();
            formData.append('UserId', UserId);
            formData.append('UserImage', file);

            const response = await uploadImage('Users/upload', formData);

            console.log('response', response);

            await fetchUserDetails();


        } catch (error) {
            console.error('Error uploading files:', error);

        }

    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
      <Layout>
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-7">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-start align-items-sm-center gap-4">
                                <img
                                    src={user.UserImage ? `https://ohoindiaimages.s3.ap-south-1.amazonaws.com/${user.UserImage.split('.').shift()}.jpg` : "../../assets/img/dummy-avatar.jpg"}
                                    alt="User Avatar"
                                    className="d-block rounded"
                                    height="100"
                                    width="100"
                                    id="uploadedAvatar"
                                />
                                <div className="button-wrapper">
                                    <label htmlFor="upload" className="btn btn-primary me-2 mb-4">
                                        <span>Upload Image</span>
                                        <FontAwesomeIcon icon={faUpload} className="ms-2" />
                                        <input type="file" id="upload" className="account-file-input" hidden accept="image/png, image/jpeg" onChange={handleFileSelection} />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={styles.cardBody}>
                            <div style={styles.userDetail}>
                                <label style={styles.label}>UserName</label>
                                <p style={styles.value}>{user.UserName}</p>
                            </div>
                            <div style={styles.userDetail}>
                                <label style={styles.label}>EmployeeId</label>
                                <p style={styles.value}>{user.EmployeeId}</p>
                            </div>
                            <div style={styles.userDetail}>
                                <label style={styles.label}>E-mail</label>
                                <p style={styles.value}>{user.EmailId}</p>
                            </div>
                            <div style={styles.userDetail}>
                                <label style={styles.label}>Phone Number</label>
                                <p style={styles.value}>{user.MobileNumber}</p>
                            </div>
                            <div style={styles.userDetail}>
                                <label style={styles.label}>Role</label>
                                <p style={styles.value}>{user.UserRoleName}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="card-body">
                        <ChangePassword />
                    </div>
                </div>
            </div>
            </div>
        </Layout>
    );
};

const styles = {
    cardBody: {
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
    },
    userDetail: {
        marginBottom: '15px',
        padding: '10px 15px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    label: {
        fontWeight: 'bold',
        color: '#333',
    },
    value: {
        fontSize: '16px',
        color: '#555',
    },
};

export default UserProfile;
