import React, { useState } from 'react';
import Map, { Marker, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const MapBoxHospital = (props) => {
    const [selectedLocation, setSelectedLocation] = useState(null);
    const {hospitalsData} = props

    const handleLocation = (event, location) => {
        event.preventDefault();
        setSelectedLocation(location);
    };

    return (
        <Map
            initialViewState={{
                longitude: 78.477740,
                latitude: 17.434170,
                zoom: 10
            }}
            style={{ width: '70%', height: '400px' }}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            mapboxAccessToken={process.env.REACT_APP_MAP_BOX}
        >
            {hospitalsData.map((location, index) => (
                <Marker
                    key={index}
                    longitude={location.Longitude}
                    latitude={location.Latitude}
                    color="red"                    
                >
                    <button
                        style={{ border: '0px', backgroundColor: 'transparent', cursor: 'pointer' }}
                        onClick={(e) => handleLocation(e, location) }
                    >
                        <i className="fa-solid fa-location-dot fa-2x text-danger"></i>
                    </button>
                </Marker>
            ))}

            {selectedLocation && (
                <Popup
                    latitude={selectedLocation.Latitude}
                    longitude={selectedLocation.Longitude}
                    onClose={() => setSelectedLocation(null)}
                    closeOnClick={false}
                >
                    <p>{selectedLocation.HospitalName}</p>
                </Popup>
            )}
        </Map>
    );
};

export default MapBoxHospital;

