import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom";
import { Alert, Snackbar } from '@mui/material';
import { fetchData, fetchAllData} from "../helpers/externapi";
import Flatpickr from 'react-flatpickr';

const SelectNominee = ({ selectedProduct }) => {
    const [members, setMembers] = useState({
        adult: null,
        children: [],
        nominee: null,
    });
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const total = selectedProduct.MaximumAdult + selectedProduct.MaximumChild;
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [openFormIndex, setOpenFormIndex] = useState(null);
    const [isAdultSubmitted, setIsAdultSubmitted] = useState(false);
    const [selectedNominee, setSelectedNominee] = useState(null); // To keep track of the selected nominee globally
    const [maxAdults] = useState(selectedProduct.MaximumAdult);
    const childrens = selectedProduct.MaximumChild;
    //const canAddAdult = !members.adult && maxAdults > 0 && !isAdultSubmitted;
    const [childrenDataLength, setIsChildrenDataLength] = useState(null);
   // const [canAddAdult, setCanAddAdult] = useState(true); 
    const [childrenCount, setChildrenCount] = useState(0);
    const [adultCount, setAdultCount ] = useState(0);
    const [hasOtherRelationship, setHasOtherRelationship] = useState(false);
    const [showNomineeSelection, setShowNomineeSelection] = useState(true);
  

    //console.log("selectedProduct", selectedProduct);


  

    const id = useParams();

    console.log("id", id.Id);


    const hasNominee = () => {
        if (members.adult && members.adult.isNominee) return true;
        if (members.nominee) return true;
        return members.children.some(child => child.isNominee);
    };


    const addNomineeForm = () => {
        if (!hasNominee()) {
            setMembers({
                ...members,
                nominee: {
                    name: "",
                    dob: "",
                    age: "",
                    mobile: "",
                    gender: "",
                    relationship: "",
                    profileImage: "",
                    useAge: false,
                    isNominee: true,  // Set as nominee
                },
            });
        }
    };

    const addAdultMemberForm = () => {
        if (members.adult === null && maxAdults > 0) {
            setMembers({
                ...members,
                adult: {
                    name: "",
                    dob: "",
                    age: "",
                    mobile: "",
                    gender: "",
                    relationship: "",
                    profileImage: "",
                    useAge: false,
                    isNominee: false,
                },
            });
        }
    };

    const removeChildMemberForm = (index) => {
        const updatedChildren = [...members.children];
        if (updatedChildren[index].isNominee) {
            setSelectedNominee(null); // Reset nominee if the removed child was the nominee
        }
        updatedChildren.splice(index, 1);
        setMembers({ ...members, children: updatedChildren });

        // Reset openFormIndex if the removed index was equal to openFormIndex
        if (index === openFormIndex) {
            setOpenFormIndex(null); // Resetting the openFormIndex
        } else if (index < openFormIndex) {
            // If the removed index is before the openFormIndex, decrease it by 1
            setOpenFormIndex((prevIndex) => (prevIndex !== null ? prevIndex - 1 : null));
        }
    };

    const addChildMemberForm = () => {
        // Allow adding child forms if the adult form is not open or has been submitted
        if ((members.children.length < childrens) && (openFormIndex == null)) {
            setMembers((prevMembers) => ({
                ...prevMembers,
                children: [
                    ...prevMembers.children,
                    {
                        name: '',
                        dob: '',
                        age: '',
                        mobile: '',
                        gender: '',
                        relationship: '',
                        profileImage: '',
                        useAge: false,
                        guardianName: '',
                        guardianDob: '',
                        guardianAge: '',
                        guardianGender: '',
                        guardianMobile: '',
                        guardianUseAge: false,
                        isNominee: false,
                    },
                ],
            }));
            setOpenFormIndex(members.children.length); // Set the new index for the added child
        } else {
            setSnackbarMessage('Action Not Allowed. Please submit the current Children details before adding another Children.');
            setSnackbarOpen(true);
        }
    };



    const fetchDependentData = async () => {
        try {
            setLoading(true);

            const dependentData = await fetchAllData(`MemberDependent/GetDependentsByMemberProductId/${id.Id}/${selectedProduct.ProductsId}`);
            //console.log('dependent', dependentData);

            if (dependentData && dependentData.length > 0) {
                

                const childrenData = dependentData.filter(dep => dep.Relationship === 'Son' || dep.Relationship === 'Daughter');
                //console.log("childrenData", childrenData);
                //console.log("childrenData.length", childrenData.length);

                // Set the number of children
                setChildrenCount(childrenData.length);

                // Check for any other relationship
                const otherRelationships = dependentData.filter(dep =>
                    dep.Relationship !== 'Son' && dep.Relationship !== 'Daughter' 
                );
                
                //console.log("otherRelationship", otherRelationships.length);
                setAdultCount(otherRelationships.length);
               

                const isNominee = dependentData.some(dep => dep.IsNominee === true);
                setShowNomineeSelection(!isNominee);
               
                
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchDependentData();
    }, []);


    const handleNomineeSelection = (type, index = null) => {
     
        if (selectedNominee) {
            
            setSnackbarMessage("Nominee already selected. Only one nominee allowed.");
            setSnackbarOpen(true);

           
            if (selectedNominee.type !== type || (type === 'child' && selectedNominee.index !== index)) {
                if (selectedNominee.type === 'adult') {
                    
                    setMembers((prevMembers) => ({
                        ...prevMembers,
                        adult: {
                            ...prevMembers.adult,
                            isNominee: false,
                        },
                    }));
                } else if (selectedNominee.type === 'child') {
                  
                    setMembers((prevMembers) => {
                        const updatedChildren = [...prevMembers.children];
                        updatedChildren[selectedNominee.index].isNominee = false;
                        return { ...prevMembers, children: updatedChildren };
                    });
                }
            } else {
               
                return;
            }
        }

       
        if (type === 'adult') {
            setMembers((prevMembers) => ({
                ...prevMembers,
                adult: {
                    ...prevMembers.adult,
                    isNominee: true,
                },
            }));
        } else if (type === 'child') {
            setMembers((prevMembers) => {
                const updatedChildren = [...prevMembers.children];
                updatedChildren[index].isNominee = true;
                return { ...prevMembers, children: updatedChildren };
            });
        }

       
        setSelectedNominee({ type, index });
    };


    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const ageDiff = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageDiff);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    const calculateDOBFromAge = (age) => {
        const today = new Date();
        const birthDate = new Date(today.setFullYear(today.getFullYear() - age));
        return birthDate.toISOString().split("T")[0];
    };

    const validateMember = (member) => {
        const newErrors = {};

       
        if (!member.name) {
            newErrors.name = "Name is required";
        }

       
        if (!member.dob) {
            newErrors.dob = "Date of Birth is required";
        } else if (new Date(member.dob) > new Date()) {
            newErrors.dob = "Date of Birth cannot be in the future";
        }

        
        if (member.relationship === "Son" || member.relationship === "Daughter") {
            // Specific age range for Son/Daughter
            if (member.age < 0 || member.age > 25) {
                newErrors.age = "Age for Son or Daughter must be between 0 and 25";
            }
        } else if (member.age < selectedProduct.MinimumAge || member.age > selectedProduct.MaximumAge) {
            newErrors.age = `Age must be between ${selectedProduct.MinimumAge} and ${selectedProduct.MaximumAge}`;
        } else if (member.age <= 0) {
            newErrors.age = "Age must be a positive number";
        } else {
            delete newErrors.age; // Clear the error if age is valid
        }
       
        const mobileRegex = /^[6-9][0-9]{9}$/;

        
        if (member.mobile && !mobileRegex.test(member.mobile)) {
            newErrors.mobile = "Please enter a valid 10-digit mobile number starting with 6, 7, 8, or 9.";
        }



       
        if (!member.relationship) {
            newErrors.relationship = "Relationship is required";
        }

        
        if (!member.gender) {
            newErrors.gender = "Gender is required";
        }

        
        if (member.isNominee && member.age < 16) {
            if (!member.guardianName) {
                newErrors.guardianName = "Guardian Name is required";
            }
            if (!member.guardianDob) {
                newErrors.guardianDob = "Guardian Date of Birth is required";
            }
            if (member.guardianAge < 0) {
                newErrors.guardianAge = "Guardian Age must be a positive number";
            }
            if (!member.guardianMobile) {
                newErrors.guardianMobile = "Guardian Mobile Number is required";
            }
            if (!member.guardianGender) {
                newErrors.guardianGender = "Guardian Gender is required";
            }
        }

        return newErrors;
    };

    const handleSubmit = async (type, index) => {
        let member;

        // Determine which member to submit
        if (type === "adult") {
            member = members.adult;
        } else if (type === "children") {
            if (!members.children || members.children.length <= index) {
                console.error("No child member found at the specified index:", index);
                return;
            }
            member = members.children[index];
        } else if (type === "nominee") {
            member = members.nominee;
        }

        if (!member) {
            console.error(`No ${type} member data found`);
            return;
        }

        // Validate member details 
        const validationErrors = validateMember(member);
        if (Object.keys(validationErrors).length === 0) {
            setIsSubmitting(true);

            try {
                // Save details to API
                await saveDetailsToAPI(member, type);
                // Optionally reset nominee form here or show success feedback
            } catch (error) {
                console.error("Error saving details to API:", error);
                setSnackbarMessage("Error saving details. Please try again.");
                setSnackbarOpen(true);
            } finally {
                setIsSubmitting(false);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    
    
    const saveDetailsToAPI = async (data, type) => {
        try {
            setLoading(true);

            if (!data.age || !data.name || !data.dob) {
                console.error('Missing required fields in data:', data);
                return;
            }

            console.log({
                productsId: selectedProduct.ProductsId,
                memberId: id.Id,
                age: data.age,
                fullName: data.name,
                dateofBirth: data.dob,
                gender: data.gender,
                relationship: data.relationship,
                mobileNumber: data.mobile,
                isNominee: data.isNominee,
                guardianName: data.guardianName,
                guardianDateofBirth: data.guardianDob,
                guardianAge: data.guardianAge,
                guardianGender: data.guardianGender,
                guardianMobileNumber: data.guardianMobile,
            });

            const response = await fetchData('MemberDependent/add', {
                productsId: selectedProduct.ProductsId,
                memberId: id.Id,
                age: data.age,
                fullName: data.name,
                dateofBirth: data.dob,
                gender: data.gender,
                relationship: data.relationship,
                mobileNumber: data.mobile,
                isNominee: data.isNominee,
                guardianName: data.guardianName,
                guardianDateofBirth: data.guardianDob,
                guardianAge: data.guardianAge,
                guardianGender: data.guardianGender,
                guardianMobileNumber: data.guardianMobile,
            });

            //console.log('API Response:', response);
            if (response) {
                setSnackbarMessage(` ${response.relationship} Details Saved Successfully`);
                setSnackbarOpen(true);
                // Reload the page after 3 seconds to show the snackbar message
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }

        } catch (error) {
            console.error('API Error:', error);
        } finally {
            setLoading(false);
            setIsSubmitting(false);
        }
    };



    const handleAdultChange = (field, value) => {
        const updatedAdult = { ...members.adult, [field]: value };

       
        if (field === "age" && value) {
            updatedAdult.dob = calculateDOBFromAge(value); 
        }

        
        if (field === "dob" && value) {
            updatedAdult.age = calculateAge(value); 
        }

        setMembers({ ...members, adult: updatedAdult });
    };


    const handleNomineeChange = (field, value) => {


        const updatedNominee = { ...members.nominee, [field]: value };


        if (field === "age" && value) {
            updatedNominee.dob = calculateDOBFromAge(value);
        }


        if (field === "dob" && value) {
            updatedNominee.age = calculateAge(value);
        }

        setMembers({ ...members, nominee: updatedNominee });
    };


    const removeAdultMemberForm = () => {
        setMembers({ ...members, adult: null });
        setIsAdultSubmitted(false);
        setSelectedNominee(null);
    };


    const removeNomineeForm = () => {
        setMembers((prevMembers) => ({
            ...prevMembers,
            nominee: null,
        }));
    };

    const isAnyNomineeSelected = () => {
       
        if (members.adult && members.adult.isNominee) {
            return true;
        }

        
        if (members.children) {
            return members.children.some(child => child.isNominee);
        }

        return false; 
    };



    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
           
           
            {selectedProduct.MaximumAdult > 1 && selectedProduct.MaximumChild > 0 && (
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title text-center">Select Members</h5>
                                    <div className="note text-center" style={{ marginBottom: '10px', fontStyle: 'italic' }}>
                                        Note: Maximum of {total} persons Only ({selectedProduct.MaximumAdult} Adults, {selectedProduct.MaximumChild} Children)
                                    </div>
                                    

                                   
                                    
                                        <div className="button-group mb-4 offset-md-4 ">
                                            <button
                                                className="btn btn-primary me-2"
                                                onClick={addAdultMemberForm}
                                            disabled={adultCount >= (selectedProduct.MaximumAdult - 1)} 
                                            >
                                                Add Adult
                                            </button>

                                            <button
                                                className="btn btn-primary me-2"
                                                onClick={addChildMemberForm}
                                            disabled={childrenCount >= selectedProduct.MaximumChild} 
                                            >
                                                Add Childrens
                                            </button>

                                        {selectedProduct.IsNomineeRequired && showNomineeSelection && !hasNominee() && (
                                            <button
                                                className="btn btn-primary"
                                                onClick={addNomineeForm}
                                            >
                                                Add Nominee
                                            </button>
                                        )}
                                        </div>
                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {members.adult && (
                <div className="adult-form card mb-4 p-4 mt-3 shadow-sm rounded">
                    <h6 className="text-secondary">Adult Member</h6>

                    <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.name ? "is-invalid" : ""}`}
                            value={members.adult.name}
                            onChange={(e) => handleAdultChange("name", e.target.value)}
                        />
                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                    </div>


                 <div className="row align-items-center">
                        <div className="col-md-9"> 
                    
                        <label className="form-label">Date of Birth:</label>
                        <Flatpickr
                            className={`form-control ${errors.dob ? "is-invalid" : ""}`}
                            value={members.adult.dob}
                            onChange={(date) => handleAdultChange("dob", date[0])}
                        />
                        {errors.dob && <div className="invalid-feedback">{errors.dob}</div>}
                    </div>

                    <div className="col-md-3">
                        <label className="form-label">Age:</label>
                        <input
                            className={`form-control ${errors.age ? "is-invalid" : ""}`}
                            type="number"
                            value={members.adult.age}
                            min="0"
                            onChange={(e) => handleAdultChange("age", e.target.value)}
                        />
                        {errors.age && <div className="invalid-feedback">{errors.age}</div>}
                    </div>
                  </div>

                    <div className="mb-3">
                        <label className="form-label">Mobile Number:</label>
                        <input
                            type="number"
                            className={`form-control ${errors.mobile ? "is-invalid" : ""}`}
                            value={members.adult.mobile}
                            onChange={(e) => handleAdultChange("mobile", e.target.value)}
                            maxLength={10} // Restrict input to 10 digits
                            pattern="[0-9]*" // Accept only digits
                        />
                        {errors.mobile && <div className="invalid-feedback">{errors.mobile}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Relationship:</label>
                        <select
                            className={`form-select ${errors.relationship ? "is-invalid" : ""}`}
                            value={members.adult.relationship}
                            onChange={(e) => handleAdultChange("relationship", e.target.value)}
                        >
                            <option value="">Select Relationship</option>
                            <option value="Spouse">Spouse</option>
                            <option value="Mother">Mother</option>
                            <option value="Father">Father</option>                            

                        </select>
                        {errors.relationship && <div className="invalid-feedback">{errors.relationship}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Gender:</label>
                        <div className="d-flex align-items-center">
                            <div className="form-check me-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id={`genderMale`}
                                    name={`gender`}
                                    value="Male"
                                    checked={members.adult.gender === "Male"}
                                    onChange={(e) => handleAdultChange("gender", e.target.value)}
                                />
                                <label className="form-check-label" htmlFor={`genderMale`}>Male</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id={`genderFemale`}
                                    name={`gender`}
                                    value="Female"
                                    checked={members.adult.gender === "Female"}
                                    onChange={(e) => handleAdultChange("gender", e.target.value)}
                                />
                                <label className="form-check-label" htmlFor={`genderFemale`}>Female</label>
                            </div>
                        </div>
                        {errors.gender && <div className="invalid-feedback d-block">{errors.gender}</div>}
                    </div>
                    {selectedProduct.IsNomineeRequired && showNomineeSelection &&  (
                        <div className="mb-3 nominee-checkbox" style={{ border: '2px solid blue', borderRadius: '0.5rem', padding: '1rem', marginRight:'5px' }}>
                            {members.adult.isNominee || isAnyNomineeSelected() ? (
                                <div className="text-muted mt-2">Already Selected as Nominee</div>
                            ) : (
                                <div className="form-check">
                                    <label className="form-label ml-2"> Select Nominee</label>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`isNominee`}
                                        checked={members.adult.isNominee}
                                        onChange={(e) => handleAdultChange("isNominee", e.target.checked)}
                                        style={{ width: '2em', height: '2em', accentColor: 'blue' }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <div className="button-group">
                        <button
                            className="btn btn-danger me-2"
                            onClick={removeAdultMemberForm}
                        >
                            Remove Adult
                        </button>
                        <button
                            className="btn btn-success  me-2"
                            onClick={() => handleSubmit("adult")}
                            disabled={isSubmitting || loading}
                        >
                            Submit Adult
                        </button>
                    </div>

                </div>
            )}


            {members.children && members.children.map((member, index) => (
                <div key={index} className="member-form card mb-4 p-4 mt-5 shadow-sm rounded">
                    <h6 className="text-secondary">Children {index + 1}</h6>

                    <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.name ? "is-invalid" : ""}`}
                            value={member.name}
                            onChange={(e) => {
                                const newFamily = [...members.children];
                                newFamily[index].name = e.target.value;
                                setMembers({ ...members, children: newFamily });
                            }}
                        />
                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                    </div>

                    <div className="row align-items-center">
                        <div className="col-md-9"> 
                           <label className="form-label col-md-5 mb-0">Date of Birth:</label>
                        
                            <Flatpickr
                                className={`form-control ${errors.dob ? "is-invalid" : ""}`}
                                value={member.dob ? new Date(member.dob) : null}
                                onChange={([date]) => {
                                    const newFamily = [...members.children];
                                    if (date) {
                                        newFamily[index].dob = date.toISOString();
                                        newFamily[index].age = calculateAge(date);
                                    } else {
                                        newFamily[index].dob = '';
                                        newFamily[index].age = '';
                                    }
                                    setMembers({ ...members, children: newFamily });
                                }}
                                options={{ dateFormat: "Y-m-d" }}
                            />
                            {errors.dob && <div className="invalid-feedback">{errors.dob}</div>}
                        </div>

                        <div className="col-md-3"> 
                        <label className="form-label col-md-2 mb-0">Age:</label>
                       
                            <input
                                type="number"
                                className={`form-control ${errors.age ? "is-invalid" : ""}`}
                                value={member.age}
                                min="0"
                                onChange={(e) => {
                                    const newFamily = [...members.children];
                                    newFamily[index].age = e.target.value;
                                    newFamily[index].dob = calculateDOBFromAge(e.target.value);
                                    setMembers({ ...members, children: newFamily });
                                }}
                            />
                            {errors.age && <div className="invalid-feedback">{errors.age}</div>}
                        </div>
                    </div>



                    <div className="mb-3">
                        <label className="form-label">Mobile Number:</label>
                        <input
                            type="number"
                            className={`form-control ${errors.mobile ? "is-invalid" : ""}`}
                            value={member.mobile}
                            onChange={(e) => {
                                const newFamily = [...members.children];
                                newFamily[index].mobile = e.target.value;
                                setMembers({ ...members, children: newFamily });
                            }}
                            maxLength={10} // Restrict input to 10 digits
                            pattern="[0-9]*" // Accept only digits
                        />
                        {errors.mobile && <div className="invalid-feedback">{errors.mobile}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Relationship:</label>
                        <select
                            className={`form-select ${errors.relationship ? "is-invalid" : ""}`}
                            value={member.relationship}
                            onChange={(e) => {
                                const newFamily = [...members.children];
                                newFamily[index].relationship = e.target.value;
                                setMembers({ ...members, children: newFamily });
                            }}
                        >
                            <option value="">Select Relationship</option>
                            <option value="Son">Son</option>
                            <option value="Daughter">Daughter</option>
                        </select>
                        {errors.relationship && <div className="invalid-feedback">{errors.relationship}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Gender:</label>
                        <div className="d-flex align-items-center">
                            <div className="form-check me-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id={`genderMale-${index}`}
                                    name={`gender-${index}`}
                                    value="Male"
                                    checked={member.gender === "Male"}
                                    onChange={() => {
                                        const newFamily = [...members.children];
                                        newFamily[index].gender = "Male";
                                        setMembers({ ...members, children: newFamily });
                                    }}
                                />
                                <label className="form-check-label" htmlFor={`genderMale-${index}`}>Male</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id={`genderFemale-${index}`}
                                    name={`gender-${index}`}
                                    value="Female"
                                    checked={member.gender === "Female"}
                                    onChange={() => {
                                        const newFamily = [...members.children];
                                        newFamily[index].gender = "Female";
                                        setMembers({ ...members, children: newFamily });
                                    }}
                                />
                                <label className="form-check-label" htmlFor={`genderFemale-${index}`}>Female</label>
                            </div>
                        </div>
                        {errors.gender && <div className="invalid-feedback d-block">{errors.gender}</div>}
                    </div>

                    {selectedProduct.IsNomineeRequired && showNomineeSelection &&  (
                        <div className="mb-3 nominee-checkbox" style={{ border: '2px solid blue', borderRadius: '0.5rem', padding: '1rem' }}>
                            {member.isNominee || isAnyNomineeSelected() ? (
                                <div className="text-muted mt-2">Already Selected as Nominee</div>
                            ) : (
                                <div className="form-check">
                                    <label className="form-label">Select Nominee</label>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`isNominee-${index}`}
                                        onChange={() => handleNomineeSelection('child', index)} 
                                        style={{ width: '2em', height: '2em', accentColor: 'blue' }}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    {member.isNominee && member.age < 16 && (
                        <div className="mb-3 border p-2">
                            <h6 className="text-secondary">Guardian Details</h6>
                            <div className="mb-3">
                                <label className="form-label">Guardian Name:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.guardianName ? "is-invalid" : ""}`}
                                    value={member.guardianName || ''} // Ensure value is not undefined
                                    onChange={(e) => {
                                        const newFamily = [...members.children];
                                        newFamily[index].guardianName = e.target.value;
                                        setMembers({ ...members, children: newFamily });
                                    }}
                                />
                                {errors.guardianName && <div className="invalid-feedback">{errors.guardianName}</div>}
                            </div>



                         <div className="row align-items-center">

                             <div className="col-md-9">
                                <label className="form-label">Guardian Date of Birth:</label>
                                <Flatpickr
                                    className={`form-control ${errors.guardianDob ? "is-invalid" : ""}`}
                                    value={member.guardianDob ? new Date(member.guardianDob) : null}
                                    onChange={([date]) => {
                                        const newFamily = [...members.children];
                                        if (date) {
                                            newFamily[index].guardianDob = date.toISOString();
                                            newFamily[index].guardianAge = calculateAge(date); // Implement this function to calculate age
                                        } else {
                                            newFamily[index].guardianDob = '';
                                            newFamily[index].guardianAge = '';
                                        }
                                        setMembers({ ...members, children: newFamily });
                                    }}
                                    options={{ dateFormat: "Y-m-d" }}
                                />
                                {errors.guardianDob && <div className="invalid-feedback">{errors.guardianDob}</div>}
                            </div>

                            <div className="col-md-3">
                                <label className="form-label">Guardian Age:</label>
                                <input
                                    type="number"
                                    className={`form-control ${errors.guardianAge ? "is-invalid" : ""}`}
                                    value={member.guardianAge || ''} // Ensure value is not undefined
                                    min="0"
                                    onChange={(e) => {
                                        const newFamily = [...members.children];
                                        newFamily[index].guardianAge = e.target.value;
                                        newFamily[index].guardianDob = calculateDOBFromAge(e.target.value);
                                        setMembers({ ...members, children: newFamily });
                                    }}
                                />
                                {errors.guardianAge && <div className="invalid-feedback">{errors.guardianAge}</div>}
                            </div>

                         </div>

                            <div className="mb-3">
                                <label className="form-label">Guardian Mobile Number:</label>
                                <input
                                    type="number"
                                    className={`form-control ${errors.guardianMobile ? "is-invalid" : ""}`}
                                    value={member.guardianMobile || ''} // Ensure value is not undefined
                                    onChange={(e) => {
                                        const newFamily = [...members.children];
                                        newFamily[index].guardianMobile = e.target.value;
                                        setMembers({ ...members, children: newFamily });
                                    }}
                                    maxLength={10} // Restrict input to 10 digits
                                    pattern="[0-9]*" // Accept only digits
                                />
                                {errors.guardianMobile && <div className="invalid-feedback">{errors.guardianMobile}</div>}
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Guardian Gender:</label>
                                <div className="d-flex align-items-center">
                                    <div className="form-check me-3">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            id={`guardianGenderMale-${index}`}
                                            name={`guardianGender-${index}`}
                                            value="Male"
                                            checked={member.guardianGender === "Male"}
                                            onChange={() => {
                                                const newFamily = [...members.children];
                                                newFamily[index].guardianGender = "Male";
                                                setMembers({ ...members, children: newFamily });
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor={`guardianGenderMale-${index}`}>Male</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            id={`guardianGenderFemale-${index}`}
                                            name={`guardianGender-${index}`}
                                            value="Female"
                                            checked={member.guardianGender === "Female"}
                                            onChange={() => {
                                                const newFamily = [...members.children];
                                                newFamily[index].guardianGender = "Female";
                                                setMembers({ ...members, children: newFamily });
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor={`guardianGenderFemale-${index}`}>Female</label>
                                    </div>
                                </div>
                                {errors.guardianGender && <div className="invalid-feedback d-block">{errors.guardianGender}</div>}
                            </div>
                        </div>
                    )}


                    <div className="button-group">
                        <button className="btn btn-primary" onClick={() => handleSubmit('children', index)}>Submit Children {index + 1}</button>
                        <button className="btn btn-danger ms-2" onClick={() => removeChildMemberForm(index)}>Remove Children {index + 1}</button>
                    </div>
                </div>
            ))}


            {members.nominee && (
                <div className="nominee-form card mb-4 p-4 mt-3 shadow-sm rounded">
                    <h6 className="text-secondary">Nominee Member</h6>

                    <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.nomineeName ? "is-invalid" : ""}`}
                            value={members.nominee.name}
                            onChange={(e) => handleNomineeChange("name", e.target.value)}
                        />
                        {errors.nomineeName && <div className="invalid-feedback">{errors.nomineeName}</div>}
                    </div>

                    <div className="row align-items-center">
                        <div className="col-md-9">
                            <label className="form-label">Date of Birth:</label>
                            <Flatpickr
                                className={`form-control ${errors.nomineeDob ? "is-invalid" : ""}`}
                                value={members.nominee.dob}
                                onChange={(date) => handleNomineeChange("dob", date[0])}
                            />
                            {errors.nomineeDob && <div className="invalid-feedback">{errors.nomineeDob}</div>}
                        </div>

                        <div className="col-md-3">
                            <label className="form-label">Age:</label>
                            <input
                                className={`form-control ${errors.nomineeAge ? "is-invalid" : ""}`}
                                type="number"
                                value={members.nominee.age}
                                min="0"
                                onChange={(e) => handleNomineeChange("age", e.target.value)}
                            />
                            {errors.nomineeAge && <div className="invalid-feedback">{errors.nomineeAge}</div>}
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Mobile Number:</label>
                        <input
                            type="number"
                            className={`form-control ${errors.mobile ? "is-invalid" : ""}`}
                            value={members.nominee.mobile}
                            onChange={(e) => handleNomineeChange("mobile", e.target.value)}
                            maxLength={10} // Restrict input to 10 digits
                            pattern="[0-9]*" // Accept only digits
                        />
                        {errors.mobile && <div className="invalid-feedback">{errors.mobile}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Relationship:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.nomineeRelationship ? "is-invalid" : ""}`}
                            value={members.nominee?.relationship || ""}
                            onChange={(e) => handleNomineeChange("relationship", e.target.value)}
                            placeholder="Enter relationship"
                        />
                        {errors.nomineeRelationship && <div className="invalid-feedback">{errors.nomineeRelationship}</div>}
                    </div>


                    <div className="mb-3">
                        <label className="form-label">Gender:</label>
                        <div className="d-flex align-items-center">
                            <div className="form-check me-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id={`nomineeGenderMale`}
                                    name={`nomineeGender`}
                                    value="Male"
                                    checked={members.nominee.gender === "Male"}
                                    onChange={(e) => handleNomineeChange("gender", e.target.value)}
                                />
                                <label className="form-check-label" htmlFor={`nomineeGenderMale`}>Male</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id={`nomineeGenderFemale`}
                                    name={`nomineeGender`}
                                    value="Female"
                                    checked={members.nominee.gender === "Female"}
                                    onChange={(e) => handleNomineeChange("gender", e.target.value)}
                                />
                                <label className="form-check-label" htmlFor={`nomineeGenderFemale`}>Female</label>
                            </div>
                        </div>
                        {errors.nomineeGender && <div className="invalid-feedback d-block">{errors.nomineeGender}</div>}
                    </div>

                    <div className="button-group">
                        <button
                            className="btn btn-danger me-2"
                            onClick={removeNomineeForm}
                        >
                            Remove Nominee
                        </button>
                        <button
                            className="btn btn-success me-2"
                            onClick={() => handleSubmit("nominee")}
                            disabled={isSubmitting || loading}
                        >
                            Submit Nominee
                        </button>
                    </div>
                </div>
            )}



            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleSnackbarClose} severity="success">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            </>
    );
};

export default SelectNominee;
