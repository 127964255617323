import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import CircularProgress from '@mui/material/CircularProgress';
import Flatpickr from 'react-flatpickr';
import { fetchAllData, fetchData } from '../../helpers/externapi';
import Layout from '../../Layout/Layout';

export default function TelecallerDashboard() {
    const [monthWiseCount, setMonthWiseCount] = useState([]);
    const [progressData, setProgressData] = useState({});
    const [filteredProgressData, setFilteredProgressData] = useState({});
    const [currentDate, setCurrentDate] = useState(new Date());
    const [startDate, setStartDate] = useState([]);
    const [filterOption, setFilterOption] = useState('Today');
    const [loadingChart, setLoadingChart] = useState(true);
    const [loadingTable, setLoadingTable] = useState(true);
    const [loading, setLoading] = useState(true);

    const userId = sessionStorage.getItem("UserId");

    useEffect(() => {
        const getMonthlyProgressData = async () => {
            try {
                setLoadingChart(true);
                if (!userId) {
                    console.error('UserId is not available.');
                    return;
                }
                const memberCountPerMonthData = await fetchAllData(`Member/TeleCallerProgressOfLast6MonthlyCounts/${userId}`);
                setMonthWiseCount(memberCountPerMonthData);
            } catch (error) {
                console.error('Error fetching monthly progress data:', error);
            } finally {
                setLoadingChart(false);
            }
        };
        getMonthlyProgressData();
    }, []);

    useEffect(() => {
        const getProgressData = async () => {
            try {
                setLoadingTable(true);
                setLoading(true);                
                if (!userId) {
                    console.error('UserId is not available.');
                    return;
                }
                const progressDataResponse = await fetchAllData(`Member/TeleCallerProgressTillNow/${userId}`);
                setProgressData(progressDataResponse[0]);
                handleFilterChange('Today');
            } catch (error) {
                console.error('Error fetching progress data:', error);
            } finally {
                setLoadingTable(false);
                setLoading(false);
            }
        };
        getProgressData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 86400000);
        return () => clearInterval(intervalId);
    }, []);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleFilterChange = async (option) => {
        setFilterOption(option);
        setLoadingTable(true);
        let newStartDate = new Date();
        let newEndDate = new Date();

        if (option === 'Last 7 days') {
            newStartDate.setDate(newStartDate.getDate() - 7);
        } else if (option === 'Last 30 days') {
            newStartDate.setDate(newStartDate.getDate() - 30);
        } else if (option === 'Custom') {
            setLoadingTable(false);
            return;
        }

        try {
            setLoading(true);
            const response = await fetchData('Member/GetTeleCallerProgressByFilter', {
                value: [formatDate(newStartDate), formatDate(newEndDate), sessionStorage.getItem("UserId")],
            });
            setFilteredProgressData(response[0]);
        } catch (error) {
            console.error('Error fetching filtered progress data:', error);
        }
        setLoadingTable(false);
        setLoading(false);
    };

    const handleCustomDateChange = async () => {
        if (startDate.length === 2) {
            setLoadingTable(true);
            try {
                setLoading(true);
                const response = await fetchData('Member/GetTeleCallerProgressByFilter', {
                    value: [formatDate(startDate[0]), formatDate(startDate[1]), sessionStorage.getItem("UserId")],
                });
                setFilteredProgressData(response[0]);
            } catch (error) {
                console.error('Error fetching filtered progress data:', error);
            } finally {
                setLoadingTable(false);
            }
            setLoading(false);
        }
    };

    const chartOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
            foreColor: '#9e9e9e',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: monthWiseCount.map(item => item.month),
            labels: {
                style: {
                    colors: '#9e9e9e',
                },
            },
        },
        yaxis: {
            title: {
                text: 'Count',
                style: {
                    color: '#9e9e9e',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: (val) => val,
            },
        },
    };

    const series = [
        {
            name: 'Distributors',
            data: monthWiseCount.map(item => item.DistributorCount),
        },
        {
            name: 'Customers',
            data: monthWiseCount.map(item => item.MemberCount),
        },
        {
            name: 'Premium Cards',
            data: monthWiseCount.map(item => item.OHOCardsPremiumCount),
        },
        {
            name: 'Privilege Cards',
            data: monthWiseCount.map(item => item.OHOCardsPrivilegeCount),
        },
        {
            name: 'Priority Cards',
            data: monthWiseCount.map(item => item.OHOCardsPriorityCount),
        },
        {
            name: 'Call History',
            data: monthWiseCount.map(item => item.CallHistoryCount),
        },
    ];

    const progressLabels = {
        DistributorCount: 'Distributors',
        MemberCount: 'Customers',
        OHOCardsPremiumCount: 'Premium Cards',
        OHOCardsPrivilegeCount: 'Privilege Cards',
        OHOCardsPriorityCount: 'Priority Cards',
        CallHistoryCount: 'Call History'
    };

    const icons = {
        DistributorCount: <i className="fa-solid fa-users"></i>,
        MemberCount: <i className="fa-solid fa-user"></i>,
        OHOCardsPremiumCount: <i className="fa-solid fa-id-card"></i>,
        OHOCardsPrivilegeCount: <i className="fa-solid fa-id-card"></i>,
        OHOCardsPriorityCount: <i className="fa-solid fa-id-card"></i>,
        CallHistoryCount: <i className="fa-solid fa-phone"></i>
    };

    const cardStyle = {
        boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
        transition: 'box-shadow 0.3s ease',
    };

    const bottomHighlightStyle = {
        height: '5px',
        backgroundColor: '#4287f5',
        transition: 'width 0.3s ease',
        width: '0',
        position: 'absolute',
        bottom: '0',
        left: '0',
    };

    const skeletonLoading = () => (
        <div className="row mt-2">
            <style>
                {styles}
            </style>
            {[...Array(6)].map((_, index) => (
                <div className="col-md-2 mb-4" key={index}>
                    <div className="card custom-card card-border-shadow-primary h-100 skeletonCardStyle shimmer">
                        <div className="card-body custom-card-body">
                            <div className="d-flex align-items-center mb-2 pb-1">
                                <div className="avatar me-2">
                                    <span className="avatar-initial rounded bg-label-primary" style={{ width: '40px', height: '40px' }}></span>
                                </div>
                                <h4 className="ms-1 mb-0 textStyle" style={{ width: '60%', height: '24px' }}></h4>
                            </div>
                            <p className="d-block mb-1 textStyle" style={{ width: '80%', height: '16px' }}></p>

                        </div>
                    </div>
                </div>
            ))}
            <div className="row">

                <div className="col-md-6">
                    <div className="card shadow mb-4 skeletonCardStyle shimmer">
                        <div className="card-body">
                            <h5 className="card-title textStyle" style={{ width: '70%', height: '24px' }}></h5>
                            <div className="textStyle" style={{ width: '100%', height: '350px' }}></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card shadow mb-4 skeletonCardStyle shimmer">
                        <div className="card-body">
                            <h5 className="card-title textStyle" style={{ width: '70%', height: '24px' }}></h5>
                            <div className="textStyle" style={{ width: '100%', height: '350px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
            {Object.keys(progressLabels).map((key) => (
                key !== 'DistributorCount' &&
                key !== 'MemberCount' &&
                key !== 'OHOCardsPremiumCount' &&
                key !== 'OHOCardsPrivilegeCount' && (
                    <div className="col-md-6" key={key}>
                        <div className="card shadow mb-4 skeletonCardStyle shimmer">
                            <div className="card-body">
                                <h5 className="card-title textStyle" style={{ width: '70%', height: '24px' }}></h5>
                                <div className="textStyle" style={{ width: '100%', height: '350px' }}></div>
                            </div>
                        </div>
                    </div>
                )
            ))}
            <div className="col-md-12">


                <h5 className="textStyle" style={{ width: '20%', height: '24px' }}></h5>


                <div className="row mt-2">
                    <div className="col-12 mb-1">
                        <h4 className="card-title textStyle" style={{ width: '30%', height: '32px' }}></h4>
                    </div>
                </div>

                <div className="row mt-2">
                    {Object.keys(progressLabels).map((key) => (
                        <div className="col-md-3 mb-4" key={key}>
                            <div className="card custom-card card-border-shadow-primary h-100 skeletonCardStyle shimmer">
                                <div className="card-body custom-card-body">
                                    <div className="d-flex align-items-center mb-2 pb-1">
                                        <div className="avatar me-2">
                                            <span className="avatar-initial rounded bg-label-primary textStyle" style={{ width: '40px', height: '40px' }}></span>
                                        </div>
                                        <h4 className="ms-1 mb-0 textStyle" style={{ width: '60%', height: '24px' }}></h4>
                                    </div>
                                    <p className="d-block mb-1 textStyle" style={{ width: '80%', height: '16px' }}></p>


                                </div>
                            </div>
                        </div>
                    ))}
                </div>


            </div>
        </div>
    );

    const renderCards = () => (
        <div className="mt-4">
            {loadingTable ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div className="row mt-2">
                        <div className="col-12 mb-2">
                            <h4 className="card-title">
                                OUR PROGRESS TILL TODAY <span>{formatDate(currentDate)}</span>
                            </h4>
                        </div>
                        {Object.entries(progressLabels).map(([key, label]) => (
                            <div className="col-md-2 mb-4" key={key}>
                                <div
                                    className="card custom-card card-border-shadow-primary h-100"
                                    style={cardStyle}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.boxShadow = '0 8px 16px 0 rgba(0,0,0,0.2)';
                                        e.currentTarget.querySelector('.card-bottom-highlight').style.width = '100%';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.boxShadow = 'none';
                                        e.currentTarget.querySelector('.card-bottom-highlight').style.width = '0';
                                    }}
                                >
                                    <div className="card-body custom-card-body">
                                        <div className="d-flex align-items-center mb-2 pb-1">
                                            <div className="avatar me-2">
                                                <span className="avatar-initial rounded bg-label-primary">{icons[key]}</span>
                                            </div>
                                            <h4 className="ms-1 mb-0">
                                                {key === 'TotalPremiumAmount' ?
                                                    (progressData[key]) :
                                                    key === 'TotalPrivilegeAmount' ?
                                                        (progressData[key]) :
                                                        key === 'TotalPriorityAmount' ?
                                                            (progressData[key]) :
                                                            progressData[key]
                                                }
                                            </h4>
                                        </div>
                                        <p className="d-block mb-1">{label}</p>
                                        <div className="card-bottom-highlight" style={bottomHighlightStyle}></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h5>Filter</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="col-3 me-3">
                                            <select
                                                className="form-select"
                                                value={filterOption}
                                                onChange={(e) => handleFilterChange(e.target.value)}
                                            >
                                                <option value="Today">Today</option>
                                                <option value="Last 7 days">Last 7 days</option>
                                                <option value="Last 30 days">Last 30 days</option>
                                                <option value="Custom">Custom Date Range</option>
                                            </select>
                                        </div>
                                        {filterOption === 'Custom' && (
                                            <>
                                                <div className="col-md-3 col-4 mb-4 my-1">
                                                    <label htmlFor="flatpickr-range" className="form-label">Select multiple dates</label>
                                                    <Flatpickr
                                                        id="flatpickr-range"
                                                        className="form-control"
                                                        placeholder="YYYY-MM-DD to YYYY-MM-DD"
                                                        value={startDate}
                                                        options={{
                                                            mode: 'range',
                                                            dateFormat: 'Y-m-d',
                                                        }}
                                                        onChange={(selectedDates) => {
                                                            if (selectedDates.length === 2) {
                                                                setStartDate(selectedDates);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-3 mx-2 mt-2">
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={handleCustomDateChange}
                                                    >
                                                        Apply
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 mb-1">
                                            <h4 className="card-title" style={{ color: '#4287f5' }}>
                                                Tele Caller Progress by date range
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        {loadingTable ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                <CircularProgress />
                                            </div>
                                        ) : (
                                            <div className="table-responsive">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Label</th>
                                                            <th scope="col">Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.entries(progressLabels).map(([key, label]) => (
                                                            <tr key={key}>
                                                                <td>{label}</td>
                                                                <td>
                                                                    {key === 'TotalPremiumAmount' ?
                                                                        (filteredProgressData[key]) :
                                                                        key === 'TotalPrivilegeAmount' ?
                                                                            (filteredProgressData[key]) :
                                                                            key === 'TotalPriorityAmount' ?
                                                                                (filteredProgressData[key]) :
                                                                                filteredProgressData[key]
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );   

    return (
        <Layout>
            <div className="container">
            {loadingChart ? (
                <div className="d-flex justify-content-center">
                    <CircularProgress />
                </div>
            ) : (
                renderCards()
            )}
            <div className="card mt-5">
                {loading && skeletonLoading()}
                {!loading && (
                   
                <div className="card-body">
                    <div>
                        <h6>Tele Caller Progress Monthly Wise</h6>
                    </div>
                    {loadingChart ? (
                        <div className="d-flex justify-content-center">
                            <CircularProgress />
                        </div>
                    ) : (
                        <Chart options={chartOptions} series={series} type="bar" height={350} />
                    )}
                </div>
                )}
                
            </div>
            </div>
        </Layout>
    );
}

const styles = `
        .cardStyle {
            border: 1px solid #ddd;
            border-radius: 8px;
            transition: box-shadow 0.3s ease-in-out;
            background-color: #F7F7F7; /* Lighter background for cards */
        }
        .bottomHighlightStyle2 {
            height: 4px;
            background-color: #007bff;
            transition: width 0.3s ease-in-out;
        }

        .shimmer {
            animation-duration: 2.5s; /* Increased duration for slower movement */
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: shimmer;
            animation-timing-function: linear;
            background: linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%);
            background-size: 1000px 104px;
            position: relative;
            overflow: hidden;
        }

        @keyframes shimmer {
            0% {
                background-position: -1000px 0;
            }
            100% {
                background-position: 1000px 0;
            }
        }

        .skeletonCardStyle {
            border: 1px solid #ddd;
            border-radius: 8px;
            transition: box-shadow 0.3s ease-in-out;
            background-color: #f0f0f0; /* Lighter background for cards */
            overflow: hidden;
            position: relative;
        }
        .textStyle {
            width: 100%;
            height: 100%;
            background: #c8c8c8; /* Darker background for text */
            animation: shimmer 2.5s infinite linear;
            border-radius: 4px; /* Optional: to match the style */
        }
        `;