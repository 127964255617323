import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BoxWrapper from "../../Components/BoxWrapper";
import moment from 'moment';
import MuiAlert from '@mui/material/Alert';
import { fetchDeleteData, fetchData, fetchAllData, fetchUpdateData, uploadImage, uploadPdf } from "../../helpers/externapi";
import Snackbar from '@mui/material/Snackbar';
import { FaHeartbeat } from 'react-icons/fa';
import CardEditForms from '../../Components/CardEditForms';
import SelectNominee from '../../Components/SelectNominee';
import TableContainer from '@mui/material/TableContainer';
import ConfirmationDialogDelete from "../../Components/ConfirmationDialogDelete";
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign, faL } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomerPersonalDetails(props) {
    const [profile, setProfile] = React.useState(props.data);
    const [kycMessage, setKycMessage] = React.useState();
    const navigate = useNavigate();
    const [file, setFile] = useState();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [callResponseOptions, setCallResponseOptions] = useState([]);
    const [activeTab, setActiveTab] = useState('callHistory');
    const [formError, setFormError] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [formVisible, setFormVisible] = useState(false);
    const [callHistory, setCallHistory] = useState([]);
    const [cardDetails, setCardDetails] = React.useState([]);
    const [loading, setLoading] = React.useState();
    const [products, setProducts] = useState([]);
    const [showNominee, setShowNominee] = useState(false);
    const [selectedProduct, setSelectedProduct] = React.useState(null);
    const [showProductDetailsTab, setShowProductDetailsTab] = useState(false);
    const initialFormData = {
        callHistoryId: "", callLog: "", CollectedDate: "", callResponsesId: "", DateToBeVisited: ""
    }
    const [formData, setFormData] = useState(initialFormData);
    const [isFormValid, setIsFormValid] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationData, setConfirmationData] = useState({});
    const [availableProducts, setAvailableProducts] = useState();
    const [memberDetails, setMemberDetails] = useState();
    const [purchaseProduct, setPurchaseProduct] = useState();
    const [utrNumber, setUtrNumber] = useState('');
    const [utrError, setUtrError] = useState(false);
    const [paymentSuccessMsg, setPaymentSeccessMsg] = useState(false);
    const [comboProductDetails, setComboProductDetails] = useState([]);
    const [familyMembersLength, setFamilyMembesLength] = useState();
    const [cardNumberEntered, setCardNumberEntered] = useState(false);
    const [cardNumber, setCardNumber] = useState("2804 00");
    const [cardNumberError, setCardNumberError] = useState(null);
    const [memberPrdAddFail, setMemberProdAddFail] = useState('');
    const [paymentFailureError, setPaymentFailureError] = useState('');
    const [updatePaymentForProductError, setUpdatePaymentForProductError] = useState('');
    const [cardAddError, setCardAddError] = useState('');
    const [utrSubmitLoad, setUtrSubmitLoad] = useState(false);

    const UserId = sessionStorage.getItem("UserId");
    const { Id } = useParams();

    const handleBackToList = () => {
        navigate("/customers/list");
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        const isFormValid = formData.callResponsesId.length > 0;
        setIsFormValid(isFormValid);
    }, [formData]);

    useEffect(() => {
        const getCallResponse = async () => {
            setLoading(true);
            try {
                const getResponseTypes = await fetchData('CallResponseType/all', { skip: 0, take: 0 });

                let CallResponseTypeId = getResponseTypes.filter(types => types.ResponseName === "Member");

                const response = await fetchAllData(`CallResponse/GetCallResponsesByResponseType/${CallResponseTypeId[0].CallResponseTypeId}`);
                setCallResponseOptions(response);
            } catch (error) {
                console.error('Error fetching call responses:', error);
            } finally {
                setLoading(false);
            }
        };

        const getAvailableProducts = async () => {
            setLoading(true);
            try {
                if (cardDetails && products && cardDetails.status && products.length > 0) {
                    const response = await fetchAllData(`MembersProducts/GetProductForMember/${memberDetails[0].MemberId}`);
                    setAvailableProducts(response);
                } else {
                    const response = await fetchData('Products/all', { skip: 0, take: 0 });

                    let getCombo = [];

                    response && response.length > 0 && response.map(each => {
                        if (each.IsCombo) {
                            getCombo = [...getCombo, each];
                        }
                    })

                    setAvailableProducts(getCombo);
                }
            } catch (error) {
                console.error('Error fetching available products: ', error);
            }
        };

        getAvailableProducts();
        getCallResponse();
    }, [memberDetails, products]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchAllData(`OHOCards/GetMemberCardByMemberId/${Id}`);
                setCardDetails(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [Id]);

    //useEffect(() => {
    //    const fetchData = async () => {
    //        setLoading(true);
    //        try {
    //            const response = await fetchAllData(`MemberDependent/GetByMemberId/${Id}`);
    //          //  console.log("Dependent", response.length);
    //            setFamilyMembesLength(response.length);

    //            setLoading(false);
    //        } catch (error) {
    //            console.error("Error fetching Dependent data: ", error);
    //            setLoading(false);
    //        } finally {
    //            setLoading(false);
    //        }
    //    };
    //    fetchData();
    //}, [Id]);

    useEffect(() => {
        const fetchProductsData = async () => {
            setLoading(true);
            try {
                const response = await fetchAllData(`Member/GetMemberProducts/${Id}`);
                setProducts(response);
                console.log("responseProduct", response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        const fetchMemberData = async () => {
            setLoading(true);
            try {
                const response = await fetchAllData(`Member/GetById/${Id}`);
                setMemberDetails(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchMemberData();
        fetchProductsData();
    }, [Id]);

    const convertDOBFormat = (dobString) => {
        const date = new Date(dobString);
        if (isNaN(date.getTime())) {
            return "Invalid Date";
        } else {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
    };

    const handleSelectMemberClick = () => {
        setShowNominee(true);
    };

    const handleCardNumberChange = (e) => {
        // Extract only digits after the default prefix "2804 00"
        let input = e.target.value.replace(/\D/g, "").substring(6);

        // Limit to 6 digits for the user-input portion
        if (input.length > 6) {
            input = input.slice(0, 6);
        }

        // Format as "2804 00XX XXXX"
        const formattedCardNumber = `2804 00${input.slice(0, 2)} ${input.slice(2)}`.trim();
        setCardNumber(formattedCardNumber);
    };

    const checkCardNumberExists = async () => {
        try {
            const response = await fetchData('OHOCards/cardNumberExistorNot', { cardNumber });
            if (response.status === true) {
                setCardNumberEntered(true);
                setCardNumberError(null);
            } else {
                setCardNumberError(response.message);
            }
        } catch (error) {
            setCardNumberError("An error occurred while checking the card number. Please try again.");
        }
    };

    const handleProductClick = async (product) => {
        setSelectedProduct((prevProduct) =>
            prevProduct && prevProduct.ProductsId === product.ProductsId ? null : product
        );


        const details = await fetchAllData(`ComboProducts/FetchComboProducts/${product.ProductsId}`);
        // console.log("details", details, product.ProductsId);
        setComboProductDetails(details);


        const response = await fetchAllData(`MemberDependent/GetDependentsByMemberProductId/${Id}/${product.ProductsId}`);
        console.log("Dependent", response.length);
        setFamilyMembesLength(response.length);

        const isSameProduct = selectedProduct && selectedProduct.ProductsId === product.ProductsId;
        setShowNominee(true);
        if (isSameProduct) {
            setIsEditing(false);
            setShowProductDetailsTab(false);
        } else {
            setIsEditing(true);
            setShowProductDetailsTab(true);
            setActiveTab('products');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetchAllData(`Dependent/GetMemberById/${Id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setProfile(data);
                setFormData(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [Id]);

    const handleDelete = () => {
        setConfirmationData({
            title: 'Delete Member',
            message: 'Are you sure you want to delete this Member?',
            onConfirm: () => confirmhandleDelete(),
        });
        setConfirmationOpen(true);
    };

    const confirmhandleDelete = async () => {
        try {
            const response = await fetchDeleteData(`Member/delete/${Id}`)
            if (response.status === false) {
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
                return;
            }
            setSnackbarMessage(`Deleted Successfully`);
            setSnackbarOpen(true);

            setTimeout(() => {
                navigate("/customers/list");
            }, 2000);
        } catch (error) {
            console.error("Error deleting event:", error);
            setSnackbarMessage('Failed to delete. Please try again.');
            setSnackbarOpen(true);
        }
    };

    const customStyles = {
        rows: {
            style: {
                minHeight: '45px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                color: "#566a7f",
                fontSize: "0.75rem",
                letterSpacing: 1,
                textTransform: "uppercase"
            },
        },
        cells: {
            style: {
                paddingLeft: '2px',
                paddingRight: '2px',
                fontSize: "12px"
            },
        },
        addCallLogButton: {
            fontSize: 14,
            padding: 5,
        },
    };

    const fetchCallHistoryData = async () => {
        setLoading(true);
        try {
            const response = await fetchAllData(`CallHistory/GetAllCallHistoryByMemberId/${Id}`);
            setCallHistory(response);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCallHistoryData();
    }, [Id]);

    const handleAddNewCallLog = () => {
        setFormVisible(true);
        setFormData({ callHistoryId: "", callLog: "", CollectedDate: "", callResponsesId: "", DateToBeVisited: "" });
    };

    const handleBackToView = () => {
        setFormVisible(false);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setPurchaseProduct();
        if (tab !== 'products') {
            setShowProductDetailsTab(false);
        }
    };

    const formatCardNumber = (cardNumber) => {
        if (!cardNumber) return '';
        const chunks = cardNumber.replace(/\s/g, '').match(/.{1,4}/g);
        return chunks.join(' ');

    };

    const handlePrint = () => {
        const addAddress = () => {
            let address = '';

            selectedProduct.AddressLine1 && selectedProduct.AddressLine1.length > 0 &&
                (address = `${address} ${selectedProduct.AddressLine1}`);
            selectedProduct.AddressLine2 && selectedProduct.AddressLine2.length > 0 &&
                (address = `${address}, ${selectedProduct.AddressLine2}`);
            selectedProduct.Village && selectedProduct.Village.length > 0 &&
                (address = `${address}, ${selectedProduct.Village}`);
            selectedProduct.City && selectedProduct.City.length > 0 &&
                (address = `${address}, ${selectedProduct.City}`);
            selectedProduct.Mandal && selectedProduct.Mandal.length > 0 &&
                (address = `${address}, ${selectedProduct.Mandal}`);
            selectedProduct.District && selectedProduct.District.length > 0 &&
                (address = `${address}, ${selectedProduct.District}`);
            selectedProduct.State && selectedProduct.State.length > 0 &&
                (address = `${address}, ${selectedProduct.State}`);
            selectedProduct.pincode && selectedProduct.pincode.length > 0 &&
                (address = `${address}, ${selectedProduct.pincode}`);

            return address;
        };

        const recipientName = selectedProduct.Name || "Recipient Name";
        const recipientMobile = selectedProduct.MobileNumber || "Mobile Number";

        // Static "From" address
        const fromAddress = `
        OHOINDIA LIFE TECH PVT. LTD<br>
        5th Floor, 1-98/9/4/20,<br>
         Arunodaya Colony, VIP Hills, <br>
         Hi-Tech City, Madhapur,<br>
        Hyderabad, Telangana 500081<br>
        Mobile No. 7671997108
    `;

        // Logo and contact details
        const appDetails = `
        <div style="text-align: center; padding: 10px; margin-bottom: 20px;">
            <img src="${process.env.PUBLIC_URL}/assets/applogo.png" height="50" alt="App Logo" /><br>
            <strong>OHOINDIA</strong><br>
            Website:- www.ohoindialife.com<br>
            Contact No:- 7671997108 / 7032107108<br>
            Email:- contact@ohoindialife.com<br>
        </div>
    `;

        const printWindow = window.open("", "", "width=800,height=600");
        printWindow.document.write('<html>');
        printWindow.document.write(`
    <style>
        body {
            margin: 0;
            padding: 0;
            font-family: Arial, sans-serif;
            padding: 20px;
        }
        .box {
            border: 1px solid black;
            padding: 10px;
            margin-bottom: 20px;
        }
        h5 {
            margin: 0;
        }
    </style>
    `);
        printWindow.document.write('<body>');
        printWindow.document.write(appDetails);
        printWindow.document.write(`
        <div class="box">
            <strong>From:</strong><br>
            ${fromAddress}
        </div>
    `);
        printWindow.document.write(`
        <div class="box">
            <strong>To:</strong><br>
            ${recipientName}<br>
            ${addAddress()} <br>
             Mobile.No: ${recipientMobile}
        </div>
    `);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    const handleUploadChange = (e) => {
        e.preventDefault();
        setFile(e.target.files[0]);
    };

    const handleFileSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('memberId', selectedProduct.MemberId);
        formData.append('productsId', selectedProduct.ProductsId);
        // formData.append('individualProductsId', selectedProduct.IndividualProductId);
        formData.append('policyDocument', file);

        // const formData = {
        //     MemberId: selectedProduct.MemberId,
        //     ProductsId: selectedProduct.ProductsId,
        //     PolicyDocument: file
        // }

        // console.log("FORM DATA: ", formData);

        try {
            const uploadFile = await uploadPdf('MembersProducts/uploadingPolicyDoc', formData);
            console.log("uploadFile", uploadFile);
            setSnackbarMessage(uploadFile.message)
            setSnackbarOpen(true);
            window.location.reload();

        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    const handleGenerateMembership = async () => {
        setLoading(true)
        try {
            console.log("Fetching configuration values...");
            const configResponse = await fetchData('ConfigValues/all', { skip: 0, take: 0 });

            const bucketUrlConfig = configResponse && configResponse.length > 0
                ? configResponse.find(val => val.ConfigKey === "policiesDownloadURL")
                : null;

            if (!bucketUrlConfig) {
                console.error("Policies download URL configuration not found.");
                alert("Unable to download PDF. Configuration missing.");
                return;
            }

            const bucketUrl = bucketUrlConfig.ConfigValue;

            console.log("Fetching policy generation data...");
            const response = await fetchData('PaymentDetails/PolicyGeneration', {
                MemberId: selectedProduct.MemberId,
                productsId: selectedProduct.ProductsId,
                MemberProductId: selectedProduct.MemberProductId
            });

            console.log("Policy generation response: ", response);

            const { status, message, data } = response || {};

            if (status && data) {
                const downloadUrl = `${bucketUrl}${data}`;

                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = data;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                console.log("PDF downloaded successfully from URL:", downloadUrl);
            } else {
                console.error("File generation failed or data is missing. Status:", status, "Message:", message);
                alert("Unable to download PDF. " + message);
            }
        } catch (error) {
            console.error('Error downloading membership PDF:', error);
            alert("An error occurred while trying to download the PDF.");
        } finally {
            setLoading(false);
        }
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            let CallHistoryData;
            const requestData = {
                callLog: formData.callLog,
                MemberId: Id,
                userId: UserId,
                callResponsesId: formData.callResponsesId
            };

            if (formData.DateToBeVisited) {
                requestData.DateToBeVisited = new Date(formData.DateToBeVisited).toISOString();
            }

            if (formData.RequestCallBack) {
                requestData.RequestCallBack = new Date(formData.RequestCallBack).toISOString();
            }

            if (isEditing) {
                requestData.callHistoryId = formData.callHistoryId;
                CallHistoryData = await fetchUpdateData('CallHistory/update', requestData);
                setSnackbarMessage("Call log updated successfully!");
            } else {
                CallHistoryData = await fetchData('CallHistory/add', requestData);
                setSnackbarMessage("New call log added successfully!");
            }

            setCallHistory(CallHistoryData);
            setSnackbarOpen(true);
            setIsEditing(false);

            await fetchCallHistoryData();
        } catch (error) {
            console.error("Error adding call log:", error);
        } finally {
            setLoading(false);
            setFormVisible(false);
            setFormData(initialFormData);
        }
    };

    const handleEditForm = () => {
        navigate("/customers/new", { state: { profile } })
    };

    const handleResetForm = () => {
        setFormData(initialFormData);
        setFormError({});
    };

    const onChangeHandler = (event) => {
        const { name, value, type, checked } = event.target;
        let updatedFormData = { ...formData, [name]: type === 'checkbox' ? (checked ? value : '') : value };
        let error = '';

        //if (name === 'DateToBeVisited' && value.length === 10) {
        //    const defaultTime = "T00:00:00";
        //    updatedFormData = { ...updatedFormData, DateToBeVisited: `${value}${defaultTime}` };
        //}
        setFormData(updatedFormData);
        setFormError({ ...formError, [name]: error });
    };

    const formatKeyFeatures = (keyFeatures) => {
        if (!keyFeatures) return [];
        return keyFeatures
            .replace(/<[^>]*>/g, '')
            .split('.')
            .filter(feature => feature.trim() !== '');
    };

    const cardActiveAndDeactive = async () => {
        const memberId = cardDetails.returnData[0].CardPurchasedMemberId;
        const isActivated = cardDetails.returnData[0].IsActivated === null ? true : cardDetails.returnData[0].IsActivated === false ? true : false;

        const updateApi = await fetchData('OHOCards/ActivateorDeactivateTheCard', { memberId, isActivated });

        window.location.reload();
    };

    const handleUpdateActivated = (message) => {
        setConfirmationData({
            title: `${message} Caed`,
            message: `Are you sure you want to ${message} this Card?`,
            onConfirm: () => cardActiveAndDeactive(),
        });
        setConfirmationOpen(true);
    };

    const onChangeUtrNumber = (e) => {
        setUtrNumber(e.target.value);
    };

    const submitUtrForm = async (e) => {
        e.preventDefault();

        if (utrNumber && utrNumber.length >= 10) {
            setUtrError(false);
            setUtrSubmitLoad(true);

            const memberProductPayload = {
                productsId: purchaseProduct.ProductsId,
                memberId: memberDetails[0].MemberId,
                userId: UserId
            };

            const responseMemberProductAdd = await fetchData('MembersProducts/add', memberProductPayload);

            if (responseMemberProductAdd && responseMemberProductAdd.length > 0) {
                setMemberProdAddFail('');

                const paymentPayload = {
                    customerName: memberDetails[0].Name,
                    mobileNumber: memberDetails[0].MobileNumber,
                    paidAmount: purchaseProduct.SaleAmount,
                    utrNumber: utrNumber,
                    typeofCard: "Privilege",
                    memberProductId: responseMemberProductAdd[0].memberProductId
                }

                const responsePayment = await fetchData("PaymentDetails/add", paymentPayload);

                if (responsePayment && responsePayment.paymentDetailsId) {
                    setPaymentFailureError('');

                    const paymentMemberProductPayload = {
                        productsId: responseMemberProductAdd[0].productsId,
                        memberId: memberDetails[0].MemberId,
                        paymentDetailsId: responsePayment.paymentDetailsId
                    }

                    const responsePaymentMemberProduct = await fetchData('PaymentDetails/UpdatePaymentIdInMemberProduct', paymentMemberProductPayload);

                    if (responsePaymentMemberProduct) {
                        setUpdatePaymentForProductError('');

                        const ohoCardPayload = {
                            ohoCardsId: 0,
                            ohoCardNumber: cardNumber,
                            assignedToRM: UserId,
                            assignedToMember: memberDetails[0].MemberId,
                            cardPurchasedMemberId: memberDetails[0].MemberId,
                            productsId: responseMemberProductAdd[0].productsId,
                            userId: UserId
                        };

                        const responseOhoCard = await fetchData('OHOCards/add', ohoCardPayload);

                        setPaymentSeccessMsg(true);
                        if (responseOhoCard && responseOhoCard.status) {
                            setCardAddError('');
                            setPaymentSeccessMsg(true);
                            setTimeout(() => {
                                setPurchaseProduct();
                                setPaymentSeccessMsg(false);
                                setUtrNumber('');
                                setUtrError(false);
                                setUtrSubmitLoad(false);
                                window.location.reload();
                            }, 2000);
                        } else {
                            setUtrSubmitLoad(false);
                            setCardAddError('Sorry, Failed to add card. Please contact Technical team');
                            console.error('Failed to generate OHO card:', responseOhoCard);
                        }
                    } else {
                        setUtrSubmitLoad(false);
                        setUpdatePaymentForProductError('Sorry, Failed to update payment for product. Please contact technical team');
                    }
                } else {
                    setUtrSubmitLoad(false);
                    setPaymentFailureError('Sorry payment failed. Please contact technical team.');
                }
            } else {
                setUtrSubmitLoad(false);
                setMemberProdAddFail('Sorry, Failed to add product. Please contact technical team');
            }

        } else {
            setUtrError(true);
        }
    };


    const handleBookService = async (e) => {
        e.preventDefault();
        navigate('/Others/BookConsultation/book', {
            state: { memberId: Id }
        });
    };

    const thresholdDays = 5;

    return (
        <>
            <div className="card-action">
                <div className="btn-toolbar mb-1 d-flex justify-content-between flex-wrap mt-2">
                    {/*<div className="btn-group">
                        <button onClick={handleBackToList} className="btn btn-sm btn-primary m-1">Back To List</button>
                    </div>*/}
                </div>

                {kycMessage && (
                    <div style={{
                        backgroundColor: '#4CAF50', color: 'white', padding: '5px', marginTop: '5px', borderRadius: '5px', textAlign: 'center', float: 'right',
                        width: '100%'
                    }}>
                        {kycMessage}
                    </div>
                )}
            </div>
            <div className="container-xxl flex-grow-1">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card mb-4 d-flex" style={{ minHeight: "200px" }}>
                            <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start mx-3 my-3">
                                <div className="flex-shrink-0 me-md-4 mb-3 mb-md-0">
                                    <img
                                        src={profile.MemberImage ? `https://ohoindiaimages.s3.ap-south-1.amazonaws.com/${profile.MemberImage.split('.').shift()}.jpg` : "../../assets/img/dummy-avatar.jpg"}
                                        height="250"
                                        width="250"
                                        alt="User avatar"
                                        className="d-block rounded user-profile-img"
                                    />
                                </div>

                                <div className="flex-grow-1">
                                    <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start flex-md-row flex-column gap-4">
                                        <div className="user-profile-info p-4" style={{ backgroundColor: "#f8f9fa", borderRadius: "12px", boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)" }}>
                                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                                                <h2 className="mb-3 text-dark" style={{ fontSize: "24px", fontWeight: "600" }}>{profile.Name}</h2>

                                                <div className="d-flex align-items-center gap-2">
                                                    <button type="button" className="btn btn-outline-primary btn-sm" onClick={handleEditForm}>
                                                        <i className="bx bx-user-edit me-1"></i> Edit
                                                    </button>
                                                    <button className="btn btn-outline-danger btn-sm" onClick={handleDelete}>
                                                        <i className="bx bx-trash-alt me-1"></i> Delete
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="row text-muted" style={{ fontSize: "14px" }}>
                                                <div className="col-xl-12 mb-2">
                                                    <i className="bx bx-map me-2"></i><strong>Address :</strong> {profile.completeAddress || "Not provided"}
                                                </div>

                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-calendar me-2"></i><strong>RegOn :</strong> {moment(profile.RegisterOn).format('DD-MMM-YYYY')}
                                                </div>

                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-calendar-alt me-2"></i><strong>DOB :</strong> {profile.DateofBirth ? moment(profile.DateofBirth).format('DD-MMM-YYYY') : "Not updated"}
                                                </div>

                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-phone me-2"></i><strong>Mobile No :</strong> {profile.MobileNumber ? (
                                                        <a href={"tel:" + profile.MobileNumber} className="text-dark">{profile.MobileNumber}</a>
                                                    ) : <span className="text-danger">Not updated</span>}
                                                </div>

                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-envelope me-2"></i><strong>Email : </strong> {profile.Email ? (
                                                        <a href={"mailto:" + profile.Email} className="text-dark">{profile.Email}</a>
                                                    ) : <span className="text-danger">Not updated</span>}
                                                </div>

                                                {profile.ServiceProviderName && (
                                                    <div className="col-md-4 mb-2">
                                                        <span><strong>{profile.ServiceProviderName} : </strong></span>{" "}
                                                        <span className={`badge ${profile.ServiceProviderStatus === "Pending" ? "bg-danger" :
                                                            profile.ServiceProviderStatus === "Processing" ? "bg-warning" :
                                                                profile.ServiceProviderStatus === "Active" ? "bg-success" :
                                                                    "bg-secondary"}`}>
                                                            {profile.ServiceProviderStatus || 'Pending'}
                                                        </span>
                                                    </div>
                                                )}


                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-user me-2"></i><strong>Gender :</strong> {profile.Gender || "Not specified"}
                                                </div>
                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-id-card me-2"></i><strong>RM Name :</strong> {profile.RMName || "Not assigned"}
                                                </div>
                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-building me-2"></i><strong>Route :</strong> {profile.RouteName || "Not assigned"}
                                                </div>

                                                <div className="col-md-4 mb-2">
                                                    <i className="fa fa-volume-control-phone me-2" aria-hidden="true"></i><strong>Telecaller :</strong> {profile.TelecallerName || "Not assigned"}
                                                </div>


                                                <div className="col-md-4 mb-2">
                                                    <strong>Membership Status :</strong>
                                                    <span className={`badge ${profile.MembershipStatus === "Pending" ? "bg-danger" :
                                                        profile.MembershipStatus === "Active" ? "bg-success" : "bg-secondary"}`}>
                                                        {profile.MembershipStatus || "Pending"}
                                                    </span>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeTab === 'callHistory' ? 'active' : ''}`}
                                onClick={() => handleTabChange('callHistory')}
                            >
                                <i className='bx bx-phone'></i> Call History
                            </button>
                        </li>

                        <li className="nav-item">
                            <button className={`nav-link ${activeTab === 'cards' ? 'active' : ''}`} onClick={() => handleTabChange('cards')}>
                                <i className='bx bx-card'></i> Cards & Products</button>
                        </li>

                        {showProductDetailsTab && (
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'products' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('products')}
                                >
                                    <i className='bx bx-grid-alt'></i> Product Details
                                </button>
                            </li>
                        )}

                        <li className="nav-item">
                            <button className={`nav-link ${activeTab === 'purchaseProducts' ? 'active' : ''}`} onClick={() => handleTabChange('purchaseProducts')}>
                                <i className="fa-solid fa-list"></i> Purchase Products</button>
                        </li>

                    </ul>
                </div>
            </div>

            <BoxWrapper>
                <>
                    {activeTab === 'products' && selectedProduct && (
                        <>

                        </>
                    )}
                    {activeTab === 'callHistory' && (
                        <div className="row">

                            <div className="row">
                                <div className="card col-md-5 pt-2 pb-2" style={{ marginRight: 11, minWidth: 495 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <h3>Call History</h3>
                                        <div className="col-md-4">
                                            <button className="btn btn-primary btn-md mb-4" style={customStyles.addCallLogButton} onClick={handleAddNewCallLog}>Add New Call Log</button>
                                        </div>
                                    </div>
                                    {formVisible && (
                                        <form onSubmit={onSubmitHandler} className="p-4 border rounded shadow-sm bg-white mb-4">
                                            <div className="mb-4">
                                                <h5 className="mb-3" style={{ fontWeight: 'bold' }}>Call Response  <span className="required" style={{ color: "red" }}> *</span></h5>

                                                <div className="d-flex flex-wrap">
                                                    {callResponseOptions.map((option) => (
                                                        <div className="form-check me-4 mb-2 col-5" key={option.CallResponsesId}>
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id={`callResponse_${option.CallResponsesId}`}
                                                                name="callResponsesId"
                                                                value={option.CallResponsesId}
                                                                checked={formData.callResponsesId.includes(option.CallResponsesId)}
                                                                onChange={onChangeHandler}
                                                            />
                                                            <label className="form-check-label" htmlFor={`callResponse_${option.CallResponsesId}`}>
                                                                {option.ResponseName}
                                                            </label>

                                                        </div>
                                                    ))}
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-12 col-sm-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="DateToBeVisited" className="form-label">Date To Be Visited</label>
                                                        <input
                                                            type="datetime-local"
                                                            className="form-control"
                                                            id="DateToBeVisited"
                                                            name="DateToBeVisited"
                                                            placeholder="YYYY-MM-DD HH:MM"
                                                            value={formData.DateToBeVisited}
                                                            onChange={onChangeHandler}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="RequestCallBack" className="form-label">Request Call Back</label>
                                                        <input
                                                            type="datetime-local"
                                                            className="form-control"
                                                            id="RequestCallBack"
                                                            name="RequestCallBack"
                                                            placeholder="YYYY-MM-DD HH:MM"
                                                            value={formData.RequestCallBack}
                                                            onChange={onChangeHandler}
                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                                        <textarea
                                                            className="form-control"
                                                            id="remarks"
                                                            name="callLog"
                                                            placeholder="Enter Remarks"
                                                            onChange={onChangeHandler}
                                                            value={formData.callLog}
                                                            rows="4"
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 d-flex justify-content-start">
                                                    <button type="submit" className="btn btn-primary" disabled={!isFormValid}>
                                                        {isEditing ? 'Update' : 'Submit'}
                                                    </button>
                                                    <button className="btn btn-secondary ms-2" type="button" onClick={handleResetForm}>
                                                        Reset
                                                    </button>
                                                    <button className="btn btn-danger ms-2" type="button" onClick={handleBackToView}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>

                                        </form>
                                    )}
                                    {callHistory && callHistory.length > 0 ? (
                                        <div className="card card-action mb-4">
                                            <div className="card-header align-items-center">
                                                <h5 className="card-action-title mb-0">
                                                    <i className="bx bx-list-ul me-2"></i>Call History
                                                </h5>
                                            </div>
                                            <div className="card-body">
                                                <ul className="timeline ms-2">
                                                    {callHistory.map((call, index) => (
                                                        <li key={index} className="timeline-item timeline-item-transparent">
                                                            <span className="timeline-point-wrapper">
                                                                <span className="timeline-point timeline-point-success"></span>
                                                            </span>
                                                            <div className="timeline-event">
                                                                <div className="timeline-header mb-1">
                                                                    <h6 className="mb-0">
                                                                        {call.UserName}
                                                                        <span className="badge bg-label-primary mb-2" style={{ marginLeft: '8px' }} >
                                                                            {call.CallResponseName}
                                                                        </span>
                                                                    </h6>
                                                                    <small className="text-muted">
                                                                        {moment.utc(call.CollectedDate).local().diff(moment(), 'days') <= thresholdDays
                                                                            ? <strong>{moment.utc(call.CollectedDate).local().fromNow()}</strong>
                                                                            : <strong>{moment.utc(call.CollectedDate).local().format('DD-MMM-YYYY HH:mm')}</strong>}
                                                                    </small>
                                                                </div>

                                                                <div className="timeline-header mb-1 mt-1">
                                                                    <h6 className="mb-0">Remarks :</h6>
                                                                </div>
                                                                <p className="mb-0">{call.CallLog}</p>

                                                                {call.DateToBeVisited !== '0001-01-01T00:00:00' && (
                                                                    <>
                                                                        <div className="timeline-header mb-1 mt-1">
                                                                            <h6 className="mb-0">Requested RM to visit on :</h6>
                                                                        </div>
                                                                        <p className="mb-0">{moment.utc(call.DateToBeVisited).local().format('DD-MMM-YYYY HH:mm')}</p>
                                                                    </>
                                                                )}

                                                                {call.RequestCallBack !== '0001-01-01T00:00:00' && (
                                                                    <>
                                                                        <div className="timeline-header mb-1 mt-1">
                                                                            <h6 className="mb-0">Requested Callback on :</h6>
                                                                        </div>
                                                                        <p className="mb-0">{moment.utc(call.RequestCallBack).local().format('DD-MMM-YYYY HH:mm')}</p>
                                                                    </>
                                                                )}

                                                            </div>
                                                        </li>
                                                    ))}
                                                    <li className="timeline-end-indicator">
                                                        <i className="bx bx-check-circle"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="text-danger fw-semibold mb-4 mx-2">
                                            No Call History records
                                        </div>
                                    )}
                                    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                        <Alert onClose={handleSnackbarClose} severity="success">
                                            {snackbarMessage}
                                        </Alert>
                                    </Snackbar>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 'cards' && (
                        <>
                            {cardDetails.status && cardDetails.returnData.length > 0 ? (
                                <div className="card-body position-relative mx-4 my-4">
                                    {cardDetails.returnData[0].IsActivated === null && (
                                        <button type='button' className="btn btn-success mb-3" onClick={() => handleUpdateActivated('Activate')}>Activate Card</button>
                                    )}

                                    {cardDetails.returnData[0].IsActivated === false && (
                                        <button type='button' className="btn btn-success mb-3" onClick={() => handleUpdateActivated('Activate')}>Activate Card</button>
                                    )}

                                    {cardDetails.returnData[0].IsActivated === true && (
                                        <button type='button' className="btn btn-danger mb-3" onClick={() => handleUpdateActivated('Deactivate')}>Deactivate Card</button>
                                    )}

                                    <button type='button' className="btn btn-success mb-3 ms-3" onClick={(e) => handleBookService(e)}>Book Service</button> 
                                    <div className="row">
                                        <img
                                            src={cardDetails.returnData ? `https://ohoindia-mous.s3.ap-south-1.amazonaws.com/40831cda-bf5a-4945-b607-36b65f77ac70.jpg` : "../../assets/img/dummy-avatar.jpg"}
                                            style={{ width: 330, height: 200 }}
                                            alt="User avatar"
                                            className="d-block h-auto ms-0 ms-sm-4 rounded user-profile-img"
                                        />
                                        <img
                                            src={cardDetails.returnData ? `https://ohoindia-mous.s3.ap-south-1.amazonaws.com/3b56a6e5-41ca-4049-a882-02a3d14e1d78.jpg` : "../../assets/img/dummy-avatar.jpg"}
                                            style={{ width: 330, height: 200 }}
                                            alt="User avatar"
                                            className="d-block h-auto ms-0 ms-sm-4 rounded user-profile-img"
                                        />
                                        <p className="position-absolute" style={{ top: "195px", left: "41px", fontSize: '1rem', color: 'white', textShadow: '1px 1px 2px black' }}>
                                            {formatCardNumber(cardDetails.returnData[0]?.OHOCardnumber)}
                                        </p>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-xl-12 col-lg-12">
                                            <div className="card-body d-flex flex-row overflow-auto mb-2 mx-2">
                                                {products && products.length > 0 ? (
                                                    products.map((product, index) => (
                                                        (product.ProductName && product.IssuedOn && product.ValidTill) ? (
                                                            <div key={index} className="col-md-4 mb-4">
                                                                <div
                                                                    className="card me-3 shadow-sm border-0"
                                                                    onClick={() => handleProductClick(product)}
                                                                    style={{ cursor: "pointer", borderRadius: "8px" }}
                                                                >
                                                                    <div className="card-body">
                                                                        {/* Product Name and Category */}
                                                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                                                            {product.ProductName && (
                                                                                <h6 className="fw-bold mb-0" style={{ fontSize: "1.1rem" }}>
                                                                                    {product.ProductName}
                                                                                </h6>
                                                                            )}
                                                                            {product.ProductCategoryName && (
                                                                                <span className="badge bg-label-primary">
                                                                                    {product.ProductCategoryName}
                                                                                </span>
                                                                            )}
                                                                        </div>

                                                                        <hr className="mb-3" />


                                                                        <ul className="list-unstyled d-flex justify-content-between align-items-start">
                                                                            {product.IssuedOn && product.ValidTill && (
                                                                                <li className="mb-3">
                                                                                    <div className="d-flex flex-column">
                                                                                        <h6 className="fw-bold mb-1">Validity:</h6>
                                                                                        <span>
                                                                                            {convertDOBFormat(product.IssuedOn)} to {convertDOBFormat(product.ValidTill)}
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                            {product.IsCombo !== null && (
                                                                                <li className="mb-3">
                                                                                    <span className={`fw-bold badge ${product.IsCombo ? 'bg-primary' : 'bg-success'}`}>
                                                                                        {product.IsCombo ? 'Combo Product' : 'Individual Product'}
                                                                                    </span>
                                                                                </li>
                                                                            )}
                                                                        </ul>

                                                                        {product.IsVerified !== null && (
                                                                            <div className={`alert ${product.IsVerified ? 'alert-success' : 'alert-danger'} mt-3`} role="alert">
                                                                                {product.IsVerified ? 'Product verification successful' : product.verificationMessage || 'Product not verified'}
                                                                            </div>
                                                                        )}

                                                                        <button
                                                                            className="btn btn-outline-primary mt-3 w-100"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation(); // Prevent card click
                                                                                handleProductClick(product);
                                                                            }}
                                                                        >
                                                                            Add Family Members
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    ))
                                                ) : (
                                                    <div className="text-danger fw-semibold mb-4 mx-2 text-center">
                                                        Sorry, You haven't purchased any products.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : cardDetails.status === false ? (
                                <div className="text-danger fw-semibold text-center mb-4 mx-2">{cardDetails.message}</div>
                            ) : (
                                <div className="text-danger fw-semibold text-center mb-4 mx-2">Sorry, You haven't purchased any products.</div>
                            )}
                        </>
                    )}

                    {activeTab === 'products' && selectedProduct && (
                        <>
                            {selectedProduct ? (
                                <>
                                    {selectedProduct.PolicyDocument === null ? (
                                        <div className="d-flex flex-row justify-content-between mb-3">
                                            <form className="form-group custom-form" onSubmit={handleFileSubmit}>
                                                <h5>Upload Policy Document <span className="text-danger">*</span></h5>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    required
                                                    onChange={(e) => handleUploadChange(e)}
                                                />
                                                <button
                                                    type="submit"
                                                    className="btn btn-success mt-2"
                                                    disabled={!file}>
                                                    UPLOAD
                                                </button>
                                            </form>

                                            <div className='d-flex flex-row'>
                                                <button className="btn bg-primary text-white h-25" onClick={handlePrint}>
                                                    Print Address
                                                </button>
                                                <button
                                                    className="btn btn-secondary text-white h-25 ms-2"
                                                    onClick={handleGenerateMembership}
                                                    style={{ minWidth: '150px', minHeight: '25px' }}
                                                >
                                                    {loading ? (
                                                        <div className="spinner-border text-white" role="status" style={{ width: '1rem', height: '1rem' }}>
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <i className="bi bi-download me-2"></i>
                                                            Download MOC
                                                        </>
                                                    )}
                                                </button>
                                                <button
                                                    className="btn btn-primary text-white h-25 ms-2"
                                                    onClick={handleGenerateMembership}
                                                    style={{ minWidth: '150px', minHeight: '25px' }}
                                                >
                                                    {loading ? (
                                                        <div className="spinner-border text-white" role="status" style={{ width: '1rem', height: '1rem' }}>
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <i className="bi bi-file-earmark-plus me-2"></i>
                                                            Generate MOC
                                                        </>
                                                    )}
                                                </button>

                                            </div>
                                        </div>
                                    ) : (
                                        <div className="d-flex flex-row mb-2">
                                            <button className="btn bg-primary">
                                                <a
                                                    href={`${process.env.REACT_APP_POLICY_DOC}${selectedProduct.PolicyDocument}`}
                                                    className="text-white"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    download>
                                                    Download Policy
                                                </a>
                                            </button>
                                            <button className="btn bg-primary text-white h-25 ms-2" onClick={handlePrint}>
                                                Print Address
                                            </button>
                                            <button className="btn bg-primary text-white h-25 ms-2" onClick={handleGenerateMembership}>
                                                Generate Membership
                                            </button>
                                        </div>
                                    )}
                                    <div className="col-xl-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                        <div className="card-body d-flex flex-wrap overflow-auto" style={{ width: "50%" }}>
                                            <div
                                                className="card card-custom"
                                                style={{
                                                    borderRadius: "12px",
                                                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                                                    padding: "16px",
                                                    backgroundColor: "#ffffff",
                                                    width: "50%", // Ensures it fills the defined width of the parent div
                                                }}
                                            >
                                                <div className="card-body card-body-custom">
                                                    <dl className="row mb-0">
                                                        {selectedProduct.ProductName && (
                                                            <>
                                                                <dt className="col-4 fw-bold text-primary" style={{ fontSize: "14px" }}>
                                                                    <i className="bi bi-box-seam me-1"></i>Product Name:
                                                                </dt>
                                                                <dd className="col-8 text-dark" style={{ fontSize: "16px", fontWeight: "500" }}>
                                                                    {selectedProduct.ProductName}
                                                                </dd>
                                                            </>
                                                        )}

                                                        {selectedProduct.PaidAmount && (
                                                            <>
                                                                <dt className="col-4 fw-bold text-primary" style={{ fontSize: "14px" }}>
                                                                    <i className="bi bi-currency-rupee me-1"></i>Price:
                                                                </dt>
                                                                <dd className="col-8 text-success" style={{ fontSize: "16px", fontWeight: "500" }}>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        style: 'currency',
                                                                        currency: 'INR',
                                                                    }).format(selectedProduct.PaidAmount)}
                                                                </dd>
                                                            </>
                                                        )}

                                                        <>
                                                            <dt className="col-4 fw-bold text-primary" style={{ fontSize: "14px" }}>
                                                                <i className="bi bi-credit-card me-1"></i>UTR Number:
                                                            </dt>
                                                            <dd className="col-8" style={{ fontSize: "16px", fontWeight: "500" }}>
                                                                {selectedProduct.UTRNumber && selectedProduct.UTRNumber.length > 0 ? (
                                                                    <span>{selectedProduct.UTRNumber}</span>
                                                                ) : (<span className="text-danger">UTR Number does't exist</span>)}
                                                            </dd>
                                                        </>

                                                        {selectedProduct.KeyFeatures && (
                                                            <>
                                                                <dt className="col-4 fw-bold text-primary" style={{ fontSize: "14px" }}>
                                                                    <i className="bi bi-lightbulb me-1"></i>Key Features:
                                                                </dt>
                                                                <dd className="col-8" style={{ color: "#555" }}>
                                                                    <ul className="list-unstyled">
                                                                        {formatKeyFeatures(selectedProduct.KeyFeatures).map((feature, index) => (
                                                                            <li key={index} className="d-flex align-items-center mb-1" style={{ fontSize: "14px" }}>
                                                                                <i className="bi bi-check-circle-fill text-success me-2"></i>
                                                                                {feature.trim()}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </dd>
                                                            </>
                                                        )}
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="text-danger fw-semibold mb-4 mx-2">No products purchased.</div>
                            )}



                            {showNominee && familyMembersLength < 3 && (
                                <BoxWrapper>
                                    {selectedProduct.IsCombo
                                        ? comboProductDetails.map((product, index) => (
                                            <SelectNominee key={index} selectedProduct={product} />
                                        ))
                                        : <SelectNominee selectedProduct={selectedProduct} />
                                    }
                                </BoxWrapper>
                            )}

                            {isEditing && selectedProduct && (
                                <>
                                    {selectedProduct.IsCombo
                                        ? comboProductDetails.map((product) => (
                                            <CardEditForms
                                                key={product.ProductsId} // Unique key for each CardEditForms component
                                                selectedProductId={product.ProductsId}
                                            />
                                        ))
                                        : <CardEditForms selectedProductId={selectedProduct.ProductsId} />
                                    }
                                </>
                            )}

                        </>
                    )}

                    {activeTab === 'purchaseProducts' && (
                        <>
                            {purchaseProduct ? (
                                <>
                                    {/* OHO Card Number Input Card */}
                                    {purchaseProduct.IsCombo === true && !cardNumberEntered && (
                                        <div className="card p-3" style={{ minWidth: "400px" }}>
                                            <label className="form-label" htmlFor="cardNumber" style={{ fontSize: "15px" }}>
                                                Card Number <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="cardNumber"
                                                className="form-control"
                                                placeholder="2804 0000 0001"
                                                value={cardNumber}
                                                onChange={handleCardNumberChange}
                                                maxLength={14}
                                            />
                                            {cardNumberError && <p className="text-danger">{cardNumberError}</p>}
                                            <button
                                                className="btn btn-primary mt-3"
                                                onClick={checkCardNumberExists} // Check if card number exists on click
                                            >
                                                Confirm Card Number
                                            </button>
                                        </div>
                                    )}

                                    {/* Main Product Details Card */}
                                    {(!purchaseProduct.IsCombo || cardNumberEntered) && (
                                        <div className="card p-3 d-flex flex-column align-items-center">
                                            <h5 className="card-header">
                                                <strong>Product Name: </strong>
                                                <span className="text-primary fs-4 fw-bold">{purchaseProduct.ProductName}</span>
                                            </h5>
                                            <img
                                                src="https://ohoindia-mous.s3.ap-south-1.amazonaws.com/70ec0ebd-a761-4dc4-acb8-80750653a421.jpg"
                                                alt="QR Code"
                                                className="h-25 w-25 mt-3 mb-3"
                                            />
                                            <p className="fs-5">
                                                Amount to pay: <FontAwesomeIcon icon={faIndianRupeeSign} className="text-primary" />{" "}
                                                <span>{purchaseProduct.InsurancePremiums[0].TotalAmount}</span>
                                            </p>

                                            <div style={{ minWidth: "400px" }}>
                                                <label className="form-label" htmlFor="utrnumber" style={{ fontSize: "15px" }}>
                                                    UTR Number <span className="text-danger"> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="utrnumber"
                                                    className="form-control"
                                                    placeholder="Enter UTR Number"
                                                    value={utrNumber}
                                                    onChange={(e) => onChangeUtrNumber(e)}
                                                />
                                                {utrError && <p className="text-danger">*Please Enter UTR Number</p>}
                                            </div>

                                            <div className="mt-3 d-flex flex-row justify-content-center">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary me-sm-3 me-1"
                                                    onClick={(e) => submitUtrForm(e)}
                                                    style={{minWidth: '100px'}}
                                                    disabled={utrSubmitLoad}
                                                >
                                                    {utrSubmitLoad ? (
                                                        <div class="spinner-border text-white" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    ) : 'Submit' }                                                    
                                                </button>
                                                <button type="button" className="btn btn-label-secondary" onClick={() => setPurchaseProduct()}>
                                                    Cancel
                                                </button>
                                            </div>

                                            {paymentSuccessMsg && <p className="text-success mt-3">Successfully purchased product</p>}
                                            {memberPrdAddFail && memberPrdAddFail.length > 0 && <p className="text-danger mt-3">{memberPrdAddFail}</p>}
                                            {paymentFailureError && paymentFailureError.length > 0 && <p className="text-danger mt-3">{paymentFailureError}</p>}
                                            {updatePaymentForProductError && updatePaymentForProductError.length > 0 && <p className="text-danger mt-3">{updatePaymentForProductError}</p>}
                                            {cardAddError && cardAddError.length > 0 && <p className="text-danger mt-3">{cardAddError}</p>}
                                        </div>
                                    )}
                                </>
                            ) : (
                                availableProducts && availableProducts.length > 0 ? (
                                    <div className="container-fluid">
                                        <div className="row">
                                            {availableProducts.map(product => (
                                                <div key={product.ProductsId} className="card d-flex flex-column col-md-5 p-3 mt-2 mb-2 me-3">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <i className="fa-solid fa-circle-check me-2" style={{ color: '#349bc7' }}></i>
                                                        <h5 className="mb-1 text-dark">{product.ProductName}</h5>
                                                    </div>
                                                    <p className="text-secondary" style={{ fontSize: '14px' }}>{product.ProductCategoryName}</p>

                                                    <div className="d-flex flex-row align-items-center">
                                                        <h6>Amount: </h6>
                                                        <p className="ms-1"><i className="fa-solid fa-indian-rupee-sign" style={{ color: '#349bc7' }}></i> {product.SaleAmount}</p>
                                                    </div>
                                                    <button className="btn btn-primary align-self-end" onClick={() => setPurchaseProduct(product)}>Purchase</button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <h4 className="text-danger fw-semibold text-center">No Products Available</h4>
                                    </div>
                                )
                            )}

                        </>
                    )}

                </>
            </BoxWrapper>
            <TableContainer component={Paper}>
                <ConfirmationDialogDelete
                    open={confirmationOpen}
                    title={confirmationData.title}
                    message={confirmationData.message}
                    onConfirm={confirmationData.onConfirm}
                    onCancel={() => setConfirmationOpen(false)}
                />
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert onClose={handleSnackbarClose} severity="success">
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </TableContainer>
        </>
    );
}