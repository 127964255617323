import React from 'react';
import 'regenerator-runtime/runtime';
import { Outlet } from "react-router-dom";
import CardAssign from './CardAssign';
import Layout from '../../Layout/Layout';

export default function RMCardAssign() {

    return (
        <Layout>
            <div>
                <div className="text-center mt-2">
                    <div className="row align-items-center">
                        <div className="col-md-9">
                        </div>

                        <div className="col-md-3">
                            <h5 className="fw-bold mb-0 text-md-end">
                                RM Cards Management
                            </h5>
                        </div>

                    </div>

                </div>

                <div id="detail">
                    <CardAssign />
                </div>
            </div>
        </Layout>
    )
}
