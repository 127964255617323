import React, { useEffect, useState } from 'react';
import 'regenerator-runtime/runtime';
import { Link, Outlet, useLocation } from "react-router-dom";
import Layout from '../../Layout/Layout';
import PreviousList from './PreviousList';
import UpcommingList from './UpcommingList';
import BookConsultation from './BookConsultation';

export default function Consultation() {
    const location = useLocation();
    const [navPath, setNavPath] = useState('');

    useEffect(() => {
        const path = location.pathname;

        if (path.includes('/BookConsultation/upcomming')) {
            setNavPath('upcoming');
        } else if (path.includes('/BookConsultation/previous')) {
            setNavPath('previous');
        } else {
            setNavPath('book');
        }
    }, []);

    console.log("navpath", navPath);

    return (
        <Layout>
            <div className=" text-center bg-white p-2 my-1">
                <div className="row align-items-center">
                    <div className="col-md-9">
                        <ul className="nav nav-md nav-pills">
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${navPath === "previous" ? "active" : ""}`}
                                    to={`/Others/BookConsultation/previous`}
                                    onClick={() => setNavPath('previous')}
                                >
                                    <i className="bx bx-user me-1"></i>&nbsp; Previous Appointments
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${navPath === "upcoming" ? "active" : ""}`}
                                    to={`/Others/BookConsultation/upcoming`}
                                    onClick={() => setNavPath('upcoming')}
                                >
                                    <i className="bx bx-user me-1"></i>&nbsp;Upcomming Appointments
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${navPath === "book" ? "active" : ""}`}
                                    to={`/Others/BookConsultation/book`}
                                    onClick={() => setNavPath('book')}
                                >
                                    <i className="bx bx-user me-1"></i>&nbsp;Book Appointment
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="detail">
                {navPath === "previous" ? (
                    <PreviousList />
                ) : navPath === "upcoming" ? (
                    <UpcommingList />
                ) : (
                    <BookConsultation />
                )}                
            </div>
        </Layout>
    )
}