import React, { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAllData ,fetchData } from '../../helpers/externapi';

const BookConsultation = () => {
    const [mobileNumber, setMobileNumber] = useState("");
    const [isVerified, setIsVerified] = useState(false);
    const [formData, setFormData] = useState({
        FullName: '', MobileNumber: '', Cardnumber: '', Gender: '', DateofBirth: '', Age: '', Address: '',
        DateAndTime: '', HospitalName: '', Branch: '', DoctorName: '', ServiceType: '', Appointment: '', DiscountPercentage: 0.0, 
        ConsultationFee: 0   
    });
    const [formErrors, setFormErrors] = useState({ DateAndTime: '', HospitalName: '', Branch: '', ServiceType: '', Appointment: '' });
    const [eligibilityMessage, setEligibilityMessage] = useState();
    const [formSuccessMessage, setFormSuccessMessage] = useState();
    const [isDiscountedPercentVisible, setIsDiscountedPercentVisible] = useState(false);
    const [isFeeconsulationVisible, setIsFeeConsultationVisible] = useState(false);
    const [memberDetails, setMemberDetails] = useState();
    const [dependents, setDependents] = useState();
    const [verifiedMessage, setVerifiedMessage] = useState();

    const location = useLocation();
    const { memberId: initialMemberId } = location.state || '';
    const [memberId, setMemberId] = useState(initialMemberId);

    console.log("MEM ID: ", memberId);

    const navigate = useNavigate();

    useEffect(() => {
        if (memberId) {
            setIsVerified(true);
        }
    }, [memberId]);

    useEffect(() => {
        const fetchMemberDetails = async () => {
            const responseMemberDetails = await fetchAllData(`OHOCards/GetMemberDetailsId/${memberId}`);
            console.log("reponse", responseMemberDetails);
            setMemberDetails(responseMemberDetails);

            responseMemberDetails && responseMemberDetails.length > 0 && (
                setFormData((preVal) => ({
                    ...preVal, FullName: responseMemberDetails[0].FullName, MobileNumber: responseMemberDetails[0].MobileNumber, Cardnumber: responseMemberDetails[0].OHOCardNumber,
                    Gender: responseMemberDetails[0].Gender, DateofBirth: formatDate(responseMemberDetails[0].DateofBirth), Age: calculateAge(responseMemberDetails[0].DateofBirth),
                    Address: responseMemberDetails[0].AddressLine1
                }))
            )
        }

        const fetchDependents = async () => {
            const responseDependents = await fetchAllData(`MemberDependent/GetByMemberId/${memberId}`);
            console.log("family", responseDependents);

            setDependents(responseDependents);
        }



        if (memberId) {
            fetchMemberDetails();
            fetchDependents();
        }
    }, [memberId]);


    const handleInputChange = (e) => {
        const value = e.target.value;
        // Allow only up to 12 digits
        if (value.length <= 12 && /^[0-9]*$/.test(value)) {
            setMobileNumber(value);
        }
    };

    const formatCardNumber = (number) => {
        return number.replace(/\s+/g, '').replace(/(.{4})(?=.{4})/g, '$1 ');
    };

    const handleVerify = async (e) => {
        e.preventDefault();

        // Determine if the input is a mobile number or card number
        const isCardNumber = mobileNumber.length === 12;

        const formattedCardNumber = isCardNumber ? formatCardNumber(mobileNumber) : mobileNumber;

        // Create payload based on input type
        const payload = {
            mobileNumber: isCardNumber ? "" : mobileNumber,
            cardNumber: isCardNumber ? formattedCardNumber : ""
        };

        try {

            console.log("Payload", payload);
            const response = await fetchData(`OHOCards/GetMemberDetailsByCardNumberuorMobileNo`, payload);
            console.log("reponseVirify", response);
            

            if (response.status === true) {
                console.log('Verification successful:', response.message);
                setMemberId(response.memberId);
                setVerifiedMessage(response.message)
                setIsVerified(true);
            } else {
                setVerifiedMessage(response.message)
            }
        } catch (error) {
            console.error('Error during verification:', error);
            alert('An error occurred while verifying. Please try again.');
        }
    };

    function calculateAge(dateOfBirth) {
        const dob = new Date(dateOfBirth);
        const today = new Date();

        let age = today.getFullYear() - dob.getFullYear();
        const monthDifference = today.getMonth() - dob.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
            age--;
        }

        return age;
    }

    const formatDate = (dobString) => {
        const date = new Date(dobString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };

        return date.toLocaleDateString('en-US', options);
    };

    const formateDatabaseDatetime = (inp) => {
        const date = new Date(inp);
    
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
    
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }

    const onChangeDateTime = (e) => {
        const dateStr = e.toString();
    
        const date = formateDatabaseDatetime(dateStr.slice(0, 24));
    
        if (date.length > 0) {
            setFormErrors(preVal => ({
                ...preVal, DateAndTime: ''
            }))
        }
    
        setFormData(preVal => ({
            ...preVal, DateAndTime: date
        }))
    };

    console.log("formData", formData);

    const onChangeHandler = (e) => {

        if (e.target.name === 'Appointment') {

            if (e.target.id === 'DiscountedConsultation' || e.target.id === 'DiscountedPharmacy' || e.target.id === 'DiscountedInvestigation') {
                setIsFeeConsultationVisible(true);
                setIsDiscountedPercentVisible(true);
            } else {
                setIsFeeConsultationVisible(false);
                setIsDiscountedPercentVisible(false);
            }

            setFormData(preVal => ({
                ...preVal, [e.target.name]: e.target.id
            }))
        } else if (e.target.name === 'FullName') {

            memberDetails[0].FullName === e.target.value ? (
                setFormData((preVal) => ({
                    ...preVal, FullName: memberDetails[0].FullName, MobileNumber: memberDetails[0].MobileNumber, Cardnumber: memberDetails[0].OHOCardNumber,
                    Gender: memberDetails[0].Gender, DateofBirth:(memberDetails[0].DateofBirth), Age: calculateAge(memberDetails[0].DateofBirth),
                    Address: memberDetails[0].AddressLine1
                }))
            ) : dependents.map(each => (
                each.fullName === e.target.value && (
                    setFormData((preVal) => ({
                        ...preVal, FullName: each.fullName, Gender: each.gender, DateofBirth:(each.dateofBirth), Age: calculateAge(each.dateofBirth),
                    }))
                )
            ))

            setFormData(preVal => ({
                ...preVal, [e.target.name]: e.target.value
            }))           

        } else {
            setFormData(preVal => ({
                ...preVal, [e.target.name]: e.target.value
            }))
            if (e.target.value.length > 0) {
                setFormErrors(preVal => ({
                    ...preVal, [e.target.name]: ''
                }))
            }
        }        
    };

    const checkErrors = () => {
        if (formData.DateAndTime === '' || formData.HospitalName.length < 2 || formData.Branch.length < 2 ||
            formData.ServiceType.length < 2 || formData.Appointment === '') {

            if (formData.DateAndTime === '') {
                setFormErrors(preVal => ({
                    ...preVal, DateAndTime: 'Please select appointment date & time *'
                }))
            }
            if (formData.HospitalName.length < 2) {
                setFormErrors(preVal => ({
                    ...preVal, HospitalName: 'Please Enter valid hospital name *'
                }))
            }
            if (formData.Branch.length < 2) {
                setFormErrors(preVal => ({
                    ...preVal, Branch: 'Please Enter valid branch name *'
                }))
            }
            if (formData.ServiceType.length < 2) {
                setFormErrors(preVal => ({
                    ...preVal, ServiceType: 'Please Enter servicetype *'
                }))
            }
            if (formData.Appointment === '') {
                setFormErrors(preVal => ({
                    ...preVal, Appointment: 'Please select appoointment type *'
                }))
            }
        } else {
            return true;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const noError = checkErrors();

        if (noError) {
            const payload = {
                name: formData.FullName,
                mobileNumber: formData.MobileNumber,
                cardNumber: formData.Cardnumber,
                gender: formData.Gender,
                dateofBirth: formateDatabaseDatetime(formData.DateofBirth),
                age: formData.Age,
                appointmentDate: formData.DateAndTime,
                address: formData.Address,
                hospitalName: formData.HospitalName,
                branch: formData.Branch,
                serviceType: formData.ServiceType,
                consultationFee: formData.ConsultationFee,
                memberId: memberId,
                doctorName: formData.DoctorName,
                discountinPercentage: formData.DiscountPercentage,
                appointment: formData.Appointment
            }
            console.log("payload", payload);
            const responseEligible = await fetchData(`BookingConsultation/bookAppointment/add`, { ...payload });
            console.log("submitResponse", responseEligible);

            if (responseEligible.status) {
                setFormSuccessMessage(responseEligible.message);
                setEligibilityMessage('');

                setFormErrors({
                    DateAndTime: '', HospitalName: '', Branch: '', ServiceType: '', Appointment: ''
                });

                setTimeout(() => {
                    setFormData(preVal => ({
                        ...preVal, DateAndTime: '', HospitalName: '', Branch: '', DoctorName: '', ServiceType: '', Appointment: '',
                        DiscountPercentage: '', ConsultationFee: ''
                    }));

                    setEligibilityMessage('');
                    setFormSuccessMessage('');
                    //navigate('/Others/BookConsultation/upcoming');
                    window.location.reload();
                }, 3000);
            } else {
                setEligibilityMessage(responseEligible.message);
                setFormSuccessMessage('');
            }

        } else {
            setEligibilityMessage('');
            setFormSuccessMessage('');
        }

       
    };

    const handleReset = (e) => {
        e.preventDefault();

        setFormData(preVal => ({
            ...preVal, DateAndTime: '', HospitalName: '', Branch: '', DoctorName: '', ServiceType: '', Appointment: '',
            DiscountPercentage: '', ConsultationFee: ''
        }));

        setFormErrors({
            DateAndTime: '', HospitalName: '', Branch: '', ServiceType: '', Appointment: ''
        })
    };

    const handleCancel = () => {
        setFormData(preVal => ({
            ...preVal, DateAndTime: '', HospitalName: '', Branch: '', DoctorName: '', ServiceType: '', Appointment: '',
            DiscountPercentage: '', ConsultationFee: ''
        }));
        setFormErrors('');
        setEligibilityMessage('');
        setFormSuccessMessage('');
        setFormErrors({
            DateAndTime: '', HospitalName: '', Branch: '', ServiceType: '', Appointment: ''
        })
    };

    const returnForm = () => (
        <div className='card p-3'>
            <form>
                <div className="row">

                    <div className="d-flex flex-column col-12 col-md-4 mb-3">
                        <label htmlFor="FullName" className="form-select-label">
                            Name<span className="text-danger"> *</span>
                        </label>
                        <select
                            name="FullName"
                            id="FullName"
                            className="form-select"
                            value={formData.FullName}
                            onChange={(e) => onChangeHandler(e)}
                        >
                            {/* First option from memberDetails */}
                            {memberDetails && memberDetails.length > 0 && (
                                <option value={memberDetails[0].FullName}>{memberDetails[0].FullName}</option>
                            )}

                            {/* Options from dependents */}
                            {dependents && dependents.length > 0 && dependents.map((dependent, index) => (
                                <option key={index} value={dependent.fullName}>{dependent.fullName}</option>
                            ))}
                        </select>
                    </div>

                    {/*<div className="d-flex flex-column col-12 col-md-4 mb-3">*/}
                    {/*    <label htmlFor="MobileNumber" className="form-control-label">*/}
                    {/*        Mobile Number<span className="text-danger"> *</span>*/}
                    {/*    </label>*/}
                    {/*    <input name="MobileNumber" id="MobileNumber" type="tel" maxLength="10" className="form-control"*/}
                    {/*        placeholder="Enter Mobile Number" readOnly value={`${formData.MobileNumber.slice(0,4)}XXXXXX`} />*/}
                    {/*</div>*/}

                    {/*<div className="d-flex flex-column col-12 col-md-4 mb-3">*/}
                    {/*    <label htmlFor="Cardnumber" className="form-control-label">*/}
                    {/*        Card Number<span className="text-danger"> *</span>*/}
                    {/*    </label>*/}
                    {/*    <input name="Cardnumber" id="Cardnumber" className="form-control"*/}
                    {/*        placeholder="Enter Card Number" readOnly value={`${formData.Cardnumber.slice(0, 3)}X XXXX X${formData.Cardnumber.slice(10, 13)}`}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    {/*<div className="d-flex flex-column col-12 col-md-4 mb-3">*/}
                    {/*    <label className="form-control-label">*/}
                    {/*        Gender<span className="text-danger"> *</span>*/}
                    {/*    </label>*/}
                    {/*    <div className="d-flex flex-row">*/}
                    {/*        <div className="form-check me-3">*/}
                    {/*            <input className="form-check-input" type="radio" name="Gender" id="Male" readOnly checked={formData.Gender === 'Male'} />*/}
                    {/*            <label className="form-check-label" htmlFor="Male">Male</label>*/}
                    {/*        </div>*/}
                    {/*        <div className="form-check me-3">*/}
                    {/*            <input className="form-check-input" type="radio" name="Gender" id="Female" readOnly checked={formData.Gender === 'Female'} />*/}
                    {/*            <label className="form-check-label" htmlFor="Female">Felame</label>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="d-flex flex-column col-12 col-md-4 mb-3">*/}
                    {/*    <label htmlFor="flatpickr-human-friendly" className="form-control-label">*/}
                    {/*        DateofBirth<span className="text-danger"> *</span>*/}
                    {/*    </label>*/}
                    {/*    <input type="text" className="form-control flatpickr-input" placeholder="Month DD, YYYY"*/}
                    {/*        id="flatpickr-human-friendly" name="DateofBirth" disabled="" value={formData.DateofBirth} readOnly*/}
                    {/*    />*/}
                    {/*</div>*/}

                    {/*<div className="d-flex flex-column col-12 col-md-4 mb-3">*/}
                    {/*    <label className="form-control-label">*/}
                    {/*        Age<span className="text-danger"> *</span>*/}
                    {/*    </label>*/}
                    {/*    <input type="text" name="Age" className="form-control" placeholder="Enter your age" readOnly value={formData.Age} />*/}
                    {/*</div>*/}

                    {/*<div className="d-flex flex-column col-12 col-md-4 mb-3">*/}
                    {/*    <label className="form-control-label">*/}
                    {/*        Address<span className="text-danger"> *</span>*/}
                    {/*    </label>*/}
                    {/*    <input type="text" name="Address" className="form-control" placeholder="Enter your Address" readOnly value={formData.Address} />*/}
                    {/*</div>*/}

                    <div className="d-flex flex-column col-12 col-md-4 mb-3">
                        <label htmlFor="flatpickr-datetime" className="form-control-label">
                            Consultation Date &amp; Time<span className="text-danger"> *</span>
                        </label>
                        <Flatpickr
                            className="form-control"
                            placeholder="YYYY-MM-DD HH:MM"
                            id="flatpickr-datetime"
                            name="DateAndTime"
                            value={formData.DateAndTime}
                            onChange={(e) => onChangeDateTime(e)}
                            options={{
                                enableTime: true,
                                dateFormat: "Y-m-d H:i",
                                time_24hr: false,
                                minDate: new Date()
                            }}
                        />
                        {formErrors && formErrors.DateAndTime.length > 0 && <p className='text-danger'>{formErrors.DateAndTime}</p>}
                    </div>

                    <div className="d-flex flex-column col-12 col-md-4 mb-3">
                        <label className="form-control-label">
                            Hospital Name<span className="text-danger"> *</span>
                        </label>
                        <input type="text" name="HospitalName" className="form-control" placeholder="Enter Hospital Name"
                            value={formData.HospitalName}
                            onChange={(e) => onChangeHandler(e)}
                        />
                        {formErrors && formErrors.HospitalName.length > 0 && <p className='text-danger'>{formErrors.HospitalName}</p>}
                    </div>

                    <div className="d-flex flex-column col-12 col-md-4 mb-3">
                        <label className="form-control-label">
                            Branch<span className="text-danger"> *</span>
                        </label>
                        <input type="text" name="Branch" className="form-control" placeholder="Enter Branch Name"
                            value={formData.Branch}
                            onChange={(e) => onChangeHandler(e)} 
                        />
                        {formErrors && formErrors.Branch.length > 0 && <p className='text-danger'>{formErrors.Branch}</p>}
                    </div>

                    <div className="d-flex flex-column col-12 col-md-4 mb-3">
                        <label className="form-control-label">Doctor Name</label>
                        <input type="text" name="DoctorName" className="form-control" placeholder="Enter Doctor Name"
                            value={formData.DoctorName}
                            onChange={(e) => onChangeHandler(e)} 
                        />
                    </div>

                    <div className="d-flex flex-column col-12 col-md-4 mb-3">
                        <label className="form-control-label">
                            Servive Type<span className="text-danger"> *</span>
                        </label>
                        <input type="text" name="ServiceType" className="form-control" placeholder="Ex: Orthopedic"
                            value={formData.ServiceType}
                            onChange={(e) => onChangeHandler(e)} 
                        />
                        {formErrors && formErrors.ServiceType.length > 0 && <p className='text-danger'>{formErrors.ServiceType}</p>}
                    </div>

                    <div className="d-flex flex-column col-12 col-md-4 mb-3">
                        <label className="form-control-label">
                            Appointment<span className="text-danger"> *</span>
                        </label>
                        <div className="d-flex flex-column">
                            <div className="form-check me-3">
                                <input className="form-check-input" name="Appointment" type="radio" id="FreeConsultation"
                                    checked={formData.Appointment === 'FreeConsultation'}
                                    onChange={(e) => onChangeHandler(e)} 
                                />
                                <label className="form-check-label" htmlFor="FreeConsultation">Free Consultation</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" name="Appointment" type="radio" id="DiscountedConsultation"
                                    checked={formData.Appointment === 'DiscountedConsultation'}
                                    onChange={(e) => onChangeHandler(e)} 
                                />
                                <label className="form-check-label" htmlFor="DiscountedConsultation">Discounted Consultation</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" name="Appointment" type="radio" id="DiscountedPharmacy"
                                    checked={formData.Appointment === 'DiscountedPharmacy'}
                                    onChange={(e) => onChangeHandler(e)} 
                                />
                                <label className="form-check-label" htmlFor="DiscountedPharmacy">Discounted Pharmacy</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" name="Appointment" type="radio" id="DiscountedInvestigation"
                                    checked={formData.Appointment === 'DiscountedInvestigation'}
                                    onChange={(e) => onChangeHandler(e)} 
                                />
                                <label className="form-check-label" htmlFor="DiscountedInvestigation">Discounted Investigation</label>
                            </div>
                        </div>
                        {formErrors && formErrors.Appointment.length > 0 && <p className='text-danger'>{formErrors.Appointment}</p>}
                    </div>

                    {isDiscountedPercentVisible && (
                        <div className="d-flex flex-column col-12 col-md-4 mb-3">
                            <label className="form-control-label">Discount Percentage %</label>
                            <input type="number" min="0" max="100" name="DiscountPercentage" className="form-control"
                                placeholder="Enter Discount percentage" value={formData.DiscountPercentage}
                                onChange={(e) => onChangeHandler(e)} 
                            />
                        </div>
                    )}
                    {isFeeconsulationVisible && (
                        <div className="d-flex flex-column col-12 col-md-4 mb-3">
                            <label className="form-control-label">Consultation Fee (Rs)</label>
                            <input type="number" min="0" max="100000" name="ConsultationFee" className="form-control"
                                placeholder="Enter Consultation Fee" value={formData.ConsultationFee}
                                onChange={(e) => onChangeHandler(e)}
                            />
                        </div>
                    )}

                </div>
                <div className="text-center">
                    <button type="button" className="btn btn-secondary me-1" onClick={(e) => handleCancel(e)}>Cancel</button>
                    <button type="button" className="btn btn-danger me-1" onClick={(e) => handleReset(e)}>Reset</button>
                    <button type="submit" className="btn btn-success" onClick={(e) => handleSubmit(e)}>Submit</button>
                </div>
                {formSuccessMessage && formSuccessMessage.length > 0 && <p className='text-success text-center'>{formSuccessMessage}</p>}
                {eligibilityMessage && eligibilityMessage.length > 0 && <p className='text-danger text-center'>{eligibilityMessage}</p>}
            </form>
        </div>
    );

    return (
        isVerified ? (returnForm()) : (
            <div className='card p-2'>
                <div className='col-12 col-md-6'>
                    <label htmlFor="mobileNumber" className="form-label">
                        Mobile Number/OHOCard Number<span className="text-danger"> *</span>
                    </label>

                    <div className='d-flex flex-row align-items-center'>
                        <input
                            type="text"
                            className="form-control p-0 me-3 mb-3"
                            maxLength="12"
                            id="mobileNumber"
                            value={mobileNumber}
                            onChange={handleInputChange}
                            style={{ minHeight: '40px' }}
                        />
                        <button
                            type="button"
                            className="btn btn-primary mb-3"
                            onClick={handleVerify}
                        >
                            Verify
                        </button>
                    </div>
                </div>
                {verifiedMessage && verifiedMessage.length > 0 && <p className='text-danger text-center'>{verifiedMessage}</p>}
            </div>
        )
    )
};

export default BookConsultation;