import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BoxWrapper from "../../Components/BoxWrapper";
import moment from 'moment';
import MuiAlert from '@mui/material/Alert';
import AdvisorTabs from './AdvisorTabs';
import ChangePassword from '../../Components/ChangePassword';
import { fetchDeleteData } from "../../helpers/externapi";
import Snackbar from '@mui/material/Snackbar';
import TableContainer from '@mui/material/TableContainer';
import ConfirmationDialogDelete from "../../Components/ConfirmationDialogDelete";
import Paper from '@mui/material/Paper';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AdvisorPersonalDetails(props) {
    const [profile, setProfile] = React.useState(props.data);
    const [kycMessage, setKycMessage] = React.useState();
    const [formVisible, setFormVisible] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationData, setConfirmationData] = useState({});
    const navigate = useNavigate();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const id = useParams();

    const handleEditForm = () => {
        navigate('/advisor/new', { state: { profile } })
    }

    const handleBackToList = () => {
        navigate("/distributor/list")
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleDelete = () => {
        setConfirmationData({
            title: 'Delete Distributor',
            message: 'Are you sure you want to delete this Distributor?',
            onConfirm: () => confirmhandleDelete(),
        });
        setConfirmationOpen(true);
    };

    const confirmhandleDelete = async () => {
        try {
            const response = await fetchDeleteData(`Member/delete/${id.Id}`);
            if (response.status === false) {
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
                return;
            }
            setSnackbarMessage(`Deleted Successfully`);
            setSnackbarOpen(true);

            setTimeout(() => {
                navigate("/distributor/list");
            }, 2000);
        } catch (error) {
            console.error("Error deleting event:", error);
            setSnackbarMessage('Failed to delete. Please try again.');
            setSnackbarOpen(true);
        }
    };

    const blueColor = '#0d6efd';
    const getColor = (value) => (value ? 'text-primary' : 'text-primary');

    return (
        <>
            <div className="card-action ">
                {kycMessage && (
                    <div style={{
                        backgroundColor: '#4CAF50', color: 'white', padding: '5px', marginTop: '5px', borderRadius: '5px', textAlign: 'center', float: 'right',
                        width: '100%'
                    }}>
                        {kycMessage}
                    </div>
                )}
            </div>
            <div className="container-xxl flex-grow-1 p-2 y-4">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card mb-3 d-flex" style={{ minHeight: "200px" }}>

                            <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start mx-3 my-3">
                                <div className="flex-shrink-0 me-md-4 mb-3 mb-md-0">
                                    <img
                                        src={profile.MemberImage ? `https://ohoindiaimages.s3.ap-south-1.amazonaws.com/${profile.MemberImage.split('.').shift()}.jpg` : "../../assets/img/dummy-avatar.jpg"}
                                        height="250"
                                        width="250"
                                        alt="User avatar"
                                        className="d-block h-auto rounded user-profile-img"
                                    />

                                </div>
                                <div className="flex-grow-1">
                                    <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start flex-md-row flex-column gap-4">
                                        <div className="user-profile-info p-4" style={{ backgroundColor: "#f8f9fa", borderRadius: "12px", boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)" }}>
                                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                                                <h2 className="mb-3 text-dark" style={{ fontSize: "24px", fontWeight: "600" }}>{profile.Name}</h2>

                                                <div className="d-flex align-items-center gap-2">
                                                    <button type="button" className="btn btn-outline-primary btn-sm" onClick={handleEditForm}>
                                                        <i className="bx bx-user-edit me-1"></i> Edit
                                                    </button>
                                                    <button className="btn btn-outline-danger btn-sm" onClick={handleDelete}>
                                                        <i className="bx bx-trash-alt me-1"></i> Delete
                                                    </button>
                                                </div>
                                            </div>

                                            {/* Main Profile Details */}
                                            <div className="row text-muted" style={{ fontSize: "14px" }}>
                                                <div className="col-xl-12 mb-2">
                                                    <i className="bx bx-map me-2"></i><strong>Address :</strong> {profile.completeAddress || "Not provided"}
                                                </div>

                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-calendar me-2"></i><strong>RegOn :</strong> {moment(profile.RegisterOn).format('DD-MMM-YYYY')}
                                                </div>

                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-calendar-alt me-2"></i><strong>DOB :</strong> {profile.DateofBirth ? moment(profile.DateofBirth).format('DD-MMM-YYYY') : "Not updated"}
                                                </div>

                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-phone me-2"></i><strong>Mobile No :</strong> {profile.MobileNumber ? (
                                                        <a href={"tel:" + profile.MobileNumber} className="text-dark">{profile.MobileNumber}</a>
                                                    ) : <span className="text-danger">Not updated</span>}
                                                </div>

                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-envelope me-2"></i><strong>Email : </strong> {profile.Email ? (
                                                        <a href={"mailto:" + profile.Email} className="text-dark">{profile.Email}</a>
                                                    ) : <span className="text-danger">Not updated</span>}
                                                </div>

                                                {/* Service Provider Status */}
                                                {profile.ServiceProviderName && (
                                                    <div className="col-md-4 mb-2">
                                                        <span><strong>{profile.ServiceProviderName} : </strong></span>{" "}
                                                        <span className={`badge ${profile.ServiceProviderStatus === "Pending" ? "bg-danger" :
                                                            profile.ServiceProviderStatus === "Processing" ? "bg-warning" :
                                                                profile.ServiceProviderStatus === "Active" ? "bg-success" :
                                                                    "bg-secondary"}`}>
                                                            {profile.ServiceProviderStatus || 'Pending'}
                                                        </span>
                                                    </div>
                                                )}

                                                
                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-user me-2"></i><strong>Gender :</strong> {profile.Gender || "Not specified"}
                                                </div>
                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-id-card me-2"></i><strong>RM Name :</strong> {profile.RMName || "Not assigned"}
                                                </div>
                                                <div className="col-md-4 mb-2">
                                                    <i className="bx bx-building me-2"></i><strong>Route :</strong> {profile.RouteName || "Not assigned"}
                                                </div>

                                                <div className="col-md-4 mb-2">
                                                    <i className="fa fa-volume-control-phone me-2" aria-hidden="true"></i><strong>Telecaller :</strong> {profile.TelecallerName || "Not assigned"}
                                                </div>

                                                
                                                <div className="col-md-4 mb-2">
                                                    <strong>Membership Status :</strong>
                                                    <span className={`badge ${profile.MembershipStatus === "Pending" ? "bg-danger" :
                                                        profile.MembershipStatus === "Active" ? "bg-success" : "bg-secondary"}`}>
                                                        {profile.MembershipStatus || "Pending"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <BoxWrapper sx={{ padding: { xs: 2, sm: 3, md: 4 } }}>
                    <AdvisorTabs />

                    {formVisible && <ChangePassword />}
                </BoxWrapper>

            </div>

            <TableContainer component={Paper}>
                <ConfirmationDialogDelete
                    open={confirmationOpen}
                    title={confirmationData.title}
                    message={confirmationData.message}
                    onConfirm={confirmationData.onConfirm}
                    onCancel={() => setConfirmationOpen(false)}
                />
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert onClose={handleSnackbarClose} severity="success">
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </TableContainer>
        </>
    );
}