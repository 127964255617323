import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { fetchAllData } from "../../helpers/externapi";

export default function ProductPersonalDetails(props) {
    const location = useLocation();
    const [profile, setProfile] = useState(props.data || []);
    const [otherCharges, setOtherCharges] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const id = useParams();
    const [isEditing, setIsEditing] = useState(false);
    const [comboList, setComboList] = useState([]);
 
    const [selectedProduct, setSelectedProduct] = useState(null);

    const openModal = (product) => {
        setSelectedProduct(product);
        const modal = new window.bootstrap.Modal(document.getElementById('exLargeModal'));
        modal.show();
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetchAllData(`Products/GetAllProductDetailsById/${id.Id}`);
                setProfile(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, [id.Id]);

    useEffect(() => {
        const fetchOtherChargesData = async () => {
            setLoading(true);
            try {
                const response = await fetchAllData(`OtherCharges/GetOtherChargesDetailsByProductsId/${id.Id}`);
                setOtherCharges(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchOtherChargesData();
    }, [id.Id]);

    useEffect(() => {
        if (profile.length > 0 && profile[0].IsCombo) {
            const getSelectedProducts = async () => {
                const response = await fetchAllData(`ComboProducts/FetchComboProducts/${profile[0].ProductsId}`);

                setComboList(response);
            }

            getSelectedProducts();
        }

    }, [profile])

    const handleEditForm = () => {
        navigate('/products/new', { state: { profile, otherCharges } });
    };

    const handleBackToList = () => {
        navigate("/products/list");
    };

    const stripHtmlTags = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };

    return (
        <>
            <div>
                {!isEditing && (
                    <div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card mb-2">
                                    {profile.length > 0 ? (
                                        <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-2">
                                            {profile[0].Image && (
                                                <div className="mb-4 text-left">
                                                    <div className="text-center">
                                                        <img
                                                            src={`https://ohoindiaimages.s3.ap-south-1.amazonaws.com/${profile[0].Image.split('.').shift()}.jpg`}
                                                            style={{ width: '180px', height: '100px', marginTop: '0px', marginLeft: '10px' }}
                                                            alt="KYC Front"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div className="flex-grow-1 mt-3">
                                                <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                                                    <div className="user-profile-info">
                                                        <div className='d-flex flex-row justify-content-between'>
                                                            <h4 className='fw-bolder'>{profile[0].ProductName}</h4>
                                                            {profile[0].IsCombo ? (
                                                                <p className="badge bg-label-success">Combo Product</p>
                                                            ) : (
                                                                <p className="badge bg-label-warning">Individual Product</p>
                                                            )}
                                                        </div>

                                                        <ul className="list-inline mb-2 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                                                            <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                <i className='bx bx-male'></i> Maximum Adult: {profile[0].MaximumAdult}
                                                            </li>
                                                            <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                <i className='bx bx-child'></i> Maximum Child: {profile[0].ProductsMaximumChild}
                                                            </li>
                                                            <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                <i className='bx bx-calendar-alt'></i> Minimum Age: {profile[0].ProductsMinimumAge}
                                                            </li>
                                                            <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                <i className='bx bx-calendar-alt'></i> Maximum Age: {profile[0].ProductsMaximumAge}
                                                            </li>
                                                            <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                <i className='bx bx-child'></i> Children Age: {profile[0].ProductsChildrenAge}
                                                            </li> 
                                                            <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                <i className='bx bx-globe'></i> Servie Provider: {profile[0].ServiceProvider}
                                                            </li>
                                                            <li className="list-inline-item col-6">
                                                                <i className='bx bx-calendar-alt'></i> Short Description: {profile[0].ShortDescription}
                                                            </li>
                                                            {profile[0].LongDescription && (
                                                                <li className="list-inline-item col-6">
                                                                    <i className='bx bx-calendar-alt'></i> Long Description: {stripHtmlTags(profile[0].LongDescription)}
                                                                </li> 
                                                            )}                                                                                                                       
                                                        </ul>

                                                        {profile[0].IsCombo && (
                                                            <div className='mt-3'>
                                                                <h5>Combo Products</h5>
                                                                <ul>
                                                                    {comboList && comboList.map(item => (
                                                                        <li key={item.ProductsId}>
                                                                            <Link  onClick={() => openModal(item)}>
                                                                                {item.ProductName}
                                                                            </Link>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="modal fade" id="exLargeModal" tabIndex="-1" aria-hidden="true">
                                                        <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="exampleModalLabel4">
                                                                        {selectedProduct ? selectedProduct.ProductName : 'Modal title'}
                                                                    </h5>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                   
                                                                    {selectedProduct ? (
                                                                        <div>
                                                                            <ul className="list-inline mb-2 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                                                                                <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                                    <i className='bx bx-male'></i> Maximum Adult: {selectedProduct.MaximumAdult}
                                                                                </li>
                                                                                <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                                    <i className='bx bx-child'></i> Maximum Child: {selectedProduct.ProductsMaximumChild}
                                                                                </li>
                                                                                <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                                    <i className='bx bx-calendar-alt'></i> Minimum Age: {selectedProduct.ProductsMinimumAge}
                                                                                </li>
                                                                                <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                                    <i className='bx bx-calendar-alt'></i> Maximum Age: {selectedProduct.ProductsMaximumAge}
                                                                                </li>
                                                                                <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                                    <i className='bx bx-child'></i> Children Age: {selectedProduct.ProductsChildrenAge}
                                                                                </li>
                                                                                <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                                    <i className='bx bx-globe'></i> Service Provider: {selectedProduct.ServiceProvider}
                                                                                </li>
                                                                                <li className="list-inline-item col-6">
                                                                                    <i className='bx bx-calendar-alt'></i> Short Description: {selectedProduct.ShortDescription}
                                                                                </li>
                                                                                <li className="list-inline-item col-6">
                                                                                    <i className='bx bx-calendar-alt'></i> Long Description: {stripHtmlTags(selectedProduct.LongDescription)}
                                                                                </li>
                                                                            </ul>

                                                                            <div className="row justify-content-center">
                                                                                <div className="col-md-12">
                                                                                    <div className="card">
                                                                                        <h5 className="card-header">Insurance Premium Table</h5>
                                                                                        <div className="table-responsive text-nowrap">
                                                                                            <table className="table">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>Minimum Age</th>
                                                                                                        <th>Maximum Age</th>
                                                                                                        <th>Base Premium</th>
                                                                                                        <th>GST</th>
                                                                                                        <th>Total Amount</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody className="table-border-bottom-0">
                                                                                                    {selectedProduct.InsurancePremiums && selectedProduct.InsurancePremiums.length > 0 ? (
                                                                                                        selectedProduct.InsurancePremiums.map((item, index) => (
                                                                                                            <tr key={index}>
                                                                                                                <td>{item.InsurancePremiumMinimumAge}</td>
                                                                                                                <td>{item.InsurancePremiumMaximumAge}</td>
                                                                                                                <td>{item.BasePremium}</td>
                                                                                                                <td>{item.GST}</td>
                                                                                                                <td>{item.InsurancePremiumTotalAmount}</td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        <tr>
                                                                                                            <td colSpan="5">No data available</td>
                                                                                                        </tr>
                                                                                                    )}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                        
                                                                                    <div className="card mt-2">
                                                                                        <h5 className="card-header">Other Charges Table</h5>
                                                                                        <div className="table-responsive text-nowrap">
                                                                                            <table className="table">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>Label Name</th>
                                                                                                        <th>Value</th>
                                                                                                        <th>isFixed</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody className="table-border-bottom-0">
                                                                                                    {selectedProduct.OtherCharges && selectedProduct.OtherCharges.length > 0 ? (
                                                                                                        selectedProduct.OtherCharges.map((item, index) => (
                                                                                                            <tr key={index}>
                                                                                                                <td>{item.LabelName}</td>
                                                                                                                <td>{item.Value}</td>
                                                                                                                <td>{item.IsFixed ? 'Yes' : 'No'}</td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        <tr>
                                                                                                            <td colSpan="3">No data available</td>
                                                                                                        </tr>
                                                                                                    )}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                         </div>

                                                                        ) : (
                                                                            <p>Loading...</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    ) : (
                                        <div>
                                            <p>No data available</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="card">
                                    <h5 className="card-header">Insurance Premium</h5>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Minimum Age</th>
                                                    <th>Maximum Age</th>
                                                    <th>Base Premium</th>
                                                    <th>GST</th>
                                                    <th>Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {profile.length > 0 ? (
                                                    profile.map((item, index) => (
                                                        <tr>
                                                            <td>{item.InsurancePremiumMinimumAge}</td>
                                                            <td>{item.InsurancePremiumMaximumAge}</td>
                                                            <td>{item.BasePremium}</td>
                                                            <td>{item.GST}</td>
                                                            <td>{item.InsurancePremiumTotalAmount}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="3">No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card mt-2">
                                    <h5 className="card-header">Other Charges</h5>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Label Name</th>
                                                    <th>Value</th>
                                                    <th>isFixed</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {otherCharges.length > 0 ? (
                                                    otherCharges.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.LabelName}</td>
                                                            <td>{item.Value}</td>
                                                            <td>{item.IsFixed ? 'Yes' : 'No'}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="3">No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="d-flex demo-inline-spacing my-2 mx-2 align-items-center">
                <div className="card-action-title">
                    <button onClick={handleBackToList} className="btn btn-md btn-primary">Back To List</button>
                </div>
                <button onClick={handleEditForm} className="btn btn-md btn-primary">Edit</button>
            </div>
        </>
    );
}
