import React, { useEffect, useState } from "react";
import { fetchData, fetchUpdateData, fetchDeleteData, uploadImage } from "../../helpers/externapi";
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CommonTables from "../../Commoncomponents/CommonTables";
import TableContainer from '@mui/material/TableContainer';
import ConfirmationDialogDelete from "../../Components/ConfirmationDialogDelete";
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { MultiSelect } from "react-multi-select-component";
import Layout from "../../Layout/Layout";

export default function UserAndRoleList(props) {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [userRoleDropdown, setUserRoleDropdown] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [formVisible, setFormVisible] = useState(false);
    const [formType, setFormType] = useState('user');
    const [formData, setFormData] = useState({ UserId: "", UserName: "", EmployeeId: "", MobileNumber: "", EmailId: "", UserImage: "", UserRoleId: "", RoleType: "", FullName: "", PinNo: "", PassWord: "", RouteMapId: [], ReportingId: '' });
    const [errors, setErrors] = useState({});
    const [totalCountUserList, setTotalCountUserList] = useState(0);
    const [currentPageUserList, setCurrentPageUserList] = useState(1);
    const [perPageUserList, setPerPageUserList] = useState(10);
    const [totalCountUserRole, setTotalCountUserRole] = useState(0);
    const [currentPageUserRole, setCurrentPageUserRole] = useState(1);
    const [perPageUserRole, setPerPageUserRole] = useState(10);
    const [selectedImage, setSelectedImage] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationData, setConfirmationData] = useState({});
    const [routename, setRouteName] = useState([]);
    const [routeMapMultiSelect, setRouteMapMultiSelect] = useState();
    const [selectedRouteMaps, setSelectedRouteMaps] = useState([]);
    const [userFormError, setUserFormError] = useState('');
    const [allUsers, setAllUsers] = useState([]);
    const [isSnackbarSuccess, setIsSnakbarSuccess] = useState(true);

    let UserId = sessionStorage.getItem("UserId");

    const tableHeadsUserLists = ["Employee Id", "Full Name", "User Name", "Mobile Number", "Email Id", "User Role", "Reporting Name", "Actions"];
    const tableHeadsUserRoles = ["Role Type", "Actions"];

    const tableElementsUserlists = users.length > 0 ?
        users.map(user => ([
            user.EmployeeId,
            user.FullName,
            user.UserName,
            user.MobileNumber,
            user.EmailId,
            // user.AssignedRouteMapNames,
            user.UserRoleName,
            user.ReportingName,
            <>
                <button className="btn btn-sm btn-primary m-2" onClick={() => handleEdit(user, 'user')}>
                    Edit
                </button>
                <button className="btn btn-sm btn-danger" onClick={() => handleDelete(user.UserId, 'user')}>
                    Delete
                </button>
            </>

        ])) : [];

    const tableElementsUserRoles = userRoles.length > 0 ?
        userRoles.map(userRole => ([
            userRole.RoleType,
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginRight: '10px' }}
                    onClick={() => handleEdit(userRole, 'role')}
                >
                    Edit
                </Button>
                <Button
                    variant="contained"
                    size="small"
                    sx={{ backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'darkred' } }}
                    onClick={() => handleDelete(userRole.UserRoleId, 'role')}
                >
                    Delete
                </Button>
            </div>
        ])) : [];

    const getUserRoleDropdown = async () => {
        try {
            setLoading(true);
            const userRolesData = await fetchData("UserRoles/all", { "skip": 0, "take": 0 });
            setUserRoleDropdown(userRolesData);
        } catch (error) {
            console.error("Error fetching user roles data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUserRoleDropdown();
        getRouteName();
    }, []);

    //const getRouteName = async () => {
    //    const routename = await fetchData("RouteMap/all", { "skip": 0, "take": 0 });
    //    console.log('routename', routename);
    //    setRouteName(routename);

    //    setLoading(false);
    //};

    const getRouteName = async () => {
        setLoading(true);
        const routename = await fetchData("RouteMap/all", { "skip": 0, "take": 0 });
        const routeArray = routename.map(item => ({ label: item.RouteName, value: item.RouteMapId }));
        setRouteMapMultiSelect(routeArray);
        setLoading(false);
    };

    const getUsers = async () => {
        try {
            const skip = (currentPageUserList - 1) * perPageUserList;
            const take = perPageUserList;

            setLoading(true);
            const usersData = await fetchData("Users/all", { skip, take });

            const allUsers = await fetchData('Users/all', { skip: 0, take: 0 });

            setUsers(usersData);
            console.log("usersData", usersData);
            setAllUsers(allUsers);

        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            setLoading(false);
        }
    };

    const getUserRoles = async () => {
        try {
            const skip = (currentPageUserRole - 1) * perPageUserRole;
            const take = perPageUserRole;

            setLoading(true);
            const userRolesData = await fetchData("UserRoles/all", { skip, take });
            setUserRoles(userRolesData);
        } catch (error) {
            console.error("Error fetching user roles data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUsers();
        getUserRoles();
        getUserListCountData();
        getUserRoleCountData();
    }, [currentPageUserList, perPageUserList, totalCountUserList, currentPageUserRole, perPageUserRole, totalCountUserRole]);

    const handleMultiSelectChange = (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        onChangeHandler({ target: { name: 'RouteMapId', value: selectedValues } });
    };

    const routeOptions = routename ? routename.map(option => ({
        value: option.RouteMapId,
        label: option.RouteName,
    })) : [];

    const onChangeHandler = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleEdit = (item, type) => {
        setIsEditMode(true);
        setFormVisible(true);
        setFormType(type);
        setErrors({});
        setSelectedImage(null);

        if (type === 'user') {

            const routeMapIds = item.AssignedRouteMaps ? item.AssignedRouteMaps.split(',').map(id => parseInt(id.trim(), 10)) : [];

            console.log('routeMapIds', routeMapIds);

            const assignedRoutes = routeMapIds.map((routeId, index) => {
                const route = routeMapMultiSelect.find(option => option.value === routeId);
                return route ? { label: route.label, value: route.value } : null;
            }).filter(route => route !== null);

            console.log('Mapped assignedRoutes:', assignedRoutes);
            setFormData({
                UserId: item.UserId,
                FullName: item.FullName,
                UserName: item.UserName,
                EmployeeId: item.EmployeeId,
                MobileNumber: item.MobileNumber,
                EmailId: item.EmailId,
                UserImage: item.UserImage,
                UserRoleId: item.UserRoleId,
                RoleType: "",
                RouteMapId: routeMapIds,
                ReportingId: item.ReportingTo,

            });
            setSelectedRouteMaps(assignedRoutes.map(route => ({ label: route.label, value: route.value })));
            console.log('selectedRouteMaps state:', assignedRoutes.map(route => ({ label: route.label, value: route.value })));

        } else {
            setFormData({
                UserId: "",
                FullName: "",
                UserName: "",
                EmployeeId: "",
                MobileNumber: "",
                EmailId: "",
                UserImage: "",
                UserRoleId: item.UserRoleId,
                RoleType: item.RoleType,
                RouteMapId: [],
            });
            setSelectedRouteMaps([]);
        }
    };

    const handleAddNew = (type) => {
        setIsEditMode(false);
        setFormVisible(true);
        setFormType(type);
        setFormData({
            UserId: "",
            FullName: "",
            UserName: "",
            EmployeeId: "",
            MobileNumber: "",
            EmailId: "",
            UserImage: "",
            UserRoleId: "",
            RoleType: "",
            RouteMapId: [],
            UserRouteMapsId: "",
        });
        setSelectedRouteMaps([]);
        setErrors({});
        setSelectedImage(null);
    };

    const validateForm = () => {
        let errors = {};
        if (formType === 'user') {
            if (!formData.UserName) errors.UserName = "Please Enter User Name ";
            if (!formData.FullName) errors.FullName = "Please Enter Full Name ";
            if (!formData.EmployeeId) errors.EmployeeId = "Please Enter Employee Id";

            if (formData.MobileNumber) {
                if (typeof formData.MobileNumber === 'string' && formData.MobileNumber.length > 0 && formData.MobileNumber.trim() === '') {
                    errors.MobileNumber = 'Please enter a valid 10-digit mobile number';
                } else if (!/^[6-9]\d{9}$/.test(formData.MobileNumber.trim())) {
                    errors.MobileNumber = 'Mobile Number must start with 6, 7, 8, or 9 and must be 10 digits';
                }
            } else {
                errors.MobileNumber = 'Please Enter valid mobile number'
            }

            if (!formData.UserRoleId) errors.UserRoleId = "Please Select User Role";

            if (!formData.ReportingId) errors.ReportingId = "Please Select Reporting Name";
        } else {
            if (!formData.RoleType) errors.RoleType = "Please Enter Role Type";
        }
        return errors;
    };

    const handleFileSelection = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    };

    const handleFileUpload = async (UserId) => {
        try {
            const formData = new FormData();
            formData.append('UserId', UserId);
            formData.append('UserImage', selectedImage);

            console.log('Files:', selectedImage);
            console.log('UserId:', UserId);

            const response = await uploadImage('Users/upload', formData);
            console.log('Files uploaded:', response);

            return response;
        } catch (error) {
            console.error('Error uploading files:', error);
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length !== 0) {
            setErrors(errors);
            return;
        }

        setLoading(true);
        try {
            let UserId;
            if (formType === 'user') {
                let userData;
                if (isEditMode) {
                    userData = await fetchUpdateData("Users/update", {
                        userId: formData.UserId,
                        fullName: formData.FullName,
                        userName: formData.UserName,
                        employeeId: formData.EmployeeId,
                        mobileNumber: formData.MobileNumber,
                        emailId: formData.EmailId,
                        userImage: formData.UserImage,
                        userRoleId: formData.UserRoleId,
                        pinNo: formData.pinNo,
                        passWord: formData.PassWord,
                        reportingTo: formData.ReportingId,
                    });
                    // setSnackbarMessage("User updated Successfully!");

                } else {
                    userData = await fetchData("Users/add", {
                        userName: formData.UserName,
                        fullName: formData.FullName,
                        employeeId: formData.EmployeeId,
                        mobileNumber: formData.MobileNumber,
                        emailId: formData.EmailId,
                        userImage: formData.UserImage,
                        userRoleId: formData.UserRoleId,
                        reportingTo: formData.ReportingId,
                    });
                    // setSnackbarMessage("User added Successfully!");
                };

                console.log("add response: ", userData);

                UserId = userData.userId;

                if (UserId) {
                    let routeMapData = selectedRouteMaps.map(routemap => ({
                        userId: UserId,
                        routeMapId: routemap.value,
                    }));

                    if (routeMapData.length === 0) {
                        routeMapData = [{
                            userId: UserId,
                            routeMapId: 0,
                        }];
                    };

                    if (!Array.isArray(routeMapData)) {
                        console.error('userRoleIds is not an array:', routeMapData);
                        return;
                    }

                    const seedData = await fetchData("UserRouteMap/seed", routeMapData);

                    if (isEditMode) {
                        setSnackbarMessage("User updated Successfully!");
                    } else {
                        setSnackbarMessage("User added Successfully!");
                    }

                    setLoading(false);
                    setFormData({ UserId: "", UserName: "", EmployeeId: "", MobileNumber: "", EmailId: "", UserImage: "", UserRoleId: "", RoleType: "" });
                    setIsEditMode(false);
                    setFormVisible(false);
                    setUserFormError('');

                } else if (UserId === undefined || !userData.status) {

                    setLoading(false);

                    if (userData && userData.data) {
                        setUserFormError(userData.data);
                    } else {
                        setUserFormError('Something went wrong. Please Contact Technical Team')
                    }
                }

                if (!UserId) {
                    throw new Error('UserId is undefined');
                }

                if (selectedImage) {
                    await handleFileUpload(UserId);
                }

                setSnackbarOpen(true);
                await getUsers();

            } else {
                if (isEditMode) {
                    await fetchUpdateData("UserRoles/update", {
                        userRoleId: formData.UserRoleId,
                        roleType: formData.RoleType
                    });
                    setSnackbarMessage("User Roles updated Successfully!");
                } else {
                    await fetchData("UserRoles/add", {
                        roleType: formData.RoleType
                    });
                    setSnackbarMessage("User Roles Added Successfully!");
                }

                setSnackbarOpen(true);
                await getUserRoles();

                setFormData({ UserId: "", UserName: "", EmployeeId: "", MobileNumber: "", EmailId: "", UserImage: "", UserRoleId: "", RoleType: "" });
                setIsEditMode(false);
                setFormVisible(false);
            }

        } catch (error) {
            console.error("Error adding/updating:", error);
        }
        // finally {
        //     setLoading(false);
        //     setFormData({ UserId: "", UserName: "", EmployeeId: "", MobileNumber: "", EmailId: "", UserImage: "", UserRoleId: "", RoleType: "" });
        //     setIsEditMode(false);
        //     setFormVisible(false);
        // }
    };

    const handleDelete = (id, type) => {
        setConfirmationData({
            title: 'Delete User Or User Role',
            message: 'Are you sure you want to delete this User Or User Role?',
            onConfirm: () => confirmhandleDelete(id, type),
        });
        setConfirmationOpen(true);
    };

    const confirmhandleDelete = async (id, type) => {
        try {
            setLoading(true);
            setConfirmationOpen(false);
            if (type === 'user') {
                const userDelete = await fetchDeleteData(`Users/delete/${id}`);
                console.log('userDelete', userDelete, id);

                if (userDelete) {
                    setSnackbarMessage('User deleted Successfully');
                    setSnackbarOpen(true);
                    setIsSnakbarSuccess(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    setSnackbarMessage('Something went wrong, Please Contact Technical Team');
                    setSnackbarOpen(true);
                    setIsSnakbarSuccess(false);
                    await getUsers();
                };

            } else {
                await fetchDeleteData(`UserRoles/delete/${id}`);

                setSnackbarMessage('User Role deleted Successfully');
                setSnackbarOpen(true);
                setIsSnakbarSuccess(true);
                await getUserRoleCountData();
                await getUserRoles();
            }
        } catch (error) {
            console.error("Error deleting:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setFormVisible(false);
    };

    const getUserListCountData = async () => {
        setLoading(true);
        try {
            const eventCountData = await fetchData(`CommonRowCount/GetTableRowCount`, { tableName: "User" });
            const totalCount = eventCountData[0]?.CountOfRecords || 0;
            setTotalCountUserList(totalCount);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching hospital count data:", error);
            setLoading(false);
        }
    };

    const handlePageChangeUserList = (event, page) => {
        setCurrentPageUserList(page);
    };

    const handlePerPageChangeUserList = (event) => {
        setPerPageUserList(parseInt(event.target.value, 10));
        setCurrentPageUserList(1);
    };

    const getUserRoleCountData = async () => {
        setLoading(true);
        try {
            const userRoleCountData = await fetchData(`CommonRowCount/GetTableRowCount`, { tableName: "UserRole" });
            const totalCount = userRoleCountData[0]?.CountOfRecords || 0;
            setTotalCountUserRole(totalCount);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching hospital count data:", error);
            setLoading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handlePageChangeUserRole = (event, page) => {
        setCurrentPageUserRole(page);
    };

    const handlePerPageChangeUserRole = (event) => {
        setPerPageUserRole(parseInt(event.target.value, 10));
        setCurrentPageUserRole(1);
    };

    const skeletonloading = () => (
        <>
            <style>{shimmerStyle}</style>
            <div className="shimmer-container shimmer">
                <h6 className="shimmer-text "></h6>
            </div>
            <table className="shimmer-container shimmer">
                <tbody>
                    {Array.from({ length: 6 }).map((_, rowIndex) => (
                        <tr key={rowIndex} className="shimmer-row">
                            {Array.from({ length: 6 }).map((_, colIndex) => (
                                <td key={colIndex} className="shimmer-cell">
                                    <h6 className="shimmer-text2 " ></h6>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

        </>
    );

    return (
        <Layout>
            {loading && skeletonloading()}
            {!loading && (
                <>
                    <h2 style={customStyles.header}>Users List</h2>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAddNew('user')}
                    >
                        Add User
                    </Button>

                    <div className="card">
                        {loading ? (
                            <div style={customStyles.loadingContainer}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <CommonTables
                                tableHeads={tableHeadsUserLists}
                                tableData={tableElementsUserlists}
                                perPage={perPageUserList}
                                currentPage={currentPageUserList}
                                perPageChange={handlePerPageChangeUserList}
                                pageChange={handlePageChangeUserList}
                                totalCount={totalCountUserList}
                            />
                        )}
                    </div>

                    <h2 style={customStyles.header}>User Roles List</h2>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAddNew('role')}
                    >
                        Add User Role
                    </Button>

                    <div className="card">
                        {loading ? (
                            <div style={customStyles.loadingContainer}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <CommonTables
                                tableHeads={tableHeadsUserRoles}
                                tableData={tableElementsUserRoles}
                                perPage={perPageUserRole}
                                currentPage={currentPageUserRole}
                                perPageChange={handlePerPageChangeUserRole}
                                pageChange={handlePageChangeUserRole}
                                totalCount={totalCountUserRole}
                            />
                        )}
                    </div>

                    <TableContainer component={Paper}>
                        <ConfirmationDialogDelete
                            open={confirmationOpen}
                            title={confirmationData.title}
                            message={confirmationData.message}
                            onConfirm={confirmationData.onConfirm}
                            onCancel={() => setConfirmationOpen(false)}
                        />
                        <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={6000}
                            onClose={handleSnackbarClose}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                            <Alert onClose={handleSnackbarClose} severity={isSnackbarSuccess ? "success" : 'error'}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                    </TableContainer>

                    <Dialog open={formVisible} onClose={handleClose}>
                        <DialogTitle>{isEditMode ? 'Edit' : 'Add New'} {formType === 'user' ? 'User' : 'User Role'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {formType === 'user' ? 'Please enter user details.' : 'Please enter user role details.'}

                                {userFormError && userFormError.length > 0 && (
                                    <p className="text-danger text-right">{userFormError}</p>
                                )}

                            </DialogContentText>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                {formType === 'user' ? (
                                    <>
                                        <TextField
                                            margin="normal"
                                            //required
                                            fullWidth
                                            id="FullName"
                                            label={
                                                <span>
                                                    Full Name<span style={{ color: 'red' }}> *</span>
                                                </span>
                                            }
                                            name="FullName"
                                            value={formData.FullName}
                                            onChange={onChangeHandler}
                                            error={!!errors.FullName}
                                            helperText={errors.FullName}
                                        />

                                        <TextField
                                            margin="normal"
                                            // required
                                            fullWidth
                                            id="UserName"
                                            label={
                                                <span>
                                                    User Name<span style={{ color: 'red' }}> *</span>
                                                </span>
                                            }
                                            name="UserName"
                                            value={formData.UserName}
                                            onChange={onChangeHandler}
                                            error={!!errors.UserName}
                                            helperText={errors.UserName}
                                        />
                                        <TextField
                                            margin="normal"
                                            // required
                                            fullWidth
                                            id="EmployeeId"
                                            label={
                                                <span>
                                                    Employee Id<span style={{ color: 'red' }}> *</span>
                                                </span>
                                            }
                                            name="EmployeeId"
                                            value={formData.EmployeeId}
                                            onChange={onChangeHandler}
                                            error={!!errors.EmployeeId}
                                            helperText={errors.EmployeeId}
                                        />
                                        <TextField
                                            margin="normal"
                                            // required
                                            fullWidth
                                            id="MobileNumber"
                                            label={
                                                <span>
                                                    Mobile Number<span style={{ color: 'red' }}> *</span>
                                                </span>
                                            }
                                            name="MobileNumber"
                                            value={formData.MobileNumber}
                                            onChange={onChangeHandler}
                                            error={!!errors.MobileNumber}
                                            helperText={errors.MobileNumber}
                                            inputProps={{ maxLength: 10 }}
                                        />
                                        <TextField
                                            margin="normal"

                                            fullWidth
                                            id="EmailId"
                                            label={
                                                <span>
                                                    Email Id<span style={{ color: 'red' }}> *</span>
                                                </span>
                                            }
                                            name="EmailId"
                                            value={formData.EmailId}
                                            onChange={onChangeHandler}
                                            error={!!errors.EmailId}
                                            helperText={errors.EmailId}
                                        />
                                        <div className="col-md-14">
                                            <div className="mb-3">
                                                <label htmlFor="Image" className="form-label">User Image <span className="text-danger"></span></label>
                                                <div className="col-12 fw-normal" style={{ marginBottom: '10px' }}>
                                                    {selectedImage && (
                                                        <a href={URL.createObjectURL(selectedImage)} target="_blank" rel="noopener noreferrer">
                                                            <img className="selected-image img-thumbnail me-3" src={URL.createObjectURL(selectedImage)} alt="Selected Front" style={{ width: '150px', height: '100px', objectFit: 'cover' }} />
                                                        </a>
                                                    )}
                                                    <input className="form-control" type="file" name="UserImage" onChange={(e) => handleFileSelection(e)} style={{ marginTop: '10px' }} />
                                                </div>
                                            </div>
                                        </div>

                                        <FormControl fullWidth margin="normal" error={!!errors.UserRoleId}>
                                            <InputLabel id="UserRoleId-label">User Role<span style={{ color: 'red' }}> *</span></InputLabel>
                                            <Select
                                                labelId="UserRoleId-label"
                                                id="UserRoleId"
                                                name="UserRoleId"
                                                value={formData.UserRoleId}
                                                onChange={onChangeHandler}
                                                label="User Role"
                                            >
                                                {userRoleDropdown.map((role) => (
                                                    <MenuItem key={role.UserRoleId} value={role.UserRoleId}>
                                                        {role.RoleType}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.UserRoleId && <Typography color="error">{errors.UserRoleId}</Typography>}
                                        </FormControl>

                                        <FormControl fullWidth margin="normal" error={!!errors.ReportingId}>
                                            <InputLabel id="ReportingId-label">Reporting to<span style={{ color: 'red' }}> *</span></InputLabel>
                                            <Select
                                                labelId="ReportingId-label"
                                                id="ReportingId"
                                                name="ReportingId"
                                                value={formData.ReportingId}
                                                onChange={onChangeHandler}
                                                label="Reporting to"
                                            >
                                                {allUsers.map((user) => (
                                                    <MenuItem key={user.UserId} value={user.UserId}>
                                                        {user.UserName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.ReportingId && <Typography color="error">{errors.ReportingId}</Typography>}
                                        </FormControl>

                                        <label htmlFor="select2Success" className="form-label">Route Map</label>
                                        <div className="select2-primary">
                                            {routeMapMultiSelect && (
                                                <MultiSelect
                                                    options={routeMapMultiSelect}
                                                    value={selectedRouteMaps}
                                                    onChange={setSelectedRouteMaps}
                                                />
                                            )}
                                        </div>

                                    </>
                                ) : (
                                    <>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="RoleType"
                                            label="Role Type"
                                            name="RoleType"
                                            value={formData.RoleType}
                                            onChange={onChangeHandler}
                                            error={!!errors.RoleType}
                                            helperText={errors.RoleType}
                                        />
                                    </>
                                )}
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button type="submit" color="primary">
                                        {isEditMode ? 'Update' : 'Add'}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </DialogContent>
                    </Dialog>
                </>
            )}
        </Layout>
    );
}

const shimmerStyle = `
     @keyframes shimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  .shimmer {
    animation-duration: 1.0s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background:	#F7F7F7;
    background: linear-gradient(to right, #f0f0f0 8%, #fafafa 18%, #f0f0f0 33%);
    background-size: 1000px 104px;
    position: relative;
    overflow: hidden;
  }

  .shimmer-container {
    background-color: 	#F7F7F7;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    margin: 15px;
  }

  .shimmer-text2 {
    background-color: #C8C8C8;
    border-radius: 4px;
    height: 15px;
    width: 55%;
    margin: 15px 0 0 15px;
     position:relative;
     left:10%;
     bottom:10%;
  }
  .shimmer-text {
    background-color: #C8C8C8;
    border-radius: 4px;
    height: 15px;
    width: 15%;
    margin: 15px 0 0 15px;
     
  }
 .shimmer-row {
    display: flex;
  }

  .shimmer-cell {
    flex: 1;
    padding: 10px;
    height: 50px;
    background-color: #F7F7F7;
    border-radius: 4px;
    margin: 5px;
  }
  
  `;

const customStyles = {
    container: {
        padding: '20px',
        backgroundColor: '#f0f0f0',
        borderRadius: '8px',
        margin: '20px'
    },
    header: {
        marginBottom: '10px',
        color: '#333',
    },
    addButton: {
        marginBottom: '20px',
        backgroundColor: '#4caf50',
    },
    tableContainer: {
        overflowX: 'auto',
        marginBottom: '20px',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    th: {
        border: '1px solid #ddd',
        padding: '8px',
        backgroundColor: '#f2f2f2',
        color: '#333',
    },
    td: {
        border: '1px solid #ddd',
        padding: '8px',
        color: '#333',
    },
    tdCenter: {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'center',
        color: '#333',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
};
