import React, { useState } from "react";
import Pagination from '@mui/material/Pagination';

const CommonTables = (props) => {
    const { tableHeads, tableData, perPage, currentPage, perPageChange, pageChange, totalCount } = props

    return (
        <div className="table-responsive text-nowrap">
        {/*<div className="container mt-4">*/}
            {/*<table style={styles.table}>*/}
            <table style={styles.table}>
                <thead>
                    <tr style={styles.headerRow}>
                        {tableHeads.map((head, index) => (
                            <th key={`header-${index}`} style={styles.th}>{head}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((row, rowIndex) => (
                        <tr key={row.MemberId || `row-${rowIndex}`}>
                            {row.map((data, colIndex) => (
                                <td key={`${row.MemberId || rowIndex}-${colIndex}`} style={styles.td} className="text-center">
                                    {data}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="row mt-2 mb-2">
                <div className=" col-sm-12 col-md-6">
                    <div className="dataTables_length" id="DataTables_Table_0_length">
                        <label style={{ marginRight: "10px" }}>Show entries</label>
                        <select
                            style={styles.paginationSelect}
                            value={perPage}
                            onChange={perPageChange}
                        >
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option>
                        </select>
                        <label>Total Records:<span style={{ fontWeight: 'bold' }}> {totalCount}</span></label>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                    <div className="dataTables_paginate paging_simple_numbers">
                        <Pagination
                            count={Math.ceil(totalCount / perPage)}
                            page={currentPage}
                            onChange={pageChange}
                            variant="outlined"
                            shape="rounded"
                            color="primary"
                            showFirstButton
                            showLastButton
                        />
                    </div>
                </div>
            </div>
           {/* </div>*/}
        </div>
    )
};

const styles = {
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    th: {
        padding: '10px 10px',
        border: '1px solid #ddd',
        textAlign: 'center',
        backgroundColor: '#f2f2f2',
        color: '#333',
        textTransform: 'uppercase',
        fontSize: '11px',
        letterSpacing: '1px',
        fontWeight: 'bold',
    },
    td: {
        padding: '5px 5px',
        border: '1px solid #ddd',
        fontSize: '14px',
        whiteSpace: 'normal',
        maxWidth: '200px',
    },
    headerRow: {
        backgroundColor: '#f9f9f9',
    },
    link: (isHovered) => ({
        color: isHovered ? 'blue' : '#0E94C3',
        transition: 'color 0.3s',
        cursor: 'pointer',
    }),
    phoneIcon: {
        marginRight: '5px',
    },
    paginationContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
    },
    paginationSelect: {
        padding: '5px',
        borderRadius: '5px',
        border: '1px solid',
        marginRight: '10px',
        borderColor: 'blue',
    },
    kycStatus: (isVerified) => ({
        color: isVerified ? 'green' : 'red',
        transition: 'color 0.3s',
    }),
};

export default CommonTables;
