import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BoxWrapper from "./BoxWrapper";
import { fetchAllData, fetchData, fetchUpdateData, uploadImage } from "../helpers/externapi";
import CardDetailsComponent from './CardDetail';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const CardEditForms = ({ selectedProductId }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [cardDetails, setCardDetails] = useState([]);
    const [editingCardId, setEditingCardId] = useState(null);
    const [editFormData, setEditFormData] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedBackImage, setSelectedBackImage] = useState(null);
    const [relationshipSelect, setRelationshipSelect] = useState([]);
    const [kycCardTypeSelect, setKycCardTypeSelect] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [kycMessage, setKycMessage] = useState('');
    const [kycVerified, setKycVerified] = useState(false);
    const [buttonText, setButtonText] = useState('KYC Verification');
    const [frontImageUrl, setFrontImageUrl] = React.useState('');
    const [backImageUrl, setBackImageUrl] = React.useState('');
    const [frontImageName, setFrontImageName] = useState('');
    const [backImageName, setBackImageName] = useState('');
    const [rmOptions, setRmOptions] = useState([]);
    const [loading, setLoading] = React.useState();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [selfDetails, setSelfDetails] = useState([]);
   
    const id = useParams();

    useEffect(() => {
        const getUsers = async () => {
            try {
                setLoading(true);
                const usersData = await fetchData("KYCCardType/all", { "skip": 0, "take": 0 });

                const routeMaps = usersData.map(route => ({ label: route.KYCCardType, value: route.KYCCardType }));
                setRmOptions(routeMaps);
            } catch (error) {
                console.error("Error fetching KYC Card Types:", error);
            } finally {
                setLoading(false);
            }
        };

        getUsers();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [dependentResponse] = await Promise.all([
                    fetchDependentData(selectedProductId),
                   
                ]);

               
                setCardDetails(dependentResponse);
               
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [selectedProductId]);

    
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const fetchDependentData = async (productId) => {
        const response = await fetchAllData(`MemberDependent/GetDependentsByMemberProductId/${id.Id}/${productId}`);
        return response;
    };

   

    const handleFileSelection = (e, type) => {
        const file = e.target.files[0];
        if (type === 'front') {
            setSelectedImage(file);
        } else if (type === 'back') {
            setSelectedBackImage(file);
        }
    };

    const handleFileUpload = async (MemberDependentId) => {
        const formData = new FormData();
        formData.append('MemberDependentId', MemberDependentId);
        formData.append('KYCCardFront', selectedImage);
        formData.append('KYCCardBack', selectedBackImage);

        const response = await uploadImage('MemberDependent/upload', formData);
        return response;
    };

    const handleEdit = async (MemberDependentId) => {
        const editedCard = cardDetails.find(card => card.MemberDependentId === MemberDependentId);

        if (!editedCard) {
            console.error('Edited card not found');
            return; // Exit if no card is found
        }

        setEditFormData({
            memberDependentId: editedCard.MemberDependentId,
            memberId: editedCard.MemberId,
            applicant: editedCard.Applicant,
            fullName: editedCard.FullName,
            dateofBirth: editedCard.DateofBirth,
            age: editedCard.Age,
            gender: editedCard.Gender,
            typeofCard: editedCard.TypeofCard,
            relationship: editedCard.Relationship,
            mobileNumber: editedCard.MobileNumber,
            KYCCardType: editedCard.KYCCardType,
            KYCCardFront: editedCard.KYCCardFront,
            KYCCardBack: editedCard.KYCCardBack,
            kycCardNumber: editedCard.KYCCardNumber,
            ...editedCard
        });

        setEditingCardId(MemberDependentId);

        const frontImageUrl = editedCard.KYCCardFront
            ? `https://ohoindiaimages.s3.ap-south-1.amazonaws.com/${editedCard.KYCCardFront.split('.').shift()}.jpg`
            : ''; // Fallback to empty string if null or undefined

        const backImageUrl = editedCard.KYCCardBack
            ? `https://ohoindiaimages.s3.ap-south-1.amazonaws.com/${editedCard.KYCCardBack.split('.').shift()}.jpg`
            : ''; // Fallback to empty string if null or undefined

        setFrontImageUrl(frontImageUrl);
        setBackImageUrl(backImageUrl);

        const frontImageName = editedCard.KYCCardFront
            ? editedCard.KYCCardFront.split('/').pop()
            : ''; // Fallback to empty string if null or undefined

        const backImageName = editedCard.KYCCardBack
            ? editedCard.KYCCardBack.split('/').pop()
            : ''; // Fallback to empty string if null or undefined

        setFrontImageName(frontImageName);
        setBackImageName(backImageName);
    };


    const handleDateChange = (e) => {
        const dob = new Date(e.target.value);
        const today = new Date();
        let age = today.getFullYear() - dob.getFullYear();
        const m = today.getMonth() - dob.getMonth();

        // Adjust if the birthday hasn't occurred this year yet
        if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
            age--;
        }

        setEditFormData({
            ...editFormData,
            DateofBirth: e.target.value,
            Age: age.toString()
        });
    };

    // Calculate date of birth based on the entered age
    const handleAgeChange = (e) => {
        const age = e.target.value;
        const today = new Date();
        const dob = new Date(today.setFullYear(today.getFullYear() - age));

        setEditFormData({
            ...editFormData,
            Age: age,
            DateofBirth: dob.toISOString().split('T')[0]
        });
    };

    const handleCancel = () => {
        setEditingCardId(null);
    };

    const handleSave = async (MemberDependentId, event) => {
        event.preventDefault();
        try {
            if (selectedImage && selectedBackImage) {
                const uploadResponse = await handleFileUpload(MemberDependentId);

                const updatedFormData = {
                    ...editFormData,
                    KYCCardFront: uploadResponse.frontImageId,
                    KYCCardBack: uploadResponse.backImageId
                };

                const response = await fetchUpdateData('MemberDependent/update', updatedFormData);
                await fetchData('MemberDependent/SubmitTheDependentKYC', {
                    memberId: id.Id,
                    //productsId: selectedProductId,
                });

                const response1 = await fetchUpdateData('OHOCards/update', updatedFormData);
                await fetchData('MembersProducts/SubmitTheKYC', {
                    memberId: id.Id,
                    //productsId: selectedProductId,
                });
            } else {
                const response = await fetchUpdateData('MemberDependent/update', editFormData);
                await fetchData('MemberDependent/SubmitTheDependentKYC', {
                    memberId: id.Id,
                    //productsId: selectedProductId,
                });

                const response1 = await fetchUpdateData('OHOCards/update', editFormData);
                await fetchData('MembersProducts/SubmitTheKYC', {
                    memberId: id.Id,
                    //productsId: selectedProductId,
                });
            }

            setSnackbarMessage("Dependent Details Updated Successfully");

            setSnackbarOpen(true);

            setIsSubmitted(true);
            setTimeout(() => {
                setIsSubmitted(false);
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const handleKYC = async () => {
        const userIdFromStorage = parseInt(sessionStorage.getItem("UserId"), 10) || 0;
        const response = await fetchData('Member/KYCVerification', {
            memberId: id.Id,
            UserId: userIdFromStorage,
        });

        setKycMessage(response);
        setKycVerified(true);

        setTimeout(() => {
            setKycVerified(false);
            setKycMessage('');
        }, 5000);
    };

    return (
        <>


           
               
            
            <div className="row mb-3 mt-3">
                {/* 
    {cardDetails.length > 0 && (
        <div className="col-md-2 offset-md-10">
            <button
                type="button"
                onClick={handleKYC}
                className="btn btn-sm btn-primary w-100"
                disabled={buttonText === 'KYC Verified'}
            >
                {buttonText}
            </button>
            {kycVerified && (
                <p className="kyc-message" style={{ whiteSpace: 'nowrap', color: 'green', fontWeight: 'bold', marginTop: '10px' }}>
                    {kycMessage}
                </p>
            )}
        </div>
    )}
    */}
            </div>


            <div className="row">
               
                {cardDetails.map((detail) => (
                    <div className="col-xl-3 col-lg-3 col-md-3 p-2 m-0" key={detail.MemberDependentId}>
                        
                        {editingCardId === detail.MemberDependentId ? (
                            <div className="col-xl-12 col-lg-12">
                                <div className="card mb-2">
                                    <div className="card-body">
                                        <h2 className="card-title">Edit Details</h2>
                                        <div className="info-container">
                                            <form onSubmit={(event) => handleSave(detail.MemberDependentId, event)}>
                                                <div>
                                                    <label className="form-label">FullName</label>
                                                    <div className="col-md-12 fw-normal">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="FullName"
                                                            value={editFormData.FullName || ''}
                                                             onChange={(e) => setEditFormData({
                                                                ...editFormData,
                                                                 FullName: e.target.value
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div>
                                                            <label className="form-label">Date Of Birth</label>
                                                            <div className="col-12 fw-normal">
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    name="DateofBirth"
                                                                    value={editFormData.DateofBirth ? new Date(editFormData.DateofBirth).toISOString().split('T')[0] : ''}
                                                                    onChange={(e) => setEditFormData({
                                                                        ...editFormData,
                                                                        DateofBirth: e.target.value
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12">
                                                        <div>
                                                            <label className="form-label">Age</label>
                                                            <div className="col-12 fw-normal">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="Age"
                                                                    value={editFormData.Age || ''}
                                                                    onChange={(e) => setEditFormData({
                                                                        ...editFormData,
                                                                        Age: e.target.value
                                                                    })}
                                                                   
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div>
                                                            <label className="form-label">Gender</label>
                                                            <div className="col-12 d-flex gap-3 mt-2">
                                                                <div className="form-check">
                                                                    <input
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        name="Gender"
                                                                        value="Male"
                                                                        checked={editFormData.Gender === "Male"}
                                                                        onChange={(e) =>
                                                                            setEditFormData({
                                                                                ...editFormData,
                                                                                Gender: e.target.value
                                                                            })
                                                                        }
                                                                    />
                                                                    <label className="form-check-label">Male</label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        name="Gender"
                                                                        value="Female"
                                                                        checked={editFormData.Gender === "Female"}
                                                                        onChange={(e) =>
                                                                            setEditFormData({
                                                                                ...editFormData,
                                                                                Gender: e.target.value
                                                                            })
                                                                        }
                                                                    />
                                                                    <label className="form-check-label">Female</label>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div>
                                                    <label className="form-label">Relationship</label>
                                                    <div className="col-md-12 fw-normal">
                                                        <select id="relationship" name="Relationship" className="form-select" value={editFormData.Relationship || ''} onChange={(e) => setEditFormData({
                                                            ...editFormData,
                                                            Relationship: e.target.value
                                                        })} style={{ padding: '10px', borderRadius: '5px' }}>
                                                            <option value="">Select Relation</option>
                                                            <option value="Spouse">Spouse</option>
                                                            <option value="Son">Son</option>
                                                            <option value="Daughter">Daughter</option>
                                                            <option value="Father">Father</option>
                                                            <option value="Mother">Mother</option>

                                                        </select>
                                                    </div>
                                                </div>
                                                {/*<div>*/}
                                                {/*    <label className="form-label">Type Of Card</label>*/}
                                                {/*    <div className="col-md-12 fw-normal">*/}
                                                {/*        <input*/}
                                                {/*            type="text"*/}
                                                {/*            className="form-control"*/}
                                                {/*            name="typeofCard"*/}
                                                {/*            value={editFormData.typeofCard || ''}*/}
                                                {/*            readOnly*/}
                                                {/*        />*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div>
                                                    <label className="form-label">Mobile Number</label>
                                                    <div className="col-md-12 fw-normal">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="mobileNumber"
                                                            value={editFormData.mobileNumber || ''}
                                                            onChange={(e) => setEditFormData({
                                                                ...editFormData,
                                                                mobileNumber: e.target.value
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="form-label">KYC Card Type</label>
                                                    <div className="col-md-12 fw-normal">
                                                        <select
                                                            className="form-select"
                                                            name="kycCardType"
                                                            value={editFormData.kycCardType || ''}
                                                            onChange={(e) => setEditFormData({
                                                                ...editFormData,
                                                                kycCardType: e.target.value
                                                            })}
                                                           
                                                        >
                                                            {rmOptions.map(option => (
                                                                <option key={option.value} value={option.label}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="form-label">KYC Card Number</label>
                                                    <div className="col-md-12 fw-normal">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="kycCardNumber"
                                                            value={editFormData.kycCardNumber || ''}
                                                            onChange={(e) => setEditFormData({
                                                                ...editFormData,
                                                                kycCardNumber: e.target.value
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="form-label">KYC Front Image</label>
                                                    <div className="col-12 fw-normal" style={{ marginBottom: '10px' }}>
                                                        {!selectedImage && frontImageUrl && (
                                                            <img
                                                                src={frontImageUrl}
                                                                style={{ width: '120px', height: '80px', marginTop: '0px', marginLeft: '10px' }} // Reduced size
                                                                alt="Front Image"
                                                            />
                                                        )}

                                                        {selectedImage && (
                                                            <a href={URL.createObjectURL(selectedImage)} target="_blank" rel="noopener noreferrer">
                                                                <img
                                                                    className="selected-image"
                                                                    src={URL.createObjectURL(selectedImage)}
                                                                    alt="Selected Front"
                                                                    style={{ width: '120px', height: '80px', marginTop: '10px', marginLeft: '10px' }} // Reduced size
                                                                />
                                                            </a>
                                                        )}
                                                    </div>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        accept="image/jpeg"
                                                        onChange={(e) => handleFileSelection(e, 'front', editingCardId)}
                                                        style={{ marginLeft: "10px" }}
                                                    />
                                                </div>

                                                <div>
                                                    <label className="form-label">KYC Back Image</label>
                                                    <div className="col-12 fw-normal" style={{ marginBottom: '10px' }}>
                                                        {!selectedBackImage && backImageUrl && (
                                                            <img
                                                                src={backImageUrl}
                                                                style={{ width: '120px', height: '80px', marginTop: '0px', marginLeft: '10px' }} // Reduced size
                                                                alt="Back Image"
                                                            />
                                                        )}

                                                        {selectedBackImage && (
                                                            <a href={URL.createObjectURL(selectedBackImage)} target="_blank" rel="noopener noreferrer">
                                                                <img
                                                                    className="selected-image"
                                                                    src={URL.createObjectURL(selectedBackImage)}
                                                                    alt="Selected Back"
                                                                    style={{ width: '120px', height: '80px', marginTop: '0px', marginLeft: '10px' }} // Reduced size
                                                                />
                                                            </a>
                                                        )}
                                                    </div>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        accept="image/jpeg"
                                                        onChange={(e) => handleFileSelection(e, 'back', editingCardId)}
                                                        style={{ marginLeft: "10px" }}
                                                    />
                                                </div>


                                                <div className="mt-3">
                                                    <button type="submit" className="btn btn-primary" style={{ marginRight: "10px" }} >Save</button> 
                                                    <button type="button" className="btn btn-secondary ml-2" onClick={handleCancel}>Cancel</button>
                                                </div>
                                            </form>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                                 detail &&  (
                                <div className="col-xl-12 col-lg-12">
                                        <CardDetailsComponent  detail={detail} handleEdit={handleEdit} />
                                </div>
                            )
                        )}
                    </div>
                ))}
            </div>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity="success">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default CardEditForms;
