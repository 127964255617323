import React, { useEffect, useState } from "react";
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchData, fetchAllData } from "../../helpers/externapi";
import Flatpickr from 'react-flatpickr';
import { saveAs } from 'file-saver';
import { constructCompleteAddress } from '../../Commoncomponents/CommonComponents';
import CommonTables from '../../Commoncomponents/CommonTables'
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function List(props) {
    const [loading, setLoading] = useState(false);
    const [membersData, setMembersData] = useState([]);
    const [selectedReportId, setSelectedReportId] = useState("");
    const [displayId, setDisplayId] = useState();
    const [selectedDateField, setSelectedDateField] = useState("");
    const [showTable, setShowTable] = useState(false);
    const [reportsOptions, setReportsOptions] = useState([]);
    const [dateFieldsOptions, setDateFieldsOptions] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [selectedDates, setSelectedDates] = useState([]);
    const [isDisableApply, setIsDisableApply] = useState(true);
    const [callLogMemberId, setCallLogMemberId] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const initialFormData = {
        callHistoryId: "", callLog: "", CollectedDate: "", callResponsesId: "", DateToBeVisited: "", RequestCallBack: ""
    }
    const [formData, setFormData] = useState(initialFormData);
    const [formError, setFormError] = useState({});
    const [isFormVisable, setIsFormVisible] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [callResponseOptions, setCallResponseOptions] = useState([]);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [callHistoryData, setCallHistoryData] = useState([]);
    const [totalReports, setTotalReports] = useState();
    const [statusMessage, setStatusMessage] = useState('');
    const [downloadLink, setDownloadLink] = useState(null);
    const [showButtons, setShowButtons] = useState(false);

    let UserId = sessionStorage.getItem("UserId");

    const thresholdDays = 5;
    const XLSX = require('xlsx');

    
  
    useEffect(() => {
        const fetchReports = async () => {
            setLoading(true);
            try {
                const reportsData = await fetchData("Reports/all", { "skip": 0, "take": 0 });
                const options = reportsData.map(item => ({ label: item.ReportName, value: item.ReportsId }));
                setReportsOptions(options);
                setTotalReports(reportsData);
                // const datefield = reportsData.map(item => ({ label: item.DateFields, value: item.DateFields }));
                // setDateFieldsOptions(datefield);

            } catch (error) {
                console.error("Error fetching reports:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchReports();
    }, []);

    const handleReportSelect = (event) => {
        const reportId = event.target.value;
        setSelectedReportId(reportId);

        const datefield = totalReports.find(item => item.ReportsId === parseInt(reportId));

        if (datefield && datefield.DateFields) {
            datefield.DateFields.length > 0 && (
                setDateFieldsOptions([{ label: datefield.DateFields, value: datefield.DateFields }])
            );
        } else {
            setDateFieldsOptions([]);
        }
    };

    const handleDateFieldSelect = (event) => {
        const dateField = event.target.value;
        setSelectedDateField(dateField);
    };

    const getReportsData = async () => {
        let startDate = "";
        let endDate = "";

        if (selectedDates.length === 1) {
            startDate = moment(selectedDates[0]).format('YYYY-MM-DD');
            endDate = moment(selectedDates[0]).format('YYYY-MM-DD');
        } else if (selectedDates.length === 2) {
            startDate = moment(selectedDates[0]).format('YYYY-MM-DD');
            endDate = moment(selectedDates[1]).format('YYYY-MM-DD');
        }

        const filterParams = {
            reportsId: selectedReportId,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            fieldName: selectedDateField || undefined,
        };

        setLoading(true);

        // const responseCount = await fetchData("Reports/GetTableReportsCount", { ...filterParams });
        // const totalCount = responseCount[0].ReportCount || 0;
        // setTotalCount(totalCount);

        const response = await fetchData("Reports/GetTableReportsExcel", { ...filterParams });
        console.log("reportData", response);

        if (response && response.length > 0) {
            setStatusMessage('Report generated successfully. Downloading the file...');

            // Triggering the download directly after the report is generated
            saveAs(response);  // Assuming `response` is the file URL or Blob

            // You can adjust the file name as needed
            setShowButtons(false);
            setStatusMessage('Downloaded Successfully!')// Hide the download button as it's not needed anymore
        } else {
            setStatusMessage('No data found for the report.');
            setShowButtons(false);
        }
        // setMembersData(response);
        setLoading(false);
    };

    useEffect(() => {
        if (showTable) {
            getReportsData();
        }
    }, [totalCount, perPage, currentPage]);

    const applyFilter = async () => {
        if (selectedReportId.length === 0) {
            setValidationMessage('Please select Report Name');
        } else {
            setValidationMessage('');
            setShowTable(true);
            setDisplayId(selectedReportId);

            setLoading(true);
            setStatusMessage('Generating your report, please wait...');
            setShowButtons(false);

            try {
                await getReportsData();
                
            } catch (error) {
                console.error("Error applying filter:", error);
                setStatusMessage('Error generating the report.');
            } finally {
                setLoading(false);
            }
        }
    };


    const clearFilters = () => {
        setSelectedReportId("");
        setSelectedDateField("");
        setDateFieldsOptions([]);
        setSelectedDates([]);
        setMembersData([]);
        setShowTable(false);
        setShowButtons(false);
        setStatusMessage("")
        setIsDisableApply(true);
    };

    
    useEffect(() => {
        if (selectedReportId.length === 0 && selectedDateField.length === 0 && selectedDates.length === 0) {
            setIsDisableApply(true);
        } else {
            setIsDisableApply(false);
        }

    }, [selectedReportId, selectedDateField, selectedDates]);

   

    useEffect(() => {
        const isFormValid = formData.callResponsesId.length > 0;
        setIsFormValid(isFormValid);
    }, [formData]);

   
    function formatDateRange(startDate, endDate) {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };

        const start = startDate.toLocaleDateString('en-GB', options).replace(/ /g, '-');
        const end = endDate.toLocaleDateString('en-GB', options).replace(/ /g, '-');

        return `${start} to ${end}`;
    }

   

    const selectedReportLabel = reportsOptions.find(option => option.value === parseInt(selectedReportId))?.label || "No report selected";

    return (
        <>
            {/* {loading && skeletonloading()}
            {!loading && ( */}
            <>
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="select2-primary mx-2" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {(selectedReportId.length > 0 || selectedDateField.length > 0 || selectedDates.length > 0) && (
                                <>
                                    {selectedReportId.length > 0 && (
                                        <div style={{ marginRight: '10px', marginBottom: '0px', display: 'flex', alignItems: 'center' }}>
                                            <strong style={{ marginRight: '5px' }}>Report Name: </strong>
                                            <span className="selected-option-button">
                                                {selectedReportLabel}
                                            </span>
                                        </div>
                                    )}

                                    {selectedDateField.length > 0 && (
                                        <div style={{ marginRight: '10px', marginBottom: '0px', display: 'flex', alignItems: 'center' }}>
                                            <strong style={{ marginRight: '5px' }}>Date Field: </strong>
                                            <span className="selected-option-button">
                                                {selectedDateField}
                                            </span>
                                        </div>
                                    )}

                                    {selectedDates.length > 0 && (
                                        <div style={{ marginRight: '10px', marginBottom: '0px', display: 'flex', alignItems: 'center' }}>
                                            <strong style={{ marginRight: '5px' }}>Selected Dates: </strong>
                                            <span className="selected-option-button">
                                                {formatDateRange(selectedDates[0], selectedDates[selectedDates.length - 1])}
                                            </span>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="d-flex flex-wrap justify-content-between align-items-end">
                            <div className="me-3" style={{ width: '250px' }}>
                                <label htmlFor="selectReports" className="form-label my-2">Report Name</label>
                                <span className="required" style={{ color: "red" }}> *</span>
                                <select
                                    id="selectReports"
                                    className="form-select"
                                    onChange={handleReportSelect}
                                    value={selectedReportId}
                                >
                                    <option value="">Select a report</option>
                                    {reportsOptions.map(option => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                                {validationMessage && <div style={{ color: 'red' }}>{validationMessage}</div>}
                            </div>

                            {dateFieldsOptions && dateFieldsOptions.length > 0 && (
                                <div className="me-3" style={{ width: '250px' }}>
                                    <label htmlFor="selectDateFields" className="form-label my-2">Date Fields</label>
                                    <select
                                        id="selectDateFields"
                                        className="form-select"
                                        onChange={handleDateFieldSelect}
                                        value={selectedDateField}
                                    >
                                        <option value="">Select a date field</option>
                                        {dateFieldsOptions.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            {selectedDateField && (
                                <div className="me-3" style={{ width: '250px' }}>
                                    <label htmlFor="fromDate" className="form-label my-2">Select Dates:</label>
                                    <Flatpickr
                                        value={selectedDates}
                                        placeholder="YYYY-MM-DD to YYYY-MM-DD"
                                        onChange={setSelectedDates}
                                        options={{ mode: "range", dateFormat: "Y-m-d" }}
                                        className="form-control"
                                        key={selectedDates} // Adding a key forces the component to re-render when state changes
                                    />
                                </div>
                            )}


                            <div className="d-flex">
                                <button
                                    type="button"
                                    className="btn btn-secondary me-2"
                                    onClick={clearFilters}
                                >
                                    Clear
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={applyFilter}
                                    disabled={isDisableApply}
                                >
                                    Generate Report
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

                {statusMessage && (
                    <div
                        className="mt-3"
                        style={{
                            color: loading ? 'blue' : 'green',
                            fontSize: '1.5rem', // Increase the font size
                            textAlign: 'center', // Center the text
                            fontWeight: 'bold', // Make the text bold if needed
                        }}
                    >
                        {statusMessage}
                    </div>
                )}


                
               
            </>
            {/* )} */}
        </>
    );

}


const shimmerStyle = `
    @keyframes shimmer {
        0% {
            background-position: -468px 0;
        }
        100% {
            background-position: 468px 0;
        }
    }

    .shimmer {
        animation-duration: 1.0s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: shimmer;
        animation-timing-function: linear;
        background: #F7F7F7;
        background: linear-gradient(to right, #f0f0f0 8%, #fafafa 18%, #f0f0f0 33%);
        background-size: 1000px 104px;
        position: relative;
        overflow: hidden;
    }

    .shimmer-container {
        background-color: #F7F7F7;
        border-radius: 4px;
        height: 50px;
        width: 100%;
        margin: 15px;
    }

    .shimmer-text2 {
        background-color: #C8C8C8;
        border-radius: 4px;
        height: 15px;
        width: 55%;
        margin: 15px 0 0 15px;
        position: relative;
        left: 10%;
        bottom: 10%;
    }

    .shimmer-text {
        background-color: #C8C8C8;
        border-radius: 4px;
        height: 15px;
        width: 15%;
        margin: 15px 0 0 15px;
    }

    .shimmer-row {
        display: flex;
    }

    .shimmer-cell {
        flex: 1;
        padding: 10px;
        height: 50px;
        background-color: #F7F7F7;
        border-radius: 4px;
        margin: 5px;
    }
`;
