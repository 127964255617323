import React, { useEffect } from "react";
import PageWrapper from "../../Components/PageWrapper";
import List from "./List";
import Layout from "../../Layout/Layout";

export default function ReportsList(props) {

    return (
        <Layout>
            <PageWrapper>
                <div>
                    <div className=" text-center bg-white p-2 m-1">
                        <div className="row align-items-center">
                            <div className="col-md-9">
                            </div>

                            <div className="col-md-3">
                                <h5 className="fw-bold py-1 mb-1 text-md-end">
                                    Reports Management
                                </h5></div>

                        </div>

                    </div>

                    <div id="detail">
                        <List />
                    </div>
                </div>                
            </PageWrapper>
        </Layout>
    )
}